import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  TextInput,
  FlatList,
} from 'react-native';
import React, { useMemo, useState } from 'react';

import languages from '/assets/json/languages.json';
import { EvilIcons } from '@expo/vector-icons';

type Props = {
  visible: boolean;
  /** If user selected a language, it will be passed here */
  onRequestClose: (language?: string) => void;
};

export default function LanguagePickerModal(props: Props) {
  const [filter, setFilter] = useState<string>('');

  const filteredLanguages = useMemo(() => {
    return languages.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase().trim()),
    );
  }, [filter]);

  return (
    <Modal
      animationType="slide"
      visible={props.visible}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      style={{
        backgroundColor: 'black',
        height: '100%',
        width: '100%',
        zIndex: 99,
      }}
    >
      <View
        style={{
          maxWidth: 1000,
          alignSelf: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            height: 48,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          <View
            style={{
              height: 48,
              width: '15%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TouchableOpacity onPress={() => props.onRequestClose()}>
              <EvilIcons name="close" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <TextInput
            autoCorrect={false}
            style={{
              height: 48,
              width: '60%',
            }}
            placeholder="Search here..."
            onChangeText={setFilter}
          />
          <TouchableOpacity
            style={{
              height: 30,
              paddingHorizontal: '5%',
              borderRadius: 10,
              backgroundColor: 'lightgrey',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 10,
            }}
          >
            <EvilIcons name="search" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <FlatList
          renderItem={({ item }) => {
            return (
              <Item
                label={item.name}
                onPress={() => {
                  props.onRequestClose(item.name);
                }}
              />
            );
          }}
          data={filteredLanguages}
          style={{ marginBottom: 75 }}
        />
      </View>
    </Modal>
  );
}

const Item = ({ label, onPress }: { label: string; onPress: () => void }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        padding: 25,
        borderBottomColor: 'rgba(0,0,0,0.09)',
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <View style={{ width: '90%' }}>
          <Text>{label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
