import * as Localization from 'expo-localization';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, TextInput } from 'react-native';
import { Country, CountryCode } from 'react-native-country-picker-modal';
import { ReactNativePhoneInputProps } from 'react-native-phone-input';
import { KEY_GRAY, TEXT_INPUT } from '/constants';

const ReactNativePhoneInputComponent = React.lazy(
  () => import('react-native-phone-input'),
);
const CountryPickerComponent = React.lazy(
  () => import('react-native-country-picker-modal'),
);

const ReactNativePhoneInput = forwardRef(
  (props: ReactNativePhoneInputProps, ref: any) => (
    <React.Suspense fallback={<ActivityIndicator color={KEY_GRAY} />}>
      <ReactNativePhoneInputComponent ref={ref} {...props} />
    </React.Suspense>
  ),
);
const CountryPicker = (props: any) => (
  <React.Suspense fallback={<ActivityIndicator color={KEY_GRAY} />}>
    <CountryPickerComponent {...props} />
  </React.Suspense>
);

interface IPhoneInputWithCountryPickerProps
  extends ReactNativePhoneInputProps<typeof TextInput> {
  innerRef?: (ref: any) => void;
}

export default function PhoneInputWithCountryPicker({
  textProps,
  offset,
  style,
  initialCountry,
  innerRef,
  onChangePhoneNumber,
  ...rest
}: IPhoneInputWithCountryPickerProps) {
  const region = useMemo(() => {
    const _region = Localization.getLocales()[0]?.regionCode;
    if (_region && _region !== 'mock') {
      return _region;
    }
    return 'US';
  }, []);

  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>(
    (initialCountry?.toUpperCase() as CountryCode) ?? region,
  );

  const phoneInputRef = useRef<any>();

  const onFlagPress = () => {
    setShowCountryPicker(true);
  };

  const onSelectCountry = (country: Country) => {
    setCountryCode(country.cca2);
    phoneInputRef.current?.selectCountry(country.cca2.toLowerCase());
  };

  return (
    <>
      <ReactNativePhoneInput
        {...rest}
        style={[TEXT_INPUT, style]}
        textProps={{
          placeholder: 'Phone number',
          ...(textProps ?? {}),
        }}
        offset={offset ?? 10}
        initialCountry={
          initialCountry ?? (Localization.region?.toLowerCase() || 'us')
        }
        onChangePhoneNumber={(text, iso2) => {
          const result = text
            .split('')
            .filter((c) => '0123456789;,-*#'.includes(c))
            .join('');

          onChangePhoneNumber?.(result, iso2);
        }}
        onPressFlag={() => {
          // console.log('flag pressed');
          onFlagPress();
        }}
        ref={(r) => {
          if (r) {
            phoneInputRef.current = r;
            innerRef?.(r);
          }
        }}
      />
      <CountryPicker
        onSelect={(country: Country) => onSelectCountry(country)}
        onClose={() => setShowCountryPicker(false)}
        translation="common"
        countryCode={countryCode}
        visible={showCountryPicker}
        withCallingCode
        withCloseButton
        // withFilter
        withAlphaFilter
        containerButtonStyle={{
          display: 'none',
        }}
      />
    </>
  );
}
