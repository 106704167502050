import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import Alert from '/Alert';
import Button from '/components/Button';
import GenericError from '/components/common/Generic/GenericError';
import ScrollView from '/components/common/ScrollView/ScrollView';
import SectionContainer from '/components/common/Section/SectionContainer';
import ListLoading from '/components/ListLoading';
import TesterObjectiveForm, {
  ITesterObjectiveFormState,
} from '/components/TesterObjective/TesterObjectiveForm';
import {
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import { KEY_GREEN } from '/constants';
import {
  useGetTesterObjectiveQuery,
  usePutTesterObjectiveMutation,
} from '/generated/graphql';
import useNavigationTools from '/hooks/useNavigationTools';

type Props = {
  route: RouteProp<any, any>;
  navigation: StackNavigationProp<any>;
};

function EditTesterObjective(props: Props) {
  const scrollViewRef = useRef<any>();
  const { validateForm, onScroll } = useFormValidationContext(scrollViewRef);
  const { invalidateRoutes } = useNavigationTools();

  const [state, _setState] = useState<ITesterObjectiveFormState>({});
  const setState = (newState: ITesterObjectiveFormState) => {
    _setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const [{ data, fetching, error }, refetch] = useGetTesterObjectiveQuery({
    variables: {
      id: props.route.params?.id,
    },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.getTesterObjective) {
      setState({
        id: data.getTesterObjective.id,
        description: data.getTesterObjective.description,
        name: data.getTesterObjective.name,
        whatToExpect: data.getTesterObjective.whatToExpect,
        howToTest: data.getTesterObjective.howToTest,
        timeToComplete: data.getTesterObjective.timeToComplete,
        dueDate: data.getTesterObjective.dueDate,
        userRole: data.getTesterObjective.userRole,
        calEventLink: data.getTesterObjective.calEventLink,
        minimumClientVersion: data.getTesterObjective.minimumClientVersion,
      });
    }
  }, [data?.getTesterObjective]);

  const [{ fetching: putting }, putTesterObjective] =
    usePutTesterObjectiveMutation();

  async function onSubmit() {
    if (!validateForm()) return;
    if (!props.route.params?.id) return;

    const { error: putError } = await putTesterObjective({
      input: {
        id: props.route.params.id,
        ...state,
      },
    });

    if (putError) {
      Alert.alert('Error', 'Failed to update objective');
      return;
    }

    Alert.notify({
      message: 'Objective updated successfully',
      color: KEY_GREEN,
    });

    invalidateRoutes([{ routeName: 'EditTesterObjective' }]);

    setImmediate(() => {
      props.navigation.navigate('ListTesterObjectives');
    });
  }

  return fetching ? (
    <ListLoading />
  ) : error ? (
    <GenericError onRetry={refetch} />
  ) : (
    <ScrollView
      onScroll={onScroll}
      scrollEventThrottle={100}
      ref={scrollViewRef}
    >
      <SectionContainer>
        <TesterObjectiveForm state={state} onChange={setState} />

        <Button
          label="Update"
          onPress={() => onSubmit()}
          loading={putting}
          containerStyle={{
            marginTop: 10,
            alignSelf: 'flex-end',
          }}
          style={{
            backgroundColor: KEY_GREEN,
          }}
        />
      </SectionContainer>
    </ScrollView>
  );
}

export default withFormValidation(EditTesterObjective);
