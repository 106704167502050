import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { v4 } from 'uuid';
import Button from '../Button';
import WriteComment, { WriteCommentProps } from '../Comments/WriteComment';
import GenericError from '../common/Generic/GenericError';
import { IUserMention } from '../UserMentionTextInput/UserMentionTextInput';
import Message from '/components/Message';
import {
  BUTTON_SHADOW,
  CREATIVE_CONNECT_BACKGROUND,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
  PRIMARY_BUTTON_BACKGROUND,
} from '/constants';
import { useAuthContext } from '/context';
import { DiscussionBoard } from '/generated/graphql';
import useDiscussionBoard, {
  IDiscussionBoardMessage,
} from '/hooks/useDiscussionBoard';
import { DeepPartial } from '/types';
import mergeStyleSheets from '/util/mergeStyleSheets';
import { useDiscussionBoardSendingOnBehalfOfUser } from '/hooks/DiscussionBoard/useDiscussionBoardSendingAsUser';

interface IDiscussionBoardWidgetProps {
  data: DeepPartial<DiscussionBoard> | undefined;
  messageOnBehalfOfUserId?: string;
  disableAutoMarkAsRead?: boolean;
  disableSubscription?: boolean;
  onHasUnreadMessagesChanged?: (hasUnreadMessages: boolean) => void;
  onSendMessage?: () => void;
  disabled?: boolean;
  darkTheme?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  messagesPerPage?: number;
  disableMessageSending?: boolean;
  inputProps?: Pick<
    WriteCommentProps,
    'buttonBarPlacement' | 'mediaUploadIcon' | 'onFocus'
  >;
  /** Used for determining whether to render parent content preview
   * in ViewDiscussionBoard screen. */
  from?: string;
  scrollable?: boolean;
  /** Default is `go-to-discussion-board` */
  viewMoreAction?: 'go-to-discussion-board' | 'paginate-in-place';
}

const DEFAULT_MESSAGES_PER_PAGE = 4;

export interface DiscussionBoardWidgetHandle {
  markLatestMessageRead: () => void;
}

export default forwardRef<
  DiscussionBoardWidgetHandle,
  IDiscussionBoardWidgetProps
>(function DiscussionBoardWidget(
  {
    data,
    messageOnBehalfOfUserId,
    disabled,
    darkTheme,
    scrollable,
    containerStyle,
    viewMoreAction,
    messagesPerPage,
    from,
    disableMessageSending,
    disableSubscription,
    ...props
  },
  ref,
) {
  const { userData } = useAuthContext();

  const messageOnBehalfOfUser = useDiscussionBoardSendingOnBehalfOfUser(
    messageOnBehalfOfUserId,
  );

  const [media, setMedia] = useState<string[]>([]);

  const {
    messages,
    totalMessages: _totalMessages,
    sendingMessage,
    isSendingMessage,
    loading,
    error,
    messageSendError,
    firstUnreadMessage,
    isAuthorizedToManage,
    hasNextPage,
    fetchNextPage,
    sendMessage,
    markLatestMessageRead,
    refetch,
    hideMessage,
  } = useDiscussionBoard({
    disableAutoReadMarking: props.disableAutoMarkAsRead,
    discussionBoardId: data?.id,
    messageOnBehalfOfUserId,
    messagesPerPage: messagesPerPage ?? DEFAULT_MESSAGES_PER_PAGE,
    disableSubscription,
  });

  const totalMessages =
    _totalMessages ??
    data?.messages?.total ??
    data?.messages?.items?.length ??
    0;

  const { push } = useNavigation<any>();

  const [messageInput, setMessageInput] = useState('');
  const [mentions, setMentions] = useState<IUserMention[]>([]);

  useEffect(() => {
    if (props.disableAutoMarkAsRead) return;
    // Mark latest message as read whenever `messages` changes
    markLatestMessageRead();
  }, [markLatestMessageRead, props.disableAutoMarkAsRead]);

  useImperativeHandle(
    ref,
    () => ({
      markLatestMessageRead,
    }),
    [markLatestMessageRead],
  );

  async function onSendMessage() {
    const res = await sendMessage(messageInput, mentions, media);

    if (res?.error) return false;

    setMessageInput('');

    return true;
  }

  const onViewAllMessages = useCallback(
    function () {
      if (viewMoreAction === 'paginate-in-place') {
        fetchNextPage();
        return;
      }

      const params: any = {
        id: data?.id,
      };

      if (messageOnBehalfOfUserId) {
        params.messageOnBehalfOfUserId = messageOnBehalfOfUserId;
      }

      if (from) {
        params.from = from;
      }

      push('ViewDiscussionBoard', params);
    },
    [
      data?.id,
      messageOnBehalfOfUserId,
      fetchNextPage,
      from,
      push,
      viewMoreAction,
    ],
  );

  const largeFormat = !messages?.length;

  const styles = useMemo(
    () => (darkTheme ? mergeStyleSheets(_styles, _darkThemeStyles) : _styles),
    [darkTheme],
  );

  const renderMessages = useMemo(() => {
    return messages.slice(
      0,
      viewMoreAction === 'paginate-in-place' ? messages.length : totalMessages,
    );
  }, [messages, viewMoreAction, totalMessages]);

  const footer = useMemo(
    () => (
      <TouchableOpacity
        disabled={disabled}
        onPress={onViewAllMessages}
        style={[
          styles.viewAllButton,
          {
            display: totalMessages > messages.length ? 'flex' : 'none',
          },
        ]}
      >
        {loading && viewMoreAction === 'paginate-in-place' ? (
          <ActivityIndicator
            size="small"
            color={KEY_GRAY}
            style={{
              alignSelf: 'center',
            }}
          />
        ) : (
          <Text style={styles.viewAllButtonText}>
            View {viewMoreAction === 'paginate-in-place' ? 'more' : 'all'}{' '}
            messages
          </Text>
        )}
      </TouchableOpacity>
    ),
    [
      disabled,
      loading,
      messages.length,
      onViewAllMessages,
      styles.viewAllButton,
      styles.viewAllButtonText,
      totalMessages,
      viewMoreAction,
    ],
  );

  return loading && !messages?.length ? (
    <ActivityIndicator
      style={styles.activityIndicator}
      color={darkTheme ? 'white' : KEY_GRAY}
    />
  ) : error ? (
    <GenericError
      message="Failed to fetch discussion board"
      onRetry={refetch}
    />
  ) : (
    <View
      style={[
        {
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.6 : 1,
        },
        containerStyle,
      ]}
    >
      {scrollable ? (
        <FlashList
          estimatedItemSize={200}
          data={renderMessages}
          style={{ flex: 1 }}
          inverted
          ListEmptyComponent={
            <Text style={styles.noMessagesText}>No messages yet.</Text>
          }
          contentContainerStyle={{
            paddingRight: 12,
          }}
          ListFooterComponent={footer}
          onEndReached={() => {
            if (viewMoreAction === 'paginate-in-place' && hasNextPage) {
              fetchNextPage();
            }
          }}
          renderItem={({ item: message }) => {
            return (
              <MessageListItem
                key={message.id || v4()}
                data={{
                  firstUnreadMessage,
                  hideMessage,
                  isAuthorizedToManage,
                  messageSendError,
                  refetch,
                }}
                darkTheme={!!darkTheme}
                messageOnBehalfOfUserId={messageOnBehalfOfUserId}
                message={message}
                onRetrySendMessage={() => {
                  sendingMessage?.body &&
                    sendMessage(sendingMessage.body, mentions, media);
                }}
              />
            );
          }}
        />
      ) : (
        <View style={{ flexDirection: 'column-reverse', width: '100%' }}>
          {renderMessages.length ? (
            renderMessages.map((message) => {
              return (
                <MessageListItem
                  key={message.id || v4()}
                  data={{
                    firstUnreadMessage,
                    hideMessage,
                    isAuthorizedToManage,
                    messageSendError,
                    refetch,
                  }}
                  darkTheme={!!darkTheme}
                  messageOnBehalfOfUserId={messageOnBehalfOfUserId}
                  message={message}
                  onRetrySendMessage={() => {
                    sendingMessage?.body &&
                      sendMessage(sendingMessage.body, mentions, media);
                  }}
                />
              );
            })
          ) : (
            <Text style={styles.noMessagesText}>No messages yet.</Text>
          )}

          {footer}
        </View>
      )}
      <WriteComment
        mentionSuggestionContext={{
          type: 'discussionBoard',
          discussionBoardId: data?.id ?? '',
        }}
        maxLength={3000}
        avatarUri={
          (messageOnBehalfOfUser?.profile_image || userData?.profile_image) ??
          ''
        }
        uploadMediaIconColor={darkTheme ? KEY_LIGHT_GRAY : KEY_GRAY}
        enableMediaUpload
        media={media}
        onChangeMedia={setMedia}
        enableMentions
        mentions={mentions}
        onChangeMentions={setMentions}
        avatarContainerStyle={{
          padding: 1,
          // Show green border if we are messaging on behalf of another user
          borderWidth: messageOnBehalfOfUserId ? 2 : 0,
          borderColor: messageOnBehalfOfUserId ? KEY_GREEN : 'transparent',
        }}
        disabled={isSendingMessage || disabled || disableMessageSending}
        submitLabel="Send"
        placeholderText="Write a message..."
        placeholderTextColor={darkTheme ? 'gray' : undefined}
        textInputStyle={{
          color: darkTheme ? 'white' : 'black',
        }}
        textInputContainerStyle={[
          styles.textInputContainer,
          {
            minHeight: largeFormat ? 112 : 72,
          },
        ]}
        loginPromptText="join the discussion"
        loginPromptTextStyle={styles.loginPromptText}
        value={messageInput}
        hideSubmitButton={largeFormat}
        onSubmitComment={() => {
          props.onSendMessage?.();

          return onSendMessage();
        }}
        onChangeText={setMessageInput}
        {...props.inputProps}
      />
      {userData?.id ? (
        <View
          style={{
            paddingTop: 16,
            opacity: isSendingMessage ? 0.6 : 1,
            display: !largeFormat ? 'none' : 'flex',
          }}
          pointerEvents={isSendingMessage ? 'none' : 'auto'}
        >
          <Button
            disabled={isSendingMessage || disabled || disableMessageSending}
            labelStyle={styles.sendButtonText}
            containerStyle={styles.sendButtonContainer}
            style={styles.sendButton}
            label="Send Message"
            onPress={() => onSendMessage()}
          />
        </View>
      ) : null}
    </View>
  );
});

function MessageListItem({
  data,
  message,
  darkTheme,
  messageOnBehalfOfUserId,
  onRetrySendMessage,
}: {
  data: Pick<
    ReturnType<typeof useDiscussionBoard>,
    | 'firstUnreadMessage'
    | 'isAuthorizedToManage'
    | 'messageSendError'
    | 'hideMessage'
    | 'refetch'
  >;
  message: IDiscussionBoardMessage;
  darkTheme: boolean;
  messageOnBehalfOfUserId: string | undefined;
  onRetrySendMessage: () => void;
}) {
  const {
    firstUnreadMessage,
    isAuthorizedToManage,
    messageSendError,
    hideMessage,
    refetch,
  } = data;

  const styles = useMemo(
    () => (darkTheme ? mergeStyleSheets(_styles, _darkThemeStyles) : _styles),
    [darkTheme],
  );

  return (
    <View>
      {message.id === firstUnreadMessage?.id ? (
        <View style={styles.separatorContainer}>
          <View style={styles.separatorLine} />
          <Text style={styles.separatorText}>NEW MESSAGES</Text>
          <View style={styles.separatorLine} />
        </View>
      ) : null}
      <Message
        actionSheetButtonColor={darkTheme ? 'white' : KEY_GRAY}
        viewingAsUserId={messageOnBehalfOfUserId}
        isAuthorizedToManageDiscussionBoard={isAuthorizedToManage}
        message={message}
        error={!message.created_at && !!messageSendError}
        onRetrySendMessage={onRetrySendMessage}
        onMessageDeleted={() => {
          hideMessage(message.id);
          refetch();
        }}
      />
    </View>
  );
}

const _styles = StyleSheet.create({
  textInputContainer: {
    backgroundColor: KEY_LIGHT_GRAY,
  },
  loginPromptText: {
    color: 'black',
  },
  viewAllButton: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 12,
  },
  viewAllButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: KEY_GRAY,
  },
  noMessagesText: {
    fontFamily: 'Lato-Bold',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 16,
    color: 'gray',
    padding: 32,
  },
  sendButtonContainer: {
    ...BUTTON_SHADOW,
    alignSelf: 'flex-end',
  },
  sendButton: {
    backgroundColor: KEY_GRAY,
    paddingVertical: 16,
    borderRadius: 6,
  },
  sendButtonText: {
    color: 'white',
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    paddingHorizontal: 12,
  },
  separatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  separatorText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    paddingHorizontal: 2,
  },
  separatorLine: {
    flex: 1,
    height: 1,
    backgroundColor: 'crimson',
  },
  activityIndicator: {
    padding: 24,
    alignSelf: 'center',
  },
  messageContainer: {
    // Add any necessary styles for the message container
  },
});

const _darkThemeStyles = StyleSheet.create({
  separatorText: {
    color: 'white',
  },
  textInputContainer: {
    backgroundColor: CREATIVE_CONNECT_BACKGROUND,
    color: 'white',
    marginTop: 2,
  },
  sendButtonText: {
    color: 'black',
  },
  loginPromptText: {
    color: 'white',
  },
  viewAllButtonText: {
    color: 'white',
  },
  sendButton: {
    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
  },
});
