import dayjs from 'dayjs';
import React from 'react';
import { Pressable, Text, View } from 'react-native';
import Alert from '/Alert';
import { KEY_GRAY } from '/constants';
import { useLoadingContext } from '/context';
import {
  useConfirmSkillRequestSelectionMutation,
  useDeclineSkillRequestSelectionMutation,
} from '/generated/graphql';

interface ISelectedApplicationCTAProps {
  applicationId: string;
  selectedAt: string;
}

export default function SelectedApplicationCTA({
  applicationId,
  selectedAt,
}: ISelectedApplicationCTAProps) {
  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  // Ask users to respond within 3 days of being selected.
  const RESPOND_BY = dayjs(selectedAt).add(3, 'days');
  // RESPOND_BY.setDate(RESPOND_BY.getDate() + 3);

  const [, confirmSelection] = useConfirmSkillRequestSelectionMutation();
  const [, declineSelection] = useDeclineSkillRequestSelectionMutation();

  async function onConfirmSelection() {
    try {
      setShowLoading(true);
      setLoadingInfo('Working...');

      const { error } = await confirmSelection({
        applicationId: applicationId,
      });
      console.log('Confirm Selection');

      if (error) throw error;
    } catch (err) {
      console.log(err);
      Alert.alert(
        'Oh no',
        'We ran into a problem while processing your request. Please try again later.',
      );
    } finally {
      setShowLoading(false);
      setLoadingInfo('');
    }
  }

  async function onDeclineSelection() {
    try {
      setShowLoading(true);
      setLoadingInfo('Working...');

      const { error } = await declineSelection({
        applicationId: applicationId,
      });

      if (error) throw error;
    } catch (err) {
      console.log(err);
      Alert.alert(
        'Oh no',
        'We ran into a problem while processing your request. Please try again later.',
      );
    } finally {
      setShowLoading(false);
      setLoadingInfo('');
    }
  }

  return (
    <View
      style={{
        paddingVertical: 8,
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      <Pressable
        style={({ pressed }) => ({
          backgroundColor: pressed ? '#141708' : '#2B330D',
          borderRadius: 12,
          padding: 14,
          width: '36%',
          maxWidth: 325,
          alignItems: 'center',
          marginBottom: 8,
          elevation: 5, // Shadow on Android
          shadowColor: KEY_GRAY,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowRadius: 2,
          shadowOpacity: 0.2,
        })}
        onPress={onDeclineSelection}
      >
        <Text
          style={{
            fontFamily: 'LeagueSpartan-Bold',
            fontSize: 15,
            color: 'white',
          }}
        >
          DECLINE
        </Text>
      </Pressable>
      <Pressable
        style={({ pressed }) => ({
          backgroundColor: pressed ? '#BBE02F' : '#D7FE43',
          borderRadius: 12,
          padding: 14,
          width: '36%',
          maxWidth: 325,
          alignItems: 'center',
          marginBottom: 8,
          elevation: 5, // Shadow on Android
          shadowColor: KEY_GRAY,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowRadius: 2,
          shadowOpacity: 0.2,
        })}
        onPress={onConfirmSelection}
      >
        <Text
          style={{
            fontFamily: 'LeagueSpartan-Bold',
            fontSize: 15,
            opacity: 0.8,
          }}
        >
          CONFIRM
        </Text>
      </Pressable>

      {/* </View>
    <View
      style={{
        paddingVertical: 8,
        alignItems: 'center',
      }}
      > */}
      <Text
        style={{
          fontFamily: 'Lato',
          color: '#3B3B3B',
          marginTop: 12,
          marginBottom: 4,
          alignItems: 'center',
        }}
      >
        You have{' '}
        {Math.round(dayjs(Number(RESPOND_BY)).diff(new Date(), 'hours', true))}{' '}
        hours left to respond
      </Text>
    </View>
  );
}
