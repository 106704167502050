import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import dayjs from 'dayjs';
import Constants from 'expo-constants';
import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Platform,
  Pressable,
  StyleProp,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import Alert from '/Alert';
import Button from '/components/Button';
import CalBookingModal from '/components/CalBookingModal/CalBookingModal';
import WriteComment from '/components/Comments/WriteComment';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import DiscussionBoardWidget, {
  DiscussionBoardWidgetHandle,
} from '/components/SkilledImpact/DiscussionBoardWidget';
import {
  ALERT_YELLOW,
  BUTTON_LABEL_STYLE,
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useAuthContext, useModalContext } from '/context';
import {
  TesterObjectiveFeedbackCompletionStatus,
  TesterObjectiveFragmentFragment,
  TesterObjectiveTargetDeviceType,
  useCancelCalBookingMutation,
  useCreateTesterObjectiveFeedbackMutation,
} from '/generated/graphql';
import compareVersionStrings from '/util/compareVersionStrings';

interface TesterObjectiveCardProps {
  objective: TesterObjectiveFragmentFragment;
  expanded?: boolean;
  refreshObjectives: () => void;
  loading: boolean;
  style?: StyleProp<ViewStyle>;
  collapsible?: boolean;
  focusAnimationValue?: Animated.Value;
  onToggleExpanded?: (expanded: boolean) => void;
}

export default function TesterObjectiveCard({
  objective,
  refreshObjectives: props_refreshObjectives,
  loading,
  focusAnimationValue,
  ...props
}: TesterObjectiveCardProps) {
  const { styles } = useStyles();

  const { userData } = useAuthContext();
  const { spawnModal } = useModalContext();

  const testDescriptionCollapsible = !props.collapsible && !!objective.feedback;
  const [isExpanded, setIsExpanded] = useState(
    props.expanded ?? !props.collapsible,
  );
  const [testDescriptionExpanded, setTestDescriptionExpanded] = useState(false);

  const hasUnreadMessages =
    !objective.feedback?.discussionBoard?.messages?.items[0]?.read &&
    objective.feedback?.discussionBoard?.messages?.items[0]?.user?.id !==
      userData?.id;

  const discussionBoardWidgetRef = useRef<DiscussionBoardWidgetHandle>(null);

  const [completionStatusInput, setCompletionStatusInput] = useState<
    TesterObjectiveFeedbackCompletionStatus | null | undefined
  >(null);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [feedbackMedia, setFeedbackMedia] = useState<string[]>([]);

  const isClientOutdated =
    Constants?.expoConfig?.version &&
    objective.minimumClientVersion &&
    compareVersionStrings(
      Constants?.expoConfig?.version,
      objective.minimumClientVersion,
    ) < 0;

  const [, createTesterObjectiveFeedback] =
    useCreateTesterObjectiveFeedbackMutation();
  const [{ fetching: cancelingCalBooking }, cancelCalBooking] =
    useCancelCalBookingMutation();

  useEffect(() => {
    if (props.expanded !== undefined) {
      setIsExpanded(props.expanded);
    }
  }, [props.expanded]);

  async function onSubmitFeedback(): Promise<boolean> {
    if (objective.feedback || !completionStatusInput || !userData) return false;

    const { error } = await createTesterObjectiveFeedback({
      input: {
        userId: userData.id,
        objectiveId: objective.id,
        completionStatus: completionStatusInput,
        message: {
          body: feedbackText,
          media: feedbackMedia,
        },
      },
    });

    if (error) {
      Alert.alert('Error', 'There was a problem submitting your feedback.');
      return false;
    }

    Alert.notify({
      message: 'Feedback submitted!',
      color: KEY_GREEN,
    });

    return true;
  }

  function onBookObjective() {
    if (!objective.calEventLink || !userData) return;

    const calBookingUId = objective.calBooking?.uid;

    if (calBookingUId) {
      Alert.alert(
        'Cancel Session',
        "Are you sure you want to cancel the test session you've scheduled?",
        [
          { text: 'Go back', style: 'cancel' },
          {
            text: 'Confirm',
            style: 'destructive',
            onPress: async () => {
              const { error } = await cancelCalBooking({
                bookingId: calBookingUId,
              });

              if (error) {
                Alert.alert(
                  'Error',
                  'There was a problem canceling the session. Please try again later.',
                );
                console.error(error);
                return;
              }

              Alert.notify({
                message: 'Session cancelled!',
                color: KEY_GREEN,
              });

              props_refreshObjectives();
            },
          },
        ],
      );
    } else {
      spawnModal({
        title: 'Schedule Test Session',
        onWillClose() {
          props_refreshObjectives();
          return true;
        },
        component: (
          <CalBookingModal
            eventLink={objective.calEventLink}
            metadata={{
              testerObjectiveId: objective.id,
              userId: userData.id,
            }}
          />
        ),
      });
    }
  }

  function onRescheduleObjectiveBooking() {
    if (!objective.calEventLink || !userData || !objective.calBooking?.uid)
      return;

    spawnModal({
      title: 'Reschedule Test Session',
      component: (
        <CalBookingModal
          eventLink={objective.calEventLink}
          mode="reschedule"
          bookingUid={objective.calBooking?.uid}
          metadata={{
            testerObjectiveId: objective.id,
            userId: userData.id,
          }}
        />
      ),
    });
  }

  return (
    <Animated.View
      style={[
        styles('container'),
        props.style,
        focusAnimationValue
          ? {
              borderColor: focusAnimationValue.interpolate({
                inputRange: [0, 1],
                outputRange: ['black', KEY_GREEN],
              }),
              backgroundColor: focusAnimationValue.interpolate({
                inputRange: [0, 1],
                outputRange: [KEY_LIGHT_GRAY, ALERT_YELLOW],
              }),
            }
          : undefined,
      ]}
    >
      <View>
        <Pressable
          disabled={!props.collapsible}
          onPress={() => {
            const newExpanded = !isExpanded;
            setIsExpanded(newExpanded);
            props.onToggleExpanded?.(newExpanded);
          }}
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text
              style={styles('fieldLabel')}
              numberOfLines={isExpanded ? undefined : 2}
            >
              {objective.name}
            </Text>

            {props.collapsible ? (
              <Text
                numberOfLines={isExpanded ? undefined : 2}
                style={[
                  styles('fieldText'),
                  {
                    fontFamily: 'FranklinGothic-Book',
                  },
                ]}
              >
                Goal: {objective.description}
              </Text>
            ) : null}

            {isExpanded && !props.collapsible ? (
              <>
                {objective.targetDeviceType ===
                TesterObjectiveTargetDeviceType.DesktopOnly ? (
                  <HorizontalContainer>
                    <FontAwesome5 name="desktop" size={16} color={KEY_GRAY} />
                    <Text
                      style={[
                        styles('fieldLabel'),
                        {
                          marginLeft: 6,
                        },
                      ]}
                    >
                      Desktop
                    </Text>
                  </HorizontalContainer>
                ) : objective.targetDeviceType ===
                  TesterObjectiveTargetDeviceType.MobileOnly ? (
                  <HorizontalContainer>
                    <FontAwesome5
                      name="mobile-alt"
                      size={16}
                      style={{
                        marginBottom: 2,
                      }}
                      color={KEY_GRAY}
                    />
                    <Text
                      style={[
                        styles('fieldLabel'),
                        {
                          marginLeft: 6,
                        },
                      ]}
                    >
                      Mobile
                    </Text>
                  </HorizontalContainer>
                ) : null}

                <Text style={styles('fieldLabel')}>
                  Due Date:{' '}
                  <Text
                    style={{
                      color: dayjs(objective.dueDate).isBefore(dayjs())
                        ? 'gray'
                        : KEY_DARK_GREEN,
                    }}
                  >
                    {dayjs(objective.dueDate).format('LLL')}
                  </Text>
                </Text>
                <Text style={styles('fieldLabel')}>
                  Time to Complete: {objective.timeToComplete}
                </Text>
              </>
            ) : null}

            {objective.feedback ? (
              <Text
                style={[
                  styles('fieldLabel'),
                  {
                    color: 'gray',
                    fontFamily: 'Lato-Italic',
                  },
                ]}
              >
                Completed {dayjs(objective.feedback.createdAt).format('LLL')}
              </Text>
            ) : null}
          </View>

          {props.collapsible ? (
            <HorizontalContainer
              style={{
                marginLeft: 12,
                marginTop: 4,
                alignItems: 'center',
              }}
            >
              {hasUnreadMessages ? (
                <>
                  <HorizontalContainer style={{ alignItems: 'center' }}>
                    <MaterialCommunityIcons
                      name="message-badge"
                      size={16}
                      color="red"
                      style={{ marginRight: 4 }}
                    />
                    <Text
                      style={{
                        fontFamily: 'Lato',
                        fontSize: 13,
                        color: KEY_GRAY,
                        marginRight: 12,
                      }}
                    >
                      New
                    </Text>
                  </HorizontalContainer>
                </>
              ) : null}
              <FontAwesome5
                name={isExpanded ? 'chevron-up' : 'chevron-down'}
                size={17}
                color={KEY_GRAY}
              />
            </HorizontalContainer>
          ) : (
            <Button
              containerStyle={[
                styles('whiteButtonContainerStyle'),
                {
                  alignSelf: 'flex-start',
                  borderWidth: objective.feedback ? 0 : 2,
                  marginLeft: 10,
                },
              ]}
              style={[
                styles('whiteButtonStyle'),
                {
                  pointerEvents: 'none',
                  backgroundColor: objective.feedback
                    ? KEY_GREEN
                    : KEY_LIGHT_GRAY,
                  paddingVertical: 12,
                },
              ]}
              labelStyle={{
                color: KEY_GRAY,
              }}
              label={
                <HorizontalContainer>
                  <Text style={BUTTON_LABEL_STYLE}>
                    {objective.feedback ? 'Complete' : 'Not Complete'}
                  </Text>
                  {objective.feedback ? (
                    <FontAwesome5
                      name="check"
                      size={17}
                      color={'black'}
                      style={{ marginLeft: 5 }}
                    />
                  ) : null}
                </HorizontalContainer>
              }
            />
          )}
        </Pressable>

        {props.collapsible ? null : (
          <Pressable
            disabled={!testDescriptionCollapsible}
            onPress={() => setTestDescriptionExpanded(!testDescriptionExpanded)}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={[
                styles('fieldText'),
                {
                  fontFamily: 'Lato-Bold',
                  marginVertical: 16,
                  flex: 1,
                },
              ]}
            >
              Goal: {objective.description}
            </Text>
            {testDescriptionCollapsible && (
              <FontAwesome5
                name={testDescriptionExpanded ? 'chevron-up' : 'chevron-down'}
                size={17}
                color={KEY_GRAY}
                style={{ marginLeft: 12 }}
              />
            )}
          </Pressable>
        )}

        {(!testDescriptionCollapsible && isExpanded) ||
        (testDescriptionCollapsible && testDescriptionExpanded) ? (
          <>
            <Text
              style={[
                styles('fieldLabel'),
                {
                  marginTop: 8,
                },
              ]}
            >
              What to Expect:
            </Text>
            <Text style={styles('fieldText')}>{objective.whatToExpect}</Text>

            <Text
              style={[
                styles('fieldLabel'),
                {
                  marginTop: 8,
                },
              ]}
            >
              How to Test:
            </Text>
            <Text style={styles('fieldText')}>{objective.howToTest}</Text>
          </>
        ) : null}
      </View>

      {isExpanded ? (
        <View>
          <Text
            style={[
              styles('fieldLabel'),
              {
                marginTop: 8,
              },
            ]}
          >
            Feedback:
          </Text>

          {objective.feedback ? (
            <>
              <Text style={styles('fieldText')}>
                Thank you for your feedback! You may share more thoughts below
                at any time before this objective's due date.
              </Text>

              {/* don't display this if it wasn't entered by user */}
              {!objective.calEventLink ? (
                <TestCompletionStatusInput
                  disabled
                  status={objective.feedback.completionStatus}
                  onStatusChange={() => {
                    // no-op
                  }}
                />
              ) : null}

              {hasUnreadMessages && !props.collapsible ? (
                <HorizontalContainer
                  style={{
                    marginTop: 16,
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 0, 0, 0.05)',
                    padding: 8,
                    borderRadius: 6,
                  }}
                >
                  <MaterialCommunityIcons
                    name="message-badge"
                    size={16}
                    color="red"
                    style={{ marginRight: 6 }}
                  />
                  <Text
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      color: KEY_GRAY,
                      flex: 1,
                    }}
                  >
                    New messages in the discussion
                  </Text>
                  <Button
                    label="Mark as Read"
                    onPress={() => {
                      discussionBoardWidgetRef.current?.markLatestMessageRead();
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      padding: 0,
                    }}
                    containerStyle={{
                      backgroundColor: 'transparent',
                      shadowOpacity: 0,
                    }}
                    labelStyle={{
                      color: KEY_DARK_GREEN,
                      fontSize: 14,
                    }}
                  />
                </HorizontalContainer>
              ) : null}

              <DiscussionBoardWidget
                containerStyle={{
                  maxHeight: 360,
                  height: Platform.OS !== 'web' ? 320 : undefined,
                  backgroundColor: 'white',
                  borderRadius: 6,
                  marginTop: 10,
                  padding: 5,
                }}
                disableMessageSending={dayjs(objective.dueDate).isBefore(
                  dayjs(),
                )}
                ref={discussionBoardWidgetRef}
                disableAutoMarkAsRead={!props.collapsible}
                disableSubscription
                scrollable
                data={{
                  id: objective.feedback.discussionBoardId,
                }}
                onSendMessage={() => {
                  if (!props.collapsible) {
                    discussionBoardWidgetRef.current?.markLatestMessageRead();
                  }
                }}
                inputProps={{
                  buttonBarPlacement: 'right',
                  mediaUploadIcon: (
                    <MaterialCommunityIcons
                      name="paperclip"
                      size={22}
                      color="black"
                    />
                  ),
                  onFocus: () => {
                    if (!props.collapsible) {
                      discussionBoardWidgetRef.current?.markLatestMessageRead();
                    }
                  },
                }}
                viewMoreAction="paginate-in-place"
              />
            </>
          ) : objective.calEventLink ? (
            <>
              {objective.calBooking ? (
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <FontAwesome5
                    name="clock"
                    size={17}
                    color={KEY_GRAY}
                    style={{
                      marginTop: 2,
                      marginRight: 5,
                    }}
                  />

                  <Text style={styles('fieldText')}>
                    You have a scheduled session for{' '}
                    <Text style={{ fontFamily: 'Lato-Bold' }}>
                      {dayjs(objective.calBooking.start).format('LLL')}
                    </Text>
                  </Text>
                </View>
              ) : (
                <Text style={styles('fieldText')}>
                  To test this, please schedule a live session with us using the
                  button below.
                </Text>
              )}

              <HorizontalContainer
                style={{
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <Button
                  onPress={onBookObjective}
                  disabled={
                    objective.calBooking?.start &&
                    dayjs(objective.calBooking.start).isBefore(dayjs())
                  }
                  loading={loading || cancelingCalBooking}
                  label={
                    objective.calBooking ? (
                      'Cancel'
                    ) : (
                      <HorizontalContainer>
                        <FontAwesome
                          name="calendar-plus-o"
                          size={17}
                          color={KEY_GRAY}
                        />
                        <Text
                          style={[
                            BUTTON_LABEL_STYLE,
                            {
                              marginLeft: 7,
                            },
                          ]}
                        >
                          Schedule
                        </Text>
                      </HorizontalContainer>
                    )
                  }
                  containerStyle={[
                    styles('whiteButtonContainerStyle'),
                    {
                      marginTop: 8,
                    },
                  ]}
                  style={styles('whiteButtonStyle')}
                  labelStyle={{
                    color: objective.calBooking ? 'crimson' : undefined,
                  }}
                />
                {objective.calBooking ? (
                  <Button
                    disabled={
                      objective.calBooking?.start &&
                      dayjs(objective.calBooking.start).isBefore(dayjs())
                    }
                    onPress={onRescheduleObjectiveBooking}
                    loading={loading || cancelingCalBooking}
                    label="Reschedule"
                    style={styles('whiteButtonStyle')}
                    containerStyle={[
                      styles('whiteButtonContainerStyle'),
                      {
                        marginLeft: 8,
                        marginTop: 8,
                      },
                    ]}
                  />
                ) : null}
              </HorizontalContainer>
            </>
          ) : (
            <>
              <Text style={styles('fieldText')}>
                Once you've finished testing, please share your thoughts below.
              </Text>

              {isClientOutdated ? (
                <Text
                  style={[
                    styles('fieldText'),
                    {
                      marginTop: 12,
                      color: 'crimson',
                    },
                  ]}
                >
                  This objective requires version{' '}
                  <Text style={{ fontFamily: 'Lato-Bold' }}>
                    {objective.minimumClientVersion}
                  </Text>{' '}
                  or higher. You're using version{' '}
                  <Text style={{ fontFamily: 'Lato-Bold' }}>
                    {Constants?.expoConfig?.version}
                  </Text>
                  . Please update to the latest version to test this objective
                  {Platform.OS === 'web'
                    ? ' by clearing your browser cache, closing all Key Conservation tabs, and reopening the app'
                    : ''}
                  .
                </Text>
              ) : (
                <>
                  <TestCompletionStatusInput
                    status={completionStatusInput}
                    onStatusChange={setCompletionStatusInput}
                    disabled={false}
                  />

                  {completionStatusInput ? (
                    <>
                      <Text
                        style={[
                          styles('fieldText'),
                          {
                            marginTop: 8,
                          },
                        ]}
                      >
                        Please elaborate, then click "Submit":
                      </Text>
                      <WriteComment
                        media={feedbackMedia}
                        buttonBarPlacement="right"
                        mediaUploadIcon={
                          <MaterialCommunityIcons
                            name="paperclip"
                            size={22}
                            color="black"
                          />
                        }
                        enableMediaUpload
                        maxLength={5000}
                        submitLabel="Submit"
                        placeholderText="Write a message..."
                        onChangeMedia={setFeedbackMedia}
                        value={feedbackText}
                        onChangeText={setFeedbackText}
                        enableMentions={false}
                        style={{
                          marginTop: 8,
                        }}
                        avatarUri={userData?.profile_image || undefined}
                        maxDynamicHeight={250}
                        textInputContainerStyle={{
                          borderWidth: 2,
                          borderRadius: 7,
                          paddingVertical: 2,
                        }}
                        onSubmitComment={onSubmitFeedback}
                      />
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </View>
      ) : null}
    </Animated.View>
  );
}

type TestCompletionStatusInputProps = {
  status: TesterObjectiveFeedbackCompletionStatus | null | undefined;
  onStatusChange: (status: TesterObjectiveFeedbackCompletionStatus) => void;
  disabled: boolean;
  /** Override the default prompt text */
  promptText?: string;
};

export function TestCompletionStatusInput(
  props: TestCompletionStatusInputProps,
) {
  const { styles } = useStyles();
  return (
    <>
      <Text
        style={[
          styles('fieldText'),
          {
            marginTop: 8,
          },
        ]}
      >
        {props.promptText || 'Were you able to complete the test?'}
      </Text>

      <HorizontalContainer
        style={{
          flexWrap: 'wrap',
        }}
      >
        <Button
          label="Yes"
          disabled={props.disabled}
          onPress={() => {
            props.onStatusChange(
              TesterObjectiveFeedbackCompletionStatus.Completed,
            );
          }}
          style={[
            styles('whiteButtonStyle'),
            {
              backgroundColor:
                props.status ===
                TesterObjectiveFeedbackCompletionStatus.Completed
                  ? KEY_GREEN
                  : KEY_LIGHT_GRAY,
            },
          ]}
          containerStyle={[
            styles('whiteButtonContainerStyle'),
            {
              marginRight: 8,
              marginTop: 8,
            },
          ]}
        />
        <Button
          label="No"
          disabled={props.disabled}
          onPress={() => {
            props.onStatusChange(
              TesterObjectiveFeedbackCompletionStatus.NotCompleted,
            );
          }}
          style={[
            styles('whiteButtonStyle'),
            {
              backgroundColor:
                props.status ===
                TesterObjectiveFeedbackCompletionStatus.NotCompleted
                  ? KEY_GREEN
                  : KEY_LIGHT_GRAY,
            },
          ]}
          containerStyle={[
            styles('whiteButtonContainerStyle'),
            {
              marginRight: 8,
              marginTop: 8,
            },
          ]}
        />
        <Button
          label="Partially"
          disabled={props.disabled}
          onPress={() => {
            props.onStatusChange(
              TesterObjectiveFeedbackCompletionStatus.PartiallyCompleted,
            );
          }}
          style={[
            styles('whiteButtonStyle'),
            {
              backgroundColor:
                props.status ===
                TesterObjectiveFeedbackCompletionStatus.PartiallyCompleted
                  ? KEY_GREEN
                  : KEY_LIGHT_GRAY,
            },
          ]}
          containerStyle={[
            styles('whiteButtonContainerStyle'),
            {
              marginTop: 8,
            },
          ]}
        />
      </HorizontalContainer>
    </>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    container: {
      padding: 10,
      borderWidth: 1,
      borderRadius: 6,
      backgroundColor: KEY_LIGHT_GRAY,
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    fieldLabel: {
      fontFamily: 'FranklinGothic-Demi',
      fontSize: 16,
      color: KEY_GRAY,
    },
    fieldText: {
      flexShrink: 1,
      fontFamily: 'Lato',
      fontSize: 16,
      color: KEY_GRAY,
    },
    buttonContainer: {
      alignSelf: 'flex-start',
      marginLeft: 8,
    },
    whiteButtonStyle: {
      borderRadius: 8,
      backgroundColor: KEY_LIGHT_GRAY,
    },
    whiteButtonContainerStyle: {
      shadowOpacity: 0,
      borderRadius: 10,
      borderWidth: 2,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      buttonContainer: {
        marginLeft: 0,
        marginTop: 8,
      },
    },
  },
);
