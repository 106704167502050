import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createClient as createWSClient } from 'graphql-ws';
import { createContext, useCallback, useRef, useState } from 'react';
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import {
  Client,
  createClient,
  errorExchange,
  fetchExchange,
  Provider as UrqlProviderExport,
  subscriptionExchange,
} from 'urql';
import WebSocketImported from 'ws';
import keys from './graphcache/keys';
import optimistic from './graphcache/optimistic';
import resolvers from './graphcache/resolvers';
import updates from './graphcache/updates';
import env from '/env';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JwtPayload } from '@aws-amplify/core/internals/utils';
import browserdetection from '/util/browserdetection';

// Get GraphQL API endpoint
const GRAPHQL_ENDPOINT = env.GRAPHQL_API;

const wsClient = createWSClient({
  /** When testing, WebsocketImported is used instead of the globally scoped WebSocket class */
  webSocketImpl: WebSocket ?? WebSocketImported,
  url: GRAPHQL_ENDPOINT.replace('https://', 'wss://').replace(
    'http://',
    'ws://',
  ), // Same endpoint, just different scheme
  connectionParams: async () => {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();

    return {
      Authorization: token,
    };
  },
  on: {
    error: (error) => {
      console.log('WSClient error', error);
    },
  },
});

type UrqlContextType = {
  client: Client;
  resetClient: () => void;
  hasNetworkError: boolean;
  clearNetworkError: () => void;
};

export const UrqlContext = createContext({} as UrqlContextType);

export default function UrqlProvider({
  children,
  testClient,
}: React.PropsWithChildren<{
  testClient?: Client;
}>) {
  const [client, setClient] = useState(initUrqlClient(onNetworkError));

  const [hasNetworkError, setHasNetworkError] = useState(false);

  const resetStateTimeoutRef = useRef<NodeJS.Timeout>();

  function onNetworkError() {
    if (resetStateTimeoutRef.current) {
      clearTimeout(resetStateTimeoutRef.current);
      resetStateTimeoutRef.current = undefined;
    }
    setHasNetworkError(true);
  }

  function clearNetworkError() {
    setHasNetworkError(false);
  }

  const resetClient = useCallback(() => {
    setClient(initUrqlClient(onNetworkError));
  }, []);

  return (
    <UrqlContext.Provider
      value={{
        client,
        resetClient,
        hasNetworkError,
        clearNetworkError,
      }}
    >
      <UrqlProviderExport value={testClient ?? client}>
        {children}
      </UrqlProviderExport>
    </UrqlContext.Provider>
  );
}
function initUrqlClient(onNetworkError: () => void) {
  const deviceInfoHeaders: any = {
    'x-client-version': Constants.expoConfig?.version,
  };

  const { osName, brand, modelName, osVersion } = Device;
  const browser = browserdetection.getBrowser();

  if (brand) deviceInfoHeaders['x-device-brand'] = brand;
  if (modelName) deviceInfoHeaders['x-device-model'] = modelName;
  if (osName) deviceInfoHeaders['x-device-os'] = osName;
  if (osVersion) deviceInfoHeaders['x-device-os-version'] = osVersion;
  if (browser) deviceInfoHeaders['x-device-browser'] = browser;

  return createClient({
    url: GRAPHQL_ENDPOINT,
    fetchOptions: {
      headers: deviceInfoHeaders,
    },
    exchanges: [
      requestPolicyExchange({}),
      cacheExchange({
        resolvers: resolvers,
        optimistic: optimistic,
        updates,
        keys: keys,
      }),
      errorExchange({
        onError: (error: any) => {
          if (
            // Only call onNetworkError if the network error is a connection error
            error.message.trim() === '[Network]' ||
            error.message.trim() === '[Network] Network request failed' ||
            error.message.trim() === '[Network] Failed to fetch'
          ) {
            onNetworkError();
          }
          // Simply log all errors
          console.error(
            'URQL error exchange received an error:',
            JSON.stringify(error.graphQLErrors?.[0]?.path),
            JSON.stringify(error),
            error,
          );
        },
      }),
      // Auth exchange configuration
      authExchange(async (utils) => {
        let token: string | undefined, claims: JwtPayload | undefined;

        try {
          const idToken = await getAuthToken();
          token = idToken.token;
          claims = idToken.claims;
        } catch (err) {
          console.log('authExchange error', err);
        }

        let lastRefreshAttempt: number | undefined;
        let retries = 0;

        return {
          addAuthToOperation: (operation) => {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: token,
            });
          },
          willAuthError: () => {
            return !!claims?.exp && claims.exp * 1000 < Date.now();
          },
          didAuthError: (error) => {
            // Only return true if this is indeed an authentication error AND we have not fetched a new token in the past 30 seconds
            // (The 30 second buffer will prevent an infinite loop in the case that we are authenticated but the authentication error
            // is because we are trying to access an admin-only resource or some other resource we do not have access to)
            const isAuthError = error.graphQLErrors.some((err: any) =>
              err?.message?.includes?.('Access denied'),
            );

            const tokenAlreadyRefreshed =
              Date.now() <= (lastRefreshAttempt ?? 0) + 30000; // 30 seconds
            const shouldRefresh =
              !tokenAlreadyRefreshed ||
              (tokenAlreadyRefreshed && (retries ?? 0) < 2);

            return isAuthError && shouldRefresh;
          },
          async refreshAuth() {
            const isRetry = Date.now() <= (lastRefreshAttempt ?? 0) + 30000;
            retries = isRetry ? (retries ?? 0) + 1 : 0;
            lastRefreshAttempt = Date.now();

            try {
              const newToken = await getAuthToken();

              token = newToken.token;
              claims = newToken.claims;
            } catch (err) {
              console.log('authExchange refreshAuth error', err);
              token = undefined;
              claims = undefined;
            }
          },
        };
      }),
      retryExchange({}),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation: any) => ({
          subscribe: (sink: any) => ({
            unsubscribe: wsClient.subscribe(operation, sink),
          }),
        }),
      }),
    ],
  });
}

async function getAuthToken() {
  const idToken = (await fetchAuthSession()).tokens?.idToken;

  return {
    token: idToken?.toString(),
    claims: idToken?.payload,
  };
}
