import { EvilIcons } from '@expo/vector-icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Modal,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import Alert from '/Alert';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT } from '/constants';
import { useAuthContext } from '/context';
import {
  PartnershipStatus,
  UserRole,
  useAddPartnerMutation,
  useSearchUsersWithPartnershipQuery,
} from '/generated/graphql';
import { isCloseToBottom } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';
import GenericError from '../common/Generic/GenericError';

interface Props {
  initiatorId?: string;
  visible: boolean;
  onRequestClose: () => void;
}

export default function AddPartnershipModal(props: Props) {
  const [query, setQuery] = useState('');

  const searchInputRef = useRef<TextInput>(null);

  const { userData } = useAuthContext();

  const [nextToken, setNextToken] = useState<string>();

  useEffect(() => {
    setQuery('');
  }, [props.visible]);

  const [{ data, fetching, error }, searchUsers] =
    useSearchUsersWithPartnershipQuery({
      variables: {
        nextToken,
        limit: !query ? 0 : 10,
        partnerId: props.initiatorId,
        filter: {
          query,
          role: UserRole.Conservationist,
        },
      },
      pause: !props.visible,
    });
  const [, addPartner] = useAddPartnerMutation();

  const [addingPartnerIds, setAddingPartnerIds] = useState<{
    [key: string]: boolean;
  }>({});

  async function onRequestPartnership(userId: string, name: string) {
    try {
      if (addingPartnerIds[userId]) return;

      setAddingPartnerIds((prevState) => ({ ...prevState, [userId]: true }));

      const { error: err } = await addPartner({
        userId,
        initiatorId: props.initiatorId,
      });

      if (err) throw err;
    } catch (err: any) {
      let errorMessage =
        'Failed to request partnership. Please try again later.';

      if (err?.message?.includes?.('Already requested partnership'))
        errorMessage = `You have already requested partnership with "${name}"`;
      if (err?.message?.includes?.('Partnership already exists'))
        errorMessage =
          'You have already selected this organization as a partner';

      closeModal(); // Close modal so we can see alert message
      Alert.alert(errorMessage);
      console.log('Error requesting partnership', err);
    } finally {
      setAddingPartnerIds((prevState) => ({ ...prevState, [userId]: false }));
    }
  }

  function closeModal() {
    setQuery('');
    props.onRequestClose();
  }

  function onScroll(evt: NativeSyntheticEvent<NativeScrollEvent>) {
    if (!data?.searchUsers.nextToken) return;

    if (isCloseToBottom(evt.nativeEvent)) {
      // If we are close to the bottom, and we have a next token, get more data
      setNextToken(data.searchUsers.nextToken);
    }
  }

  return (
    <Modal
      onShow={() => {
        searchInputRef.current?.focus();
      }}
      style={{ flex: 1 }}
      visible={props.visible}
      onRequestClose={() => {
        setQuery('');
        props.onRequestClose();
      }}
      presentationStyle="formSheet"
      animationType={Platform.OS !== 'web' ? 'slide' : 'none'}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View
            style={{
              maxWidth: 1000,
              alignSelf: 'center',
              flexDirection: 'row',
              width: '100%',
              height: 48,
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 8,
              marginTop: 32,
            }}
          >
            <View
              style={{
                height: 48,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TouchableOpacity
                style={{
                  padding: 12,
                }}
                onPress={() => props.onRequestClose()}
              >
                <EvilIcons name="close" size={24} color="black" />
              </TouchableOpacity>
            </View>
            <TextInput
              ref={searchInputRef}
              autoCorrect={false}
              autoCapitalize="none"
              style={[TEXT_INPUT, { flex: 1 }]}
              placeholderTextColor={'gray'}
              placeholder={`Search organizations...`}
              value={query}
              onChangeText={setQuery}
              enterKeyHint="search"
            />
            <View
              style={{
                width: 42,
                height: 32,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {fetching ? (
                <ActivityIndicator size={28} color="black" />
              ) : (
                <EvilIcons
                  style={{
                    marginHorizontal: 8,
                  }}
                  name="search"
                  size={28}
                  color="black"
                />
              )}
            </View>
          </View>
          <FlatList
            onScroll={onScroll}
            data={data?.searchUsers.items}
            style={styles.flatList}
            contentContainerStyle={{
              paddingBottom: 42,
              paddingHorizontal: 8,
            }}
            ListFooterComponent={
              data?.searchUsers.total && nextToken && fetching ? (
                <View
                  style={{
                    padding: 16,
                    alignItems: 'center',
                  }}
                >
                  <ActivityIndicator size={24} color="black" />
                </View>
              ) : data?.searchUsers.total === 0 || !query ? (
                <View style={styles.emptyContainer}>
                  {fetching ? (
                    <ActivityIndicator size={32} color="black" />
                  ) : (
                    <Text style={styles.emptyText}>
                      {!query
                        ? 'Start typing above to search for organizations'
                        : 'No results'}
                    </Text>
                  )}
                </View>
              ) : error && !fetching ? (
                <GenericError
                  message="There was a problem fetching users"
                  onRetry={searchUsers}
                />
              ) : null
            }
            renderItem={({ item, index }) => {
              if (item.id === props.initiatorId) return null;

              const { partnership } = item;

              let buttonColor = '#eee';
              let buttonLabel = 'Request partnership';
              let disabled = false;

              switch (partnership?.status) {
                case PartnershipStatus.Pending: {
                  if (partnership?.recipient.id === userData?.id) {
                    buttonLabel = 'Confirm Partner';
                  } else {
                    buttonLabel = 'Requested';
                    disabled = true;
                  }
                  break;
                }
                case PartnershipStatus.Confirmed: {
                  buttonColor = KEY_GREEN;
                  buttonLabel = 'Already Added';
                  disabled = true;
                  break;
                }
              }

              return (
                <View
                  style={[
                    styles.userCard,
                    index !== data!.searchUsers.items.length - 1
                      ? styles.bottomBorderDivider
                      : undefined,
                  ]}
                >
                  <Avatar
                    size={56}
                    rounded
                    source={{
                      uri: getCDNImageUri({
                        uri: item.profile_image,
                        dimensions: {
                          width: 64,
                          height: 64,
                        },
                      }),
                    }}
                  />
                  <View style={styles.userCardRightContent}>
                    <Text style={styles.userCardTitle} numberOfLines={2}>
                      {item.name}
                    </Text>
                    <Button
                      style={{
                        backgroundColor: buttonColor,
                      }}
                      loading={addingPartnerIds[item.id]}
                      disabled={disabled}
                      containerStyle={styles.requestButton}
                      onPress={() => onRequestPartnership(item.id, item.name)}
                      label={buttonLabel}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 800,
  },
  searchInputContainer: {
    width: '95%',
  },
  userCard: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
  },
  userCardTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    paddingBottom: 6,
  },
  userCardRightContent: {
    flex: 1,
    padding: 8,
  },
  flatList: {
    flex: 1,
    overflow: 'scroll',
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 42,
  },
  emptyText: {
    fontFamily: 'Lato',
    color: KEY_GRAY,
  },
  bottomBorderDivider: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  requestButton: {
    maxWidth: 300,
  },
});
