import React from 'react';
import {
  ActivityIndicator,
  GestureResponderEvent,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {
  BUTTON_LABEL_STYLE,
  BUTTON_SHADOW,
  PRIMARY_BUTTON_BACKGROUND,
} from '../constants';

export interface IButtonProps {
  label: string | React.ReactElement;
  /** Specifies whether to allow multiple lines for button label. Default is `false`. */
  multiline?: boolean;
  /** Disables interaction with the button and applies disabled button styling */
  disabled?: boolean;
  /** Allows customization of button style when `disabled` is set to `true` */
  disabledStyle?: StyleProp<ViewStyle>;
  /** Allows customization of label style when `disabled` is set to `true` */
  disabledLabelStyle?: StyleProp<TextStyle>;
  /** Display an activity indicator in place of label. Will use same color as text */
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  pointerEvents?: 'auto' | 'none' | 'box-only' | 'box-none';
  /** Default is `0.68` */
  activeOpacity?: number;
  testID?: string;
  onPress?: (event: GestureResponderEvent) => void;
}

export default function Button(props: IButtonProps) {
  return (
    <View
      style={[
        styles.container,
        props.containerStyle,
        {
          pointerEvents:
            props.pointerEvents ?? props.disabled ? 'none' : 'auto',
        },
      ]}
    >
      <Pressable
        testID={props.testID}
        onPress={props.onPress}
        style={({ pressed }) => [
          {
            opacity: pressed ? props.activeOpacity ?? 0.68 : 1,
          },
          styles.button,
          props.style ?? {},
          // Styling to apply when props.disabled is true:
          props.disabled || props.loading
            ? [styles.disabled, props.disabledStyle ?? {}]
            : [],
        ]}
      >
        {props.loading ? (
          <View
            style={[
              StyleSheet.absoluteFill,
              { justifyContent: 'center', alignItems: 'center' },
            ]}
          >
            <ActivityIndicator
              size={'small'}
              color={
                StyleSheet.flatten(props.labelStyle ?? {}).color || 'black'
              }
            />
          </View>
        ) : null}
        <View style={{ opacity: props.loading ? 0 : 1 }}>
          {typeof props.label === 'string' ? (
            <Text
              selectable={false}
              numberOfLines={props.multiline ? undefined : 1}
              style={[
                styles.label,
                props.labelStyle,
                props.disabled || props.loading ? props.disabledLabelStyle : {},
              ]}
            >
              {props.label}
            </Text>
          ) : (
            props.label
          )}
        </View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#aaa',
    borderRadius: 6,
    ...BUTTON_SHADOW,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
    padding: 16,
    paddingVertical: 7,
    borderRadius: 6,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  label: BUTTON_LABEL_STYLE,
});
