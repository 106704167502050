import { AntDesign, Feather } from '@expo/vector-icons';
import { StackNavigationProp } from '@react-navigation/stack';
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';
import React, { useMemo, useRef, useState } from 'react';
import { Keyboard, Text, TextInput, View } from 'react-native';
import styles from './ChangeEmail.style';
import Alert from '/Alert';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ReauthenticatePasswordModal from '/components/common/ReauthenticatePasswordModal';
import {
  ALERT_YELLOW,
  DESKTOP_MAX_WIDTH,
  KEY_GRAY,
  KEY_GREEN,
  SECTION_CONTAINER,
  TEXT_INPUT,
} from '/constants';
import { useAuthContext, useModalContext } from '/context';
import { useConfirmChangeEmailOrRollbackMutation } from '/generated/graphql';
import { isValidJson, userHasPassword } from '/util';
import withAuthRequired from '/components/withAuthRequired';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';

interface Props {
  navigation: StackNavigationProp<any>;
}

function ChangeEmail(props: Props) {
  const { user, userAttributes, refresh } = useAuthContext();
  const { spawnModal, closeModal } = useModalContext();
  const [, confirmChangeEmailOrRollback] =
    useConfirmChangeEmailOrRollbackMutation();

  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const [showConfirmUI, setShowConfirmUI] = useState(false);

  const confirmCodeInputRef = React.useRef<TextInput>(null);

  const [loading, setLoading] = useState(false);

  async function _updateEmail() {
    try {
      setLoading(true);

      if (showConfirmUI) {
        const accessToken = (await fetchAuthSession()).tokens?.accessToken;
        if (!accessToken) {
          throw new Error('No access token');
        }
        if (!confirmationCode.trim()) {
          Alert.alert('Enter Code', 'Confirmation code cannot be empty');
          return;
        }

        const { error } = await confirmChangeEmailOrRollback({
          confirmationCode,
          accessToken: accessToken.toString(),
        });
        if (error) {
          throw error;
        }

        Alert.notify({
          message: 'Email updated successfully!',
          color: KEY_GREEN,
        });

        props.navigation.goBack();
        refresh();
      } else {
        await updateUserAttributes({
          userAttributes: {
            email,
          },
        });

        setShowConfirmUI(true);
        confirmCodeInputRef.current?.focus();
      }
    } catch (error: any) {
      let errorTitle = 'Oh no';
      let errorMessage = 'Something went wrong. Please try again later.';

      const emailAlreadyInUse = error.message.includes(
        'An account with the given email already exists',
      );
      const invalidVerificationCode = error.message.includes(
        'Invalid verification code',
      );

      if (invalidVerificationCode) {
        errorTitle = 'Incorrect code';
        errorMessage =
          'The confirmation code you entered is incorrect. Please try again.';
      } else if (emailAlreadyInUse) {
        errorTitle = 'Email already in use';
        errorMessage =
          'The email you entered is already associated with another account.';
      }

      Alert.alert(errorTitle, errorMessage);
    } finally {
      setLoading(false);
    }
  }

  const enterPasswordModalIdRef = useRef<string>();
  function onUpdateEmail() {
    if (showConfirmUI) {
      return _updateEmail();
    }

    enterPasswordModalIdRef.current = spawnModal({
      title: 'Enter Password',
      component: (
        <ReauthenticatePasswordModal
          onConfirmSuccess={() => {
            _updateEmail();
            if (enterPasswordModalIdRef.current) {
              closeModal(enterPasswordModalIdRef.current);
            }
          }}
        />
      ),
    });
  }

  const userIdentities = useMemo(
    () =>
      isValidJson(userAttributes?.identities)
        ? JSON.parse(userAttributes?.identities)
        : [],
    [userAttributes?.identities],
  );

  const identityIcons = useMemo(() => {
    let icons: JSX.Element[] = [];

    userIdentities.forEach((identity: any) => {
      switch (identity.providerName) {
        case 'Google':
          icons.push(<AntDesign name="google" size={28} color="gray" />);
          break;
        case 'SignInWithApple':
          icons.push(<AntDesign name="apple1" size={28} color="gray" />);
          break;
      }
    });

    return icons;
  }, [userIdentities]);

  return (
    <View style={{ flex: 1 }}>
      <KeyboardAwareScrollView style={DESKTOP_MAX_WIDTH}>
        <View
          style={SECTION_CONTAINER}
          onTouchStart={() => {
            Keyboard.dismiss();
          }}
        >
          <Text style={styles.sectionTitle}>CHANGE EMAIL</Text>

          {userIdentities.length ? (
            <View
              style={{
                backgroundColor: ALERT_YELLOW,
                padding: 10,
                marginBottom: 8,
                borderRadius: 6,
                marginTop: 8,
              }}
            >
              {/* alert icon */}
              <HorizontalContainer
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <Feather
                  name="alert-triangle"
                  size={40}
                  color="black"
                  style={{
                    marginRight: 8,
                  }}
                />
                <View style={{ flex: 1 }}>
                  <Text style={styles.sectionText}>
                    Linked social sign-on accounts will be unlinked after
                    changing your email. To use social sign-on again, you can
                    log in with accounts that match your new email.
                  </Text>

                  <HorizontalContainer
                    style={{
                      marginTop: 4,
                    }}
                  >
                    {identityIcons.map((icon, index) => (
                      <View
                        key={index}
                        style={{
                          marginRight: 8,
                          elevation: 1,
                          shadowOffset: { width: 0, height: 0 },
                          shadowOpacity: 0.1,
                          shadowRadius: 1,
                          shadowColor: 'gray',
                          backgroundColor: 'white',
                          padding: 4,
                          borderRadius: 50,
                        }}
                      >
                        {icon}
                      </View>
                    ))}
                  </HorizontalContainer>
                </View>
              </HorizontalContainer>
            </View>
          ) : null}

          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>CURRENT EMAIL</Text>
            <TextInput
              pointerEvents={'none'}
              style={[
                TEXT_INPUT,
                {
                  color: KEY_GRAY,
                  backgroundColor: 'gray',
                },
              ]}
              placeholder="Current Password"
              value={userAttributes?.email}
            />
          </View>
          {!userHasPassword(user, userAttributes) ? (
            <View style={styles.fieldContainer}>
              <Text
                style={[
                  styles.sectionText,
                  {
                    color: 'orange',
                  },
                ]}
              >
                For your security, you must set a password before you can change
                your email.
              </Text>
              <Button
                label="Password Settings"
                containerStyle={{
                  marginTop: 8,
                  alignSelf: 'flex-end',
                }}
                onPress={() => {
                  props.navigation.navigate('ChangePassword');
                }}
              />
            </View>
          ) : (
            <>
              <View style={styles.fieldContainer}>
                <Text style={styles.fieldLabel}>NEW EMAIL</Text>
                <TextInput
                  autoCapitalize="none"
                  pointerEvents={
                    loading ||
                    showConfirmUI ||
                    !userHasPassword(user, userAttributes)
                      ? 'none'
                      : 'auto'
                  }
                  placeholder="New Email"
                  textContentType="emailAddress"
                  style={[
                    TEXT_INPUT,
                    {
                      color:
                        showConfirmUI || !userHasPassword(user, userAttributes)
                          ? KEY_GRAY
                          : 'black',
                      backgroundColor:
                        loading ||
                        showConfirmUI ||
                        !userHasPassword(user, userAttributes)
                          ? 'gray'
                          : TEXT_INPUT.backgroundColor,
                    },
                  ]}
                  onSubmitEditing={onUpdateEmail}
                  value={email}
                  onChangeText={setEmail}
                />
              </View>

              <View
                style={[
                  styles.fieldContainer,
                  {
                    display: showConfirmUI ? 'flex' : 'none',
                  },
                ]}
              >
                <Text style={styles.fieldLabel}>CONFIRMATION CODE</Text>
                <Text style={styles.confirmationCodeText}>
                  We've sent a confirmation code to your new email address.
                  Please enter it here. Be sure to check your spam/junk folders
                  if you cannot find it.
                </Text>
                <TextInput
                  ref={confirmCodeInputRef}
                  pointerEvents={loading ? 'none' : 'auto'}
                  placeholder="Confirmation Code"
                  textContentType="oneTimeCode"
                  keyboardType="number-pad"
                  style={[
                    TEXT_INPUT,
                    {
                      backgroundColor: loading
                        ? 'gray'
                        : TEXT_INPUT.backgroundColor,
                    },
                  ]}
                  value={confirmationCode}
                  onChangeText={setConfirmationCode}
                />
              </View>

              <View
                style={{
                  marginTop: 8,
                  alignSelf: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                {showConfirmUI ? (
                  <Button
                    label="Edit"
                    onPress={() => {
                      setShowConfirmUI(false);
                    }}
                    containerStyle={{
                      marginRight: 8,
                    }}
                  />
                ) : null}
                <Button
                  disabled={!userHasPassword(user, userAttributes)}
                  label={showConfirmUI ? 'Update' : 'Next'}
                  loading={loading}
                  onPress={() => onUpdateEmail()}
                  containerStyle={{
                    alignSelf: 'flex-end',
                  }}
                  style={
                    showConfirmUI
                      ? {
                          backgroundColor: KEY_GREEN,
                        }
                      : undefined
                  }
                />
              </View>
            </>
          )}
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}

export default withAuthRequired(ChangeEmail);
