import React, { useState } from 'react';
import Button from '../Button';
import LanguagePickerModal from './LanguagePickerModal';
import { StyleProp, TextStyle } from 'react-native';
import { ViewStyle } from 'react-native';

type Props = {
  onSelectLanguage?: (language: string) => void;
  buttonLabel?: string;
  buttonLabelStyle?: StyleProp<TextStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
};

export default function LanguagePicker(props: Props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <LanguagePickerModal
        visible={showModal}
        onRequestClose={(selectedLanguage) => {
          setShowModal(false);
          if (selectedLanguage) {
            props.onSelectLanguage?.(selectedLanguage);
          }
        }}
      />
      <Button
        label={props.buttonLabel || 'Add languages'}
        labelStyle={props.buttonLabelStyle}
        containerStyle={props.buttonContainerStyle}
        style={props.buttonStyle}
        onPress={() => {
          setShowModal(true);
        }}
      />
    </>
  );
}
