import {
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { Skill, UserSkillPortfolio } from '/generated/graphql';
import SkillButton from './SkillButton';
import { OFFWHITE, TEXT_INPUT, TEXT_INPUT_LARGE } from '/constants';
import { FontAwesome5 } from '@expo/vector-icons';
import { ValidatedTextInput, withFormValidation } from '../ValidatedForm';
import { isValidURLRegex } from '/util';
import Carousel from '../Carousel/Carousel';
import Button from '../Button';

interface ISkillPortfolio
  extends Pick<
    UserSkillPortfolio,
    | 'links'
    | 'skillName'
    | 'media_carousel'
    | 'relevant_experience'
    | 'thumbnail'
  > {
  skill: Pick<Skill, 'name' | 'thumbnail'>;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  data?: ISkillPortfolio[];
  onChange?: (data: ISkillPortfolio[]) => void;
}

function DescribeSkills(props: Props) {
  function onChange(index: number, partialData: Partial<ISkillPortfolio>) {
    const newData = Array.from(props.data ?? []);

    newData[index] = {
      ...newData[index],
      ...partialData,
    };

    props.onChange?.(newData);
  }

  return (
    <View style={props.style}>
      <Text
        style={{
          fontFamily: 'Lato-Bold',
          fontSize: 16,
          padding: 2,
        }}
      >
        (Optional) Briefly talk about your experience with each skill and attach
        relevant links
      </Text>

      {props.data?.map((skill, index) => (
        <View key={skill.skillName} style={styles.skillDetailFormContainer}>
          <View style={styles.skillDetailFormHeader}>
            <SkillButton
              labelStyle={{
                fontFamily: 'Lato',
              }}
              skill={skill.skillName}
              disabled
              selected
            />
          </View>

          <View style={styles.skillDetailFormField}>
            <Text style={styles.skillDetailFormFieldLabel}>
              What relevant experience do you have?
            </Text>
            <TextInput
              maxLength={500}
              style={TEXT_INPUT_LARGE}
              placeholder="Enter here..."
              value={skill.relevant_experience as string}
              multiline={true}
              numberOfLines={5}
              onChangeText={(text) => {
                onChange(index, {
                  relevant_experience: text,
                });
              }}
            />
          </View>

          <View style={styles.skillDetailFormField}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View style={{ width: 40 }}>
                <FontAwesome5 name="link" size={30} color="#555555" />
              </View>
              <Text style={styles.skillDetailFormFieldLabel}>
                Add relevant portfolio and work links
              </Text>
            </View>
            <View
              style={{
                paddingTop: 12,
                flexDirection: 'column',
              }}
            >
              {[...(skill.links ?? []), ''].map((link, linkIndex, array) => (
                <View
                  key={linkIndex}
                  style={{
                    paddingVertical: 4,
                  }}
                >
                  <ValidatedTextInput
                    required={false}
                    name={`link-${index}/${linkIndex}`}
                    validate={(text) => {
                      // Since the last element in our list is always an empty string (to allow for new links to be added)
                      // then we must make sure we always mark it valid
                      if (linkIndex === array.length - 1) return true;

                      return isValidURLRegex(text);
                    }}
                    style={TEXT_INPUT}
                    placeholder={`${
                      linkIndex === skill.links?.length ? '+ ' : ''
                    }Add link`}
                    placeholderTextColor="#bebebe"
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="url"
                    dataDetectorTypes="link"
                    value={link}
                    onBlur={() => {
                      if (!skill.links?.[linkIndex]) {
                        /** Delete link */
                        const newLinks = Array.from(
                          props.data?.[index].links ?? [],
                        );
                        newLinks.splice(linkIndex, 1);

                        onChange(index, {
                          links: newLinks,
                        });
                      }
                    }}
                    onChangeText={(text) => {
                      let newLinks = Array.from(
                        props.data?.[index].links ?? [],
                      );

                      // If we are typing into the last
                      // input field...
                      if (linkIndex === skill.links?.length) {
                        // ... Add a new link:
                        newLinks = [...newLinks, text];
                      } else {
                        newLinks[linkIndex] = text;
                      }

                      onChange(index, {
                        links: newLinks,
                      });
                    }}
                  />
                </View>
              ))}
            </View>
          </View>

          <View style={styles.skillDetailFormField}>
            <Text style={styles.skillDetailFormFieldLabel}>
              Add photos/videos showcasing this skill
            </Text>

            <Carousel
              data={skill.media_carousel?.map((media, j) => ({
                ...media,
                footerComponent: (
                  <UseAsThumbnailButton
                    isSetAsThumbnail={
                      skill.thumbnail
                        ? skill.thumbnail === media.thumbnail
                        : j === 0
                    }
                    onPress={() => {
                      onChange(index, {
                        thumbnail: media.thumbnail,
                      });
                    }}
                  />
                ),
              }))}
              isEditing
              onChange={(data) => {
                onChange(index, {
                  media_carousel: data,
                });
              }}
            />
          </View>
        </View>
      ))}
    </View>
  );
}

export default withFormValidation(DescribeSkills);

function UseAsThumbnailButton(props: {
  isSetAsThumbnail: boolean;
  onPress: () => void;
}) {
  return (
    <Button
      label={props.isSetAsThumbnail ? 'Using as thumbnail' : 'Set As Thumbnail'}
      disabled={props.isSetAsThumbnail}
      onPress={() => {
        props.onPress();
      }}
    />
  );
}

const styles = StyleSheet.create({
  skillDetailFormContainer: {
    paddingTop: 10,
    marginBottom: 16,
  },
  skillDetailFormHeader: {
    backgroundColor: OFFWHITE,
    alignItems: 'center',
    padding: 8,
    borderRadius: 6,
    marginBottom: 8,
  },
  skillDetailFormField: {
    paddingTop: 10,
  },
  skillDetailFormFieldLabel: {
    fontSize: 17,
    textAlign: 'left',
    fontFamily: 'Lato-Bold',
    marginBottom: 4,
  },
});
