import { StyleSheet } from 'react-native';
import { CARD_TITLE_FONT_SIZE, CONTENT_PARAGRAPH_FONT_SIZE } from '/constants';

export default StyleSheet.create({
  sectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
  },
  sectionText: {
    fontFamily: 'Lato',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
  },
  confirmationCodeText: {
    fontFamily: 'Lato',
    color: 'green',
    marginVertical: 2,
    fontSize: 15,
  },
  fieldContainer: {
    paddingVertical: 2,
    marginTop: 4,
  },
  fieldLabel: {
    fontFamily: 'LeagueSpartan-Bold',
  },
});
