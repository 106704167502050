/**
 * Allows class components to use the `useFileUpload` hook's functionality
 */
import { forwardRef } from 'react';
import useFileUpload, { useFileUploadReturns } from '/hooks/useFileUpload';

export type { useFileUploadReturns as FileUploadProps };

export default function withFileUpload<P extends object>(
  WrappedComponent: React.ComponentType<P & useFileUploadReturns>,
) {
  return forwardRef<any, P>((props, ref) => {
    const fileUpload = useFileUpload();
    const componentProps = { ...props, ...fileUpload, ref } as P &
      useFileUploadReturns;
    return <WrappedComponent {...componentProps} />;
  });
}
