import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import { View } from 'react-native';
import Button from '../Button';
import {
  useFormValidationContext,
  ValidatedTextInput,
  withFormValidation,
} from '../ValidatedForm';
import SectionText from './Section/SectionText';
import Alert from '/Alert';
import awsconfig from '/aws-exports';
import { TEXT_INPUT } from '/constants';
import { useAuthContext } from '/context';
import LoadingSpinnerOverlay from '../LoadingSpinnerOverlay';

const userPool = new CognitoUserPool({
  UserPoolId: awsconfig.aws_user_pools_id,
  ClientId: awsconfig.aws_user_pools_web_client_id,
});

type Props = {
  onConfirmSuccess: () => void;
};

function ReauthenticatePasswordModal(props: Props) {
  const { userAttributes } = useAuthContext();
  const [password, setPassword] = useState('');
  const { validateForm } = useFormValidationContext();
  const [loading, setLoading] = useState(false);

  async function onConfirm() {
    if (!validateForm() || !userAttributes?.email) return;

    setLoading(true);
    try {
      const authDetails = new AuthenticationDetails({
        Username: userAttributes.email,
        Password: password,
        ValidationData: {
          passwordLoginEmail: userAttributes.email,
        },
      });

      const cognitoUser = new CognitoUser({
        Username: userAttributes.email,
        Pool: userPool,
      });

      console.log('authenticating with', {
        username: userAttributes.email,
      });
      await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });

      props.onConfirmSuccess();
    } catch (error: any) {
      console.error(error);
      const incorrectUsernameOrPassword =
        /Incorrect username or password/i.test(error.message);

      const attemptLimitExceeded = /Password attempts exceeded/i.test(
        error.message,
      );

      if (incorrectUsernameOrPassword) {
        Alert.alert('Incorrect password');
      } else if (attemptLimitExceeded) {
        Alert.alert('Password attempts exceeded');
      } else {
        Alert.alert('There was an error reauthenticating');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <View>
        <SectionText>
          For your security, please enter your password to confirm this action.
        </SectionText>
        <ValidatedTextInput
          name="password"
          placeholder="Confirm with password"
          secureTextEntry
          textContentType="password"
          style={TEXT_INPUT}
          containerStyle={{
            marginTop: 4,
          }}
          value={password}
          onSubmitEditing={onConfirm}
          onChangeText={setPassword}
        />
        <Button
          containerStyle={{
            alignSelf: 'flex-end',
            marginTop: 10,
          }}
          label="Confirm"
          onPress={onConfirm}
          loading={loading}
        />
      </View>
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
}

export default withFormValidation(ReauthenticatePasswordModal);
