import { withIO } from 'react-native-intersection-observer';

import { FlashList } from '@shopify/flash-list';
import { Animated, FlatList } from 'react-native';
import Reanimated from 'react-native-reanimated';
import ScrollView from './common/ScrollView/ScrollView';

const ALL_METHODS: Parameters<typeof withIO>[1] = [
  'scrollTo',
  'scrollToEnd',
  'getScrollResponder',
  'getScrollableNode',
  'getInnerViewNode',
];

const ViewportTrackingScrollView = withIO(
  ScrollView,
  ALL_METHODS,
) as typeof ScrollView;

const ViewportTrackingFlatList = withIO(
  FlatList,
  ALL_METHODS,
) as typeof FlatList;

const ViewportTrackingFlashList = withIO(
  FlashList,
  ALL_METHODS,
) as typeof FlashList;

const ViewportTrackingAnimatedFlatList = withIO(
  // @ts-expect-error - Animated.FlatList is a function component, but it has the same interface, include ref
  Animated.FlatList,
  ALL_METHODS,
) as typeof Animated.FlatList;

const ViewportTrackingAnimatedScrollView = withIO(
  // @ts-expect-error - Animated.ScrollView is a function component, but it has the same interface, include ref
  Animated.ScrollView,
  ALL_METHODS,
) as unknown as typeof Animated.ScrollView;

const ViewportTrackingReanimatedScrollView = withIO(
  // @ts-expect-error - Reanimated.ScrollView is a function component, but it has the same interface, include ref
  Reanimated.ScrollView,
  ALL_METHODS,
) as unknown as typeof Reanimated.ScrollView;

const ViewportTrackingReanimatedFlatList = withIO(
  // @ts-expect-error - Reanimated.FlatList is a function component, but it has the same interface, include ref
  Reanimated.FlatList,
  ALL_METHODS,
) as unknown as typeof Reanimated.FlatList;

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const ReanimatedFlashList = Reanimated.createAnimatedComponent(FlashList);

const ViewportTrackingAnimatedFlashList = withIO(
  // @ts-expect-error - AnimatedFlashList is a function component, but it has the same interface, include ref
  AnimatedFlashList,
  ALL_METHODS,
) as unknown as typeof AnimatedFlashList;

const ViewportTrackingReanimatedFlashList = withIO(
  // @ts-expect-error - ReanimatedFlashList is a function component, but it has the same interface, include ref
  ReanimatedFlashList,
  ALL_METHODS,
) as unknown as typeof ReanimatedFlashList;

export default {
  ScrollView: ViewportTrackingScrollView,
  FlatList: ViewportTrackingFlatList,
  FlashList: ViewportTrackingFlashList,
  AnimatedFlatList: ViewportTrackingAnimatedFlatList,
  AnimatedScrollView: ViewportTrackingAnimatedScrollView,
  ReanimatedFlatList: ViewportTrackingReanimatedFlatList,
  ReanimatedScrollView: ViewportTrackingReanimatedScrollView,
  AnimatedFlashList: ViewportTrackingAnimatedFlashList,
  ReanimatedFlashList: ViewportTrackingReanimatedFlashList,
};
