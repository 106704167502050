import '@expo/metro-runtime';
import 'react-native-gesture-handler';
import 'react-native-reanimated';

// Polyfills
import 'setimmediate';

import { NavigationContainer } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from 'expo-av';
import 'expo-dev-client';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GestureResponderEvent, LogBox, Platform, View } from 'react-native';
import { enableFreeze } from 'react-native-screens';
import Alert from './Alert';

import { Amplify } from 'aws-amplify';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import amplifyconfig from './amplifyconfiguration.json';
import TesterGeneralFeedbackWidget from './components/Testers/TesterGeneralFeedbackWidget';
import { ALERT_RED } from './constants';
import { AppContextProvider } from './context';
import { IModalContext, ModalContext } from './context/ModalProvider';
import { NAVIGATION_THEME, navigationRef } from './navigation';
import AppNavigator from './navigation/AppNavigator';
import Fallback from './screens/Fallback';
import browserdetection from './util/browserdetection';
import { loadResourcesAsync } from './util/loadResourcesAsync';
import env from '/env';
import LINKING_CONFIG from '/linking/config';

Amplify.configure(amplifyconfig);

if (Platform.OS !== 'web') {
  import('/polyfills/Intl');
}

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

Sentry.init({
  dsn: env.SENTRY_DSN,
  debug: false,
  environment: env.ENV_NAME,
});

/** This will freeze any screens with a depth of n > 2 if they are not in view to improve performance
 *  https://github.com/software-mansion/react-freeze */
enableFreeze(true);

const App = () => {
  const feedbackModalId = useRef<string>();

  const [ready, setReady] = useState(false);

  const initializeAudioMode = useCallback(async () => {
    return Audio.setAudioModeAsync({
      interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
      interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: false,
      staysActiveInBackground: false,
    });
  }, []);

  const webInit = () => {
    /** Add Google Maps */
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${env.WEB_GOOGLE_MAPS_KEY}&libraries=places`;
    script.async = true;

    document.body.appendChild(script);

    const isLegacyBrowser = browserdetection.Utils.isLegacy();
    if (isLegacyBrowser) {
      Alert.notify({
        duration: 0,
        color: ALERT_RED,
        textColor: 'white',
        message:
          'Your browser is outdated and some features may not work properly. Please update your browser or use a different one.',
      });
    }

    /** Wait a tick to make sure Google maps loads before we render anything */
    return new Promise((resolve) => setImmediate(resolve));
  };

  useEffect(() => {
    const init = async () => {
      if (Platform.OS === 'web') {
        /** Wait for this stuff to initialize first... */
        await webInit();
      }

      /** LOAD FONTS */
      loadResourcesAsync();

      setReady(true);
    };

    init(); // Run initialization tasks

    /** Initialize audio mode */
    initializeAudioMode();
  }, [initializeAudioMode]);

  /** Android long setTimeout warning - this does not affect us */
  LogBox.ignoreLogs([
    'Setting a timer',
    'Attempted to capture a commit phase error inside a detached tree.',
    'source.uri should not be an empty string',
  ]);

  const onStartShouldSetResponder = (
    event: GestureResponderEvent,
    { spawnModal, closeModal }: IModalContext,
  ) => {
    if (event.nativeEvent.touches.length > 2) {
      Alert.alert(
        'Something not right?',
        'Submit feedback to our team. Thanks!',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'Submit Feedback',
            onPress: async () => {
              feedbackModalId.current = spawnModal({
                title: 'Send Feedback',
                component: (
                  <TesterGeneralFeedbackWidget
                    onRequestClose={() => {
                      if (feedbackModalId.current)
                        closeModal(feedbackModalId.current);
                    }}
                  />
                ),
              });
            },
          },
        ],
      );
    }

    return false;
  };

  return (
    <>
      {Platform.OS === 'ios' && <StatusBar style="dark" />}
      <Sentry.ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={({ resetError }) => <Fallback resetError={resetError} />}
      >
        {!ready ? null : (
          <GestureHandlerRootView style={{ flex: 1 }}>
            <NavigationContainer
              theme={NAVIGATION_THEME}
              ref={navigationRef}
              linking={LINKING_CONFIG}
              documentTitle={{
                formatter: (options, route) =>
                  `${
                    options?.title ?? route?.name
                      ? `${options?.title ?? route?.name} - `
                      : ''
                  }Key Conservation`,
              }}
            >
              <AppContextProvider>
                <ModalContext.Consumer>
                  {(value) => (
                    <View
                      style={{ flex: 1 }}
                      onStartShouldSetResponder={(e) => {
                        return onStartShouldSetResponder(e, value);
                      }}
                    >
                      <StatusBar style="auto" />
                      <AppNavigator />
                    </View>
                  )}
                </ModalContext.Consumer>
              </AppContextProvider>
            </NavigationContainer>
          </GestureHandlerRootView>
        )}
      </Sentry.ErrorBoundary>
    </>
  );
};

export default Sentry.wrap(App);
