import React from 'react';
import { Modal, Text, TouchableOpacity, View } from 'react-native';

import { AntDesign } from '@expo/vector-icons';
import { DEVICE_SIZES } from 'rn-responsive-styles';
import useStyles from './AnimalModal.style';
import Carousel from '../../Carousel/Carousel';
import animalData from '../animalData';
import Logo from '/assets/jsicons/other/Logo';

const AnimalModal = (props) => {
  const { styles, deviceSize } = useStyles();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={props.isModalVisible}
      onRequestClose={() => props.setIsModalVisible(!props.isModalVisible)}
    >
      <View style={styles('container')}>
        <View style={styles('contentContainer')}>
          <View style={styles('headerContainer')}>
            <View style={styles('header')}>
              <View style={styles('keyIcon')}>
                <Logo
                  width={
                    deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 75 : 100
                  }
                  height={
                    deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 75 : 100
                  }
                />
              </View>
              <View style={styles('headerTitleContainer')}>
                <Text style={styles('title')}>SPECIES PATTERNS</Text>
                <Text style={styles('text')}>
                  Learn what species are behind the patterns that you see
                  throughout the platform
                </Text>
              </View>
              <TouchableOpacity
                style={styles('closeButtonContainer')}
                onPress={() => props.setIsModalVisible(!props.isModalVisible)}
              >
                <AntDesign name="close" size={32} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles('listContainer')}>
            <Carousel
              data={animalData.map((animal) => ({
                media: animal.image,
                thumbnail: animal.image,
                footerComponent: (
                  <View style={styles('animalCaptionContainer')}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles('animalName')}>{animal.name}</Text>
                      <Text style={styles('animalPhotoCredit')}>
                        © {animal.photoCred}
                      </Text>
                    </View>
                    {/* <Button
                        containerStyle={{
                          marginTop: 4,
                        }}
                        style={{
                          backgroundColor: KEY_GRAY,
                        }}
                        labelStyle={{
                          color: 'white',
                        }}
                        label="LEARN MORE"
                        onPress={() => {
                          Platform.OS === 'web'
                            ? window.open(animal.link, '_blank')
                            : Linking.openURL(animal.link, '_blank');
                        }}
                      /> */}
                  </View>
                ),
              }))}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AnimalModal;
