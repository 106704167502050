import { ResizeMode } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import React, { Component, useEffect } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import ffmpeg from '/util/ffmpeg';

import {
  ActionSheetProps,
  connectActionSheet,
} from '@mtourj/react-native-action-sheet';

import { isCancelError } from 'aws-amplify/storage';
import withFileUpload, { FileUploadProps } from '../withFileUpload';
import { determineIfVideo } from '/util';

import { Asset } from 'expo-media-library';
import _ from 'lodash';
import { useDropzone as _useDropzone } from 'react-dropzone';
import MediaEditor, { MediaEditorMedia } from '../MediaEditor/MediaEditor';
import CustomUploadComponent from './components/CustomUploadComponent';
import DefaultUploadComponent from './components/DefaultUploadComponent';

const MEDIA_TYPES = {
  All: ImagePicker.MediaTypeOptions.All,
  Images: ImagePicker.MediaTypeOptions.Images,
  Videos: ImagePicker.MediaTypeOptions.Videos,
};

const DragAndDrop = ({
  children,
  style,
  onDrop,
  onDragEnter,
  onDragLeave,
  accept,
  multiple = false,
}: React.PropsWithChildren<any>) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    multiple,
    accept,
    onDrop,
  });

  useEffect(() => {
    if (isDragActive) {
      onDragEnter?.();
    } else {
      onDragLeave?.();
    }
  }, [isDragActive, onDragEnter, onDragLeave]);

  const inputProps = getInputProps();
  const rootProps = getRootProps();

  return Platform.OS === 'web' ? (
    <div
      {...rootProps}
      onClick={undefined}
      style={{ display: 'flex', flexDirection: 'column', ...style }}
    >
      <input
        {...getInputProps()}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          inputProps?.onDrop?.(e);
        }}
      />
      {children}
    </div>
  ) : (
    <View style={style}>{children}</View>
  );
};

const useDropzone =
  Platform.OS === 'web'
    ? _useDropzone
    : () => ({
        getRootProps() {
          return undefined;
        },
        getInputProps() {
          return undefined;
        },
        isDragActive: false,
      });

export type UploadMediaItem = {
  uri: string;
  thumbnailUri?: string;
  pendingUpload?: boolean;
};

type UploadMediaUploadProgress = {
  progress: number;
  loadedBytes: number;
  totalBytes: number;
  uploads: { uri: string; thumbnailUri?: string }[];
};

interface IUploadMediaProps extends React.PropsWithRef<{}> {
  media?: {
    uri: string;
    thumbnailUri?: string;
    pendingUpload?: boolean;
  }[];
  /** ONLY WORKS ON IMAGES. If set, upload media will use those dimensions when opening ImagePicker */
  targetMediaDimensions?: {
    width: number;
    height: number;
  };
  /** If true, images uploaded here will not be deleted when user's data is deleted */
  persistAfterUserDeletion?: boolean;
  disabled?: boolean;
  /** Default is 'All' */
  mediaType?: 'All' | 'Images' | 'Videos';
  style?: StyleProp<ViewStyle>;
  fontSize?: number;
  circular?: boolean;
  multiple?: boolean;
  /** If `multiple` is set, this dictates the maximum number of files */
  selectionLimit?: number;
  /** Setting to `true` will display a placeholder image when empty */
  isAvatar?: boolean;
  size?: 'auto' | number;
  removable?: boolean;
  title?: string;
  hideProgressIndicator?: boolean;
  previewResizeMode?: ResizeMode;
  /** Replace default UploadMedia component with a custom component */
  renderComponent?: (pressed: boolean, onPress: () => void) => JSX.Element;
  placeholderImage?: JSX.Element | (() => JSX.Element);
  onChangeMedia: (media: { uri: string; thumbnailUri?: string }[]) => any;
  /** Callback function called when an upload starts. Can be called while an upload is already in progress. */
  onUploadStart?: (media: { uri: string; thumbnailUri?: string }[]) => void;
  /** Callback function called when upload progress has been made */
  onUploadProgress?: (info: UploadMediaUploadProgress) => void;
  /** Callback function called once all uploads have ended successfully */
  onUploadSuccess?: () => void;
  /** Callback function called when an upload ends in a failure.
   * @param retry Callback function to retry all failed uploads */
  onUploadError?: (retry: () => void) => void;
  /** Callback function called when loading user selected media fails */
  onError?: (message: string) => void;
  /** Callback function called once all uploads have ended, regardless of success. Called with an array of
   * media URIs that failed to upload */
  onUploadEnd?: () => void;
  /** Callback function called when the list of uploading media changes */
  onUploadingMediaChanged?: (
    media: { uri: string; thumbnailUri?: string }[],
  ) => void;
}

export type MediaType = Omit<MediaEditorMedia, 'asset'> & {
  pendingUpload: boolean;
  upload?: UploadMediaUpload;
};

type EditorMediaType = MediaType & {
  asset: Pick<Asset, 'mediaType' | 'width' | 'height' | 'uri' | 'duration'> & {
    id?: string;
  };
};

type UploadMediaUpload = {
  uploading: boolean;
  uploadLoaded: number;
  uploadTotal: number;
  uploadError: boolean;
  generatingThumbnail: boolean;
  cancelUpload: (() => void) | undefined;
};

interface IUploadMediaState {
  media: MediaType[];
  editorMedia: EditorMediaType[];
  currentMedia: MediaType | undefined;
  currentIndex: number;
  /** Any object URLs that need to be revoked on component unmount to avoid
   * memory leaks */
  revokeObjectURLs: string[];
  editorVisible: boolean;
  /** User-facing error message, assigned when an error occurs while user is picking media */
  error: string | undefined;
}

class UploadMedia extends Component<
  IUploadMediaProps & ActionSheetProps & FileUploadProps,
  IUploadMediaState
> {
  constructor(props: IUploadMediaProps & ActionSheetProps & FileUploadProps) {
    super(props);

    this.state = {
      media:
        this.props.media?.map((m) => ({
          uri: m?.uri || '',
          thumbnailUri: m?.thumbnailUri || '',
          cropData: undefined,
          pendingUpload: false,
        })) ?? [],
      editorMedia: [],
      currentIndex: 0,
      currentMedia: undefined,
      error: undefined,
      revokeObjectURLs: [],
      editorVisible: false,
    };
  }

  get uploading() {
    return this.state.media.some((u) => u.upload?.uploading);
  }

  get uploadError() {
    return this.state.media.some((u) => u.upload?.uploadError);
  }

  get uploadProgress(): {
    progress: number;
    loadedBytes: number;
    totalBytes: number;
  } {
    const uploads = this.state.media
      .map((m) => m.upload)
      .filter((upload): upload is UploadMediaUpload => !!upload);
    if (!uploads.length) return { progress: 0, loadedBytes: 0, totalBytes: 0 };

    const [totalLoaded, total] = uploads.reduce(
      (acc, up) => {
        return [acc[0] + up.uploadLoaded, acc[1] + up.uploadTotal];
      },
      [0, 0],
    );

    return {
      progress: total ? (totalLoaded / total) * 100 : 0,
      totalBytes: total,
      loadedBytes: totalLoaded,
    };
  }

  get generatingThumbnail() {
    return this.state.media.some((m) => m.upload?.generatingThumbnail);
  }

  _getActionSheetOptions(mediaIndex: number) {
    const mediaType =
      this.props.mediaType === 'Images'
        ? 'Image'
        : this.props.mediaType === 'Videos'
        ? 'Video'
        : 'Media';

    return {
      cached: {
        title: 'Media Picker',
        options: [
          'Adjust Crop',
          `Change ${mediaType}`,
          `Remove ${mediaType}`,
          'Cancel',
        ],
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._editItems();
              return;
            }
            case 1: {
              this._pickMedia();
              return;
            }
            case 2: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
      uncached: {
        title: 'Media Picker',
        options: [`Change ${mediaType}`, `Remove ${mediaType}`, 'Cancel'],
        cancelButtonIndex: 2,
        destructiveButtonIndex: 1,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._pickMedia();
              return;
            }
            case 1: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
    };
  }

  _editItems = () => {
    this.setState({
      editorVisible: true,
    });
  };

  /** Cancels all uploads and removes media pending upload */
  cancelAllUploads() {
    this.state.media.forEach((m) => m.upload?.cancelUpload?.());

    this.setState((prevState) => ({
      currentMedia: undefined,
      media: prevState.media.filter((m) => !m.pendingUpload),
    }));
  }

  _pickMedia = async (files?: File[]) => {
    try {
      /**
       * If we are on Web, then we want to use the document picker instead because
       * ExpoMediaLibrary, which ImagePicker depends on, does not support the browser.
       */
      if (Platform.OS === 'web') {
        let targetTypes;

        if (this.props.mediaType === 'All' || !this.props.mediaType) {
          targetTypes = ['image/*', 'video/*'];
        } else if (this.props.mediaType === 'Videos') {
          targetTypes = ['video/*'];
        } else {
          targetTypes = ['image/*'];
        }

        let _files: DocumentPicker.DocumentPickerAsset[] = [];

        if (!files?.length) {
          const pickerResult = await DocumentPicker.getDocumentAsync({
            type: targetTypes,
            multiple: this.props.multiple,
          });

          if (pickerResult.canceled) return;

          if (!pickerResult.assets?.length) {
            this.onError('An unknown error occurred');
            return;
          }

          if (
            typeof this.props.selectionLimit === 'number' &&
            pickerResult.assets.length > this.props.selectionLimit
          ) {
            // eslint-disable-next-line no-alert
            alert(`You can only select ${this.props.selectionLimit} items`);
            _files = pickerResult.assets.slice(0, this.props.selectionLimit);
          }

          _files = pickerResult.assets;
        } else {
          _files = files.map((f) => ({
            file: f,
            uri: URL.createObjectURL(f),
            name: f.name,
            lastModified: f.lastModified,
            mimeType: f.type,
            size: f.size,
          }));
        }

        let pickerErrors = new Set<string>();

        let validatedMedia: {
          item: DocumentPicker.DocumentPickerAsset;
          dimensions: {
            width: number | undefined;
            height: number | undefined;
          };
        }[] = [];

        const getImageDimensions = async (
          doc: DocumentPicker.DocumentPickerAsset,
        ) => {
          const { width, height }: any = await new Promise((resolve) => {
            const img = new Image();
            img.addEventListener('load', function () {
              resolve({
                width: this.naturalWidth,
                height: this.naturalHeight,
              });
            });
            img.src = (doc as any).uri;
          });

          return {
            width: width as number,
            height: height as number,
          };
        };

        for (let i = 0; i < _files.length; i++) {
          const item = _files[i];

          if (!item) continue;

          const isVideo = determineIfVideo(item.name);

          // Make sure the file matches the media type specified
          if (
            (this.props.mediaType === 'Images' && isVideo) ||
            (this.props.mediaType === 'Videos' && !isVideo)
          ) {
            let typeText = this.props.mediaType.toLowerCase();

            URL.revokeObjectURL(item.uri);

            pickerErrors.add(`Please only select ${typeText}`);

            continue;
          }

          // Get the size of the file in MB
          if (item.size) {
            const documentSize = item.size / 1024 / 1024;

            if (documentSize > 200) {
              this.onError('Media too large, max 200MB');
              URL.revokeObjectURL(item.uri);
              continue;
            }
          }

          validatedMedia.push({
            item,
            dimensions: isVideo
              ? { width: undefined, height: undefined }
              : await getImageDimensions(item),
          });
        }

        if (pickerErrors.size > 0)
          this.onError(
            'Some items could not be uploaded:\n' + Array.from(pickerErrors)[0],
          );

        if (!validatedMedia.length) return;

        // Add selected files' object URL to the revokeObjectURLs array so that they can be
        // revoked on component unmount
        this.setState((prevState) => ({
          revokeObjectURLs: [
            ...prevState.revokeObjectURLs,
            ...validatedMedia.map((m) => m.item.uri),
          ],
        }));

        const newMedia: EditorMediaType[] = validatedMedia.map((media) => ({
          uri: media.item.uri,
          pendingUpload: true,
          asset: {
            uri: media.item.uri,
            duration: 0,
            width: media.dimensions.width as any,
            height: media.dimensions.height as any,
            mediaType: determineIfVideo(media.item.name) ? 'video' : 'photo',
          },
        }));

        /** If at least one item is a photo, open the editor for cropping */
        if (newMedia.some((m) => m.asset?.mediaType === 'photo')) {
          this.setState({
            editorMedia: newMedia,
            editorVisible: true,
          });
          /** Otherwise skip the editor (No thumbnail picker support on web yet) */
        } else {
          this.onEditorClose(newMedia as MediaEditorMedia[]);
        }
      } else {
        const result = await ImagePicker.launchImageLibraryAsync({
          selectionLimit: this.props.multiple ? this.props.selectionLimit : 1,
          mediaTypes: this.props.mediaType
            ? MEDIA_TYPES[this.props.mediaType]
            : MEDIA_TYPES.All,
          allowsMultipleSelection: this.props.multiple,
        });

        if (result.canceled || !result.assets.length) return;

        const media = result.assets.map((asset) => {
          const type = asset.type;

          const isVideo = type ? type === 'video' : determineIfVideo(asset.uri); // `type` is optional, so we fallback on checking the uri

          return {
            uri: asset.uri,
            pendingUpload: true,
            asset: {
              id: asset.assetId,
              uri: asset.uri,
              duration: asset.duration,
              width: asset.width,
              height: asset.height,
              filename: asset.fileName || undefined,
              mediaType: isVideo ? 'video' : 'photo',
            },
          } as EditorMediaType;
        });

        /** If at least one item is a photo, open the editor for cropping */
        if (media.some((m) => m.asset?.mediaType === 'photo')) {
          this.setState({
            editorMedia: media,
            editorVisible: true,
          });
          /** Otherwise skip the editor (No thumbnail picker support on web yet) */
        } else {
          this.onEditorClose(media as MediaEditorMedia[]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onError = (errorMessage: string) => {
    this.setState({
      error: errorMessage,
    });
    this.props.onError?.(errorMessage);
  };

  onEdit = () => {
    // strange stuff happens when we try to edit while uploading
    if (this.uploading) return;

    if (
      !this.props.multiple &&
      this.props.removable &&
      this.state.currentMedia?.uri
    ) {
      // TODO: Bring this back (where original media is stored to allow re-adjusting the crop)
      // const actionSheetOptions = this.state.media.original
      //   ? this._getActionSheetOptions(this.state.currentIndex).cached
      //   : this._getActionSheetOptions(this.state.currentIndex).uncached;
      const actionSheetOptions = this._getActionSheetOptions(
        this.state.currentIndex,
      ).uncached;

      this.props.showActionSheetWithOptions(
        actionSheetOptions,
        actionSheetOptions.onPress,
      );
    } else {
      this._pickMedia();
    }
  };

  ensureValidCurrentIndex() {
    let currentIndex = Math.min(
      this.state.currentIndex || 0,
      Math.max(0, this.state.media.length - 1),
    );

    if (this.state.currentIndex !== currentIndex) {
      this.setState({
        currentIndex,
      });
    }

    if (this.state.currentMedia !== this.state.media[currentIndex]) {
      this.setState({
        currentMedia: this.state.media[currentIndex],
      });
    }
  }

  componentDidMount() {
    this.ensureValidCurrentIndex();
  }

  componentDidUpdate(
    prevProps: IUploadMediaProps,
    prevState: IUploadMediaState,
  ) {
    this.ensureValidCurrentIndex();

    // Call onUploadingMediaChanged when uploading media changes
    if (prevState.media !== this.state.media) {
      const uploadingMedia = this.state.media
        .filter((m) => m.pendingUpload)
        .map((m) => ({
          uri: m.uri,
          thumbnailUri: m.thumbnailUri,
        }));

      this.props.onChangeMedia?.(this.state.media);
      this.props.onUploadingMediaChanged?.(uploadingMedia);
    }

    /** If `media` in props change, sync */
    if (this.props.media !== prevProps.media) {
      // Prevent synchronizing state.media with props.media when the editor is open
      if (this.state.editorVisible) {
        return;
      }

      const derivedState =
        this.props.media?.map((m) => {
          const existingItem = this.state.media.find(
            (existing) => existing.uri === m.uri,
          );
          return {
            ...existingItem,
            ...m,
            /** Ensure pendingUpload is correctly set */
            pendingUpload: existingItem?.pendingUpload || false,
            /** Ensure thumbnail is not lost even if parent doesn't store it */
            thumbnailUri: m.thumbnailUri || existingItem?.thumbnailUri,
          };
        }) ?? [];

      if (!_.isEqual(derivedState, this.state.media)) {
        const removedItems = this.state.media.filter(
          (m) => !derivedState.some((u) => u.uri === m.uri),
        );

        // Appropriate cleanup for removed items
        removedItems.forEach((m) => {
          // Revoke object URLs
          if (m.uri?.startsWith('data:')) {
            URL.revokeObjectURL(m.uri);
          }

          // Cancel upload if it's still in progress
          if (m.upload) {
            m.upload.cancelUpload?.();
          }
        });

        this.setState({
          media: derivedState,
        });
      }
    }
  }

  componentWillUnmount() {
    // Cancel uploads if unmounting
    if (this.uploading) {
      this.cancelAllUploads?.();
      this.props.onUploadEnd?.();
    }

    this.state.revokeObjectURLs.forEach((url) => {
      URL.revokeObjectURL(url);
    });

    this.clearState();
  }

  onEditorClose = (media: MediaEditorMedia[]) => {
    this.setState({ editorVisible: false });

    if (!media.length) return;

    const newMedia = media.map((m) => ({
      ...m,
      pendingUpload: true,
      /** Clear `asset` to signal this item can not be edited again */
      asset: undefined,
    }));

    this.setState(
      (prevState) => {
        const updatedMedia = this.props.multiple
          ? prevState.media.concat(...newMedia)
          : newMedia;
        return { media: updatedMedia };
      },
      () => {
        this.uploadMedia();
      },
    );
  };

  onUploadProgress = () => {
    const uploads = this.state.media.filter((media) => !!media.upload);
    const progress = this.uploadProgress;

    this.props.onUploadProgress?.({
      progress: progress.progress,
      loadedBytes: progress.loadedBytes,
      totalBytes: progress.totalBytes,
      uploads,
    });
  };

  // Uploads media in state
  uploadMedia = () => {
    const itemsToUpload = this.state.media.filter(
      (m) => m.pendingUpload && !m.upload?.uploading,
    );

    // If no items to upload, exit early
    if (!itemsToUpload.length) return;

    this.props.onUploadStart?.(itemsToUpload);

    this.setState((prevState) => {
      return {
        media: prevState.media.map((media) => {
          // Whenever we start a new upload, we want to clear `upload` from media objects
          // that are not pending upload. Allows us to continue getting accurate progress number
          // as user adds uploads.
          if (!media.pendingUpload) return { ...media, upload: undefined };

          /** Don't try to upload if upload is already in progress */
          if (media.upload?.uploading) return media;

          const uris = [media.uri];
          if (media.thumbnailUri) uris.push(media.thumbnailUri);

          const cancelUpload = this.props.uploadMultiple({
            persistAfterUserDeletion: this.props.persistAfterUserDeletion,
            uris,
            onUploadComplete: async ([uri, thumbnailUri]) => {
              const generateThumb = async () => {
                const isVideo = determineIfVideo(uri);

                if (!isVideo) {
                  return uri;
                } else {
                  this.setState((_prevState) => ({
                    media: _prevState.media.map((m) => {
                      if (m.uri !== media.uri) return m;
                      else
                        return {
                          ...m,
                          upload: m.upload
                            ? {
                                ...m.upload,
                                generatingThumbnail: true,
                              }
                            : undefined,
                        };
                    }),
                  }));

                  try {
                    return await ffmpeg.generateVideoThumbnail({
                      source: uri,
                      useOriginalFilename: true,
                    });
                  } catch (error) {
                    console.error(error);
                  } finally {
                    this.setState((_prevState) => ({
                      media: _prevState.media.map((m) => {
                        if (m.uri !== media.uri) return m;
                        else
                          return {
                            ...m,
                            upload: m.upload
                              ? {
                                  ...m.upload,
                                  generatingThumbnail: false,
                                }
                              : undefined,
                          };
                      }),
                    }));
                  }
                }
              };

              // generate a thumbnail if one doesn't exist
              const finalizedThumbnail =
                thumbnailUri || (await generateThumb());

              this.setState(
                (_prevState) => ({
                  media: _prevState.media.map((m) => {
                    if (m.uri !== media.uri) return m;
                    else
                      return {
                        ...m,
                        pendingUpload: false,
                        upload: m.upload
                          ? {
                              ...m.upload,
                              uploading: false,
                            }
                          : undefined,
                        uri,
                        thumbnailUri: finalizedThumbnail,
                      };
                  }),
                }),
                () => {
                  const otherUploadsStillInProgress = this.state.media.some(
                    (m) => m.upload?.uploading,
                  );

                  if (!otherUploadsStillInProgress) {
                    this.props.onUploadSuccess?.();
                    this.props.onUploadEnd?.();
                  }
                },
              );
            },
            onUploadProgress: (loaded, total) => {
              this.setState(
                (_prevState) => ({
                  media: _prevState.media.map((m) => {
                    if (m.uri !== media.uri) return m;
                    else
                      return {
                        ...m,
                        upload: {
                          cancelUpload: m.upload?.cancelUpload,
                          generatingThumbnail: !!m.upload?.generatingThumbnail,
                          uploadError: false,
                          uploading: true,
                          uploadLoaded: loaded,
                          uploadTotal: total,
                        },
                      };
                  }),
                }),
                () => {
                  this.onUploadProgress();
                },
              );
            },
            onUploadFailed: (error) => {
              if (error.errors.every((err: any) => isCancelError(err)))
                // If this is a cancel error, then just return; UploadMedia only experiences a cancel error
                // if we are beginning to upload different media instead, so we don't want to set uploading to false
                // or signal that uploading has ended
                return;

              this.setState((_prevState) => ({
                media: _prevState.media.map((m) => {
                  if (m.uri !== media.uri) return m;
                  else
                    return {
                      ...m,
                      upload: {
                        ...m.upload,
                        cancelUpload: m.upload?.cancelUpload,
                        generatingThumbnail: !!m.upload?.generatingThumbnail,
                        uploadLoaded: 0,
                        uploadTotal: 0,
                        uploading: false,
                        uploadError: true,
                      },
                    };
                }),
              }));

              this.props.onUploadError?.(this.retry);

              const otherUploadsStillInProgress = this.state.media.some(
                (m) => m.upload?.uploading,
              );
              if (!otherUploadsStillInProgress) {
                this.props.onUploadEnd?.();
              }
            },
          });

          const upload: UploadMediaUpload = {
            cancelUpload,
            generatingThumbnail: false,
            uploadError: false,
            uploading: true,
            uploadLoaded: 0,
            uploadTotal: 0,
          };

          media.upload = upload;

          return media;
        }),
      };
    });
  };

  retry = () => {
    this.uploadMedia();
  };

  removeCurrentMedia = () => {
    this.removeMedia(this.state.currentIndex);
  };

  removeMedia = (targetIndex: number) => {
    this.setState((prevState) => {
      const mediaToRemove = prevState.media[targetIndex];

      // Cancel upload if any
      mediaToRemove.upload?.cancelUpload?.();

      // Revoke object URLs
      if (mediaToRemove?.uri?.startsWith('data:')) {
        URL.revokeObjectURL(mediaToRemove.uri);
      }

      return {
        media: prevState.media.filter((m, i) => i !== targetIndex),
        currentIndex: Math.min(
          prevState.media.length - 2,
          prevState.currentIndex,
        ),
      };
    });
  };

  clearState = () => {
    this.setState({
      media: [],
      editorMedia: [],
      revokeObjectURLs: [],
    });
  };

  render() {
    const flattenedStyle = StyleSheet.flatten(this.props.style ?? {});

    let accept: any = {};

    switch (this.props.mediaType) {
      case 'Images':
        accept['image/*'] = [];
        break;
      case 'Videos':
        accept['video/*'] = [];
        break;
      default:
        accept['image/*'] = [];
        accept['video/*'] = [];
        break;
    }

    return (
      <DragAndDrop
        accept={accept}
        multiple={this.props.multiple}
        onDrop={(acceptedFiles: File[]) => {
          if (acceptedFiles.length) this._pickMedia(acceptedFiles);
        }}
      >
        <MediaEditor
          circleCrop={this.props.circular}
          visible={this.state.editorVisible}
          targetMediaDimensions={this.props.targetMediaDimensions}
          onFinishEditing={this.onEditorClose}
          /** Only include items that have an `asset`. Those are the only
           * items we are interested in editing. */
          media={this.state.editorMedia}
        />
        {/* Render custom component */}
        {this.props.renderComponent ? (
          <CustomUploadComponent
            onEdit={this.onEdit}
            style={flattenedStyle}
            uploading={this.uploading}
            disabled={this.props.disabled ?? false}
            renderComponent={this.props.renderComponent}
          />
        ) : (
          <DefaultUploadComponent
            onDismissError={() => {
              this.setState({ error: undefined });
            }}
            onSelectMedia={(index) => {
              this.setState({ currentIndex: index });
            }}
            currentMediaIndex={this.state.currentIndex}
            pickMedia={this._pickMedia}
            retryUploads={this.retry}
            uploadError={!!this.state.error}
            uploadProgress={this.uploadProgress.progress}
            hideProgressIndicator={!!this.props.hideProgressIndicator}
            previewResizeMode={this.props.previewResizeMode}
            isAvatar={this.props.isAvatar ?? false}
            currentMedia={this.state.currentMedia}
            removeCurrentMedia={this.removeCurrentMedia}
            fontSize={this.props.fontSize ?? 10}
            title={this.props.title ?? 'Upload'}
            media={this.state.media}
            mediaType={this.props.mediaType}
            placeholderImage={this.props.placeholderImage}
            onEdit={this.onEdit}
            style={flattenedStyle}
            uploading={this.uploading}
            disabled={this.props.disabled ?? false}
            circular={this.props.circular ?? false}
            size={this.props.size ?? 100}
            error={this.state.error}
            multiple={this.props.multiple ?? false}
          />
        )}
      </DragAndDrop>
    );
  }
}

export default connectActionSheet<IUploadMediaProps>(
  withFileUpload<ActionSheetProps & IUploadMediaProps>(UploadMedia),
);
