import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet } from 'react-native';
import SectionContainer from '/components/common/Section/SectionContainer';
import { useAuthContext } from '/context';
import { TesterStatus } from '/generated/graphql';
import TesterDashboard from '/screens/TesterDashboard/TesterDashboard';

export default function TesterWidget() {
  const { userData } = useAuthContext();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const route = useRoute<RouteProp<any>>();

  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || !!userData?.admin;

  return !isApprovedTester ? null : (
    <SectionContainer style={styles.container}>
      <TesterDashboard navigation={navigation} route={route} mode="section" />
    </SectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    overflow: 'hidden',
  },
});
