import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import { useState } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import DiscussionBoardMessageActionSheetPressable from './ActionSheet/DiscussionBoardMessageActionSheetPressable';
import Avatar from './Avatar';
import HorizontalContainer from './common/Generic/HorizontalContainer';
import MediaViewer from './MediaViewer/MediaViewer';
import UserMentionText from './UserMentionText';
import Alert from '/Alert';
import Ellipse from '/assets/jsicons/Ellipse';
import { KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';
import { useAuthContext } from '/context';
import { Message as MessageT, User } from '/generated/graphql';

interface IMessageUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface IMessageT
  extends Pick<MessageT, 'id' | 'created_at' | 'body' | 'mentions' | 'media'> {
  user: IMessageUser;
  on_behalf_of?: IMessageUser | null | undefined;
}

type MessageProps = {
  message: IMessageT;
  bodyStyle?: ViewStyle;
  /** If true, will display message in error state with a Retry button */
  error?: boolean;
  onRetrySendMessage?: () => void;
  /** Useful for team members viewing their on messages from the perspective of their organization. */
  viewingAsUserId?: string;
  isAuthorizedToManageDiscussionBoard: boolean;
  actionSheetButtonColor?: string;
  onMessageDeleted: () => void;
};

export default function Message(props: MessageProps) {
  const { userData } = useAuthContext();

  const [deleting, setDeleting] = useState(false);

  const { push } = useNavigation<StackNavigationProp<any, any>>();
  const linkTo = useLinkTo();

  const viewingAsUserId = props.viewingAsUserId || userData?.id;

  const {
    profile_image: senderAvatar,
    name: senderName,
    id: senderId,
  } = props.message.on_behalf_of || props.message.user;

  function goToProfile() {
    try {
      push('Profile', {
        id: senderId,
      });
    } catch (e) {
      linkTo(`/user/${senderId}`);
    }
  }

  // Show avatar on left if we or the team we represent is not the sender
  const showAvatarOnLeft = senderId !== viewingAsUserId;

  return (
    <View
      style={[
        styles.container,
        {
          justifyContent: showAvatarOnLeft ? 'flex-start' : 'flex-end',
          opacity: deleting ? 0.5 : 1,
          pointerEvents: deleting ? 'none' : 'auto',
        },
      ]}
    >
      {showAvatarOnLeft ? (
        <Avatar
          containerStyle={{
            marginRight: 4,
            marginTop: 4,
          }}
          source={{
            uri: senderAvatar ?? '',
          }}
          rounded
          size="medium"
          onPress={goToProfile}
        />
      ) : (
        <HorizontalContainer>
          <View style={styles.spacer} />
          <DiscussionBoardMessageActionSheetPressable
            style={{
              zIndex: 1,
              paddingRight: 4,
            }}
            isAuthorizedToManageDiscussionBoard={
              props.isAuthorizedToManageDiscussionBoard
            }
            message={props.message}
            onDelete={() => {
              setDeleting(true);
            }}
            onDeleteFailed={() => {
              setDeleting(false);
              Alert.alert('Failed to delete message', 'Please try again later');
            }}
            onDeleteSuccess={props.onMessageDeleted}
          >
            <Ellipse
              width={18}
              height={18}
              fill={props.actionSheetButtonColor ?? KEY_GRAY}
            />
          </DiscussionBoardMessageActionSheetPressable>
        </HorizontalContainer>
      )}
      <View
        style={[
          styles.bodyContainer,
          props.bodyStyle,
          {
            flex: props.message.media?.length ? 1 : undefined,
          },
        ]}
      >
        {props.message.media?.length ? (
          <MediaViewer
            sourceUris={props.message.media}
            maxHeight={480}
            noAutoplay
            style={{
              width: '100%',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        ) : null}

        <View style={{ padding: 8, paddingHorizontal: 16 }}>
          <Text style={styles.senderName}>{senderName}</Text>
          {/* If message was sent on behalf of a user, show the name of the team member who sent it here */}
          {props.message.on_behalf_of ? (
            <Text style={styles.teamMemberName}>{props.message.user.name}</Text>
          ) : null}
          <UserMentionText
            style={styles.bodyText}
            value={props.message.body}
            mentions={props.message.mentions}
          />
          <Text style={styles.timestampText}>
            {props.error ? (
              <View style={styles.errorContainer}>
                <Text style={styles.errorText}>Failed to send</Text>
                <Text
                  onPress={props.onRetrySendMessage}
                  style={styles.retryButtonText}
                >
                  Retry
                </Text>
              </View>
            ) : props.message?.created_at ? (
              dayjs(Number(props.message.created_at)).fromNow()
            ) : (
              'Sending...'
            )}
          </Text>
        </View>
      </View>
      {!showAvatarOnLeft ? (
        <Avatar
          containerStyle={{
            marginLeft: 4,
            marginTop: 4,
          }}
          source={{
            uri: senderAvatar ?? '',
          }}
          rounded
          size="medium"
          onPress={goToProfile}
        />
      ) : (
        <HorizontalContainer>
          <DiscussionBoardMessageActionSheetPressable
            style={{
              zIndex: 1,
              paddingLeft: 4,
            }}
            isAuthorizedToManageDiscussionBoard={
              props.isAuthorizedToManageDiscussionBoard
            }
            message={props.message}
            onDelete={() => {
              setDeleting(true);
            }}
            onDeleteFailed={() => {
              setDeleting(false);
              Alert.alert('Failed to delete message', 'Please try again later');
            }}
            onDeleteSuccess={props.onMessageDeleted}
          >
            <Ellipse
              width={18}
              height={18}
              fill={props.actionSheetButtonColor ?? KEY_GRAY}
            />
          </DiscussionBoardMessageActionSheetPressable>
          <View style={styles.spacer} />
        </HorizontalContainer>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  bodyContainer: {
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: 'auto',
    maxWidth: 400,
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 8,
  },
  spacer: {
    width: 10,
  },
  senderName: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },
  teamMemberName: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 14,
  },
  bodyText: {
    fontFamily: 'Lato',
    fontSize: 15,
  },
  timestampText: {
    color: 'gray',
    fontFamily: 'Lato',
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorText: {
    fontFamily: 'LeagueSpartan-Bold',
    color: 'crimson',
  },
  retryButtonText: {
    fontFamily: 'LeagueSpartan-Bold',
    padding: 8,
  },
});
