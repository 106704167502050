import { useCallback, useEffect, useRef, useState } from 'react';

type PaginatedResponse = {
  nextToken?: string | null;
  prevToken?: string | null;
  items?: any[];
};

type UseCursorsResponse = {
  nextToken: string | null;
  prevToken: string | null;
  /** If pagination conditions change, reset the cursors */
  reset: () => void;
};

type PaginationInputs = {
  nextToken: string | null;
  prevToken: string | null;
};

/** useCursors watches a paginated response and returns the nextToken and prevToken.
 * If the first page is reached, we don't return any more prevTokens, even if subsequent network requests
 * return include them (since the cache should have all the previous pages)
 * Similarly, if the last page is reached, we don't return any more nextTokens, even if subsequent network requests
 * return include them (since the cache should have all the next pages) */
export default function useCursors(
  data: PaginatedResponse | undefined | null,
  paginationInputs: PaginationInputs,
): UseCursorsResponse {
  const { nextToken, prevToken, items } = data || {};

  const [hasInitialized, setHasInitialized] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(true);
  const [hasNext, setHasNext] = useState(true);

  const seenPrevTokens = useRef<Set<string>>(new Set());
  const seenNextTokens = useRef<Set<string>>(new Set());

  const [oldestPrevToken, setOldestPrevToken] = useState<string | null>(null);
  const [latestNextToken, setLatestNextToken] = useState<string | null>(null);

  useEffect(() => {
    if (!items) return;

    setHasInitialized(true);

    /** If we load a page with no prevToken, we don't have any more previous pages */
    if (!prevToken) {
      setHasPrevious(false);
    } else {
      setOldestPrevToken((existingValue) => {
        /** If we've already seen this prevToken, we don't need to update the oldestPrevToken */
        if (seenPrevTokens.current.has(prevToken)) return existingValue;
        seenPrevTokens.current.add(prevToken);

        /** If this is first page, or we are paginating backward, this prevToken will be the oldest */
        if (
          !existingValue ||
          (paginationInputs.prevToken && !paginationInputs.nextToken)
        ) {
          return prevToken;
        }

        /** Otherwise, this prevToken is not the oldest */
        return existingValue;
      });
    }

    /** If we load a page with no nextToken, we don't have any more next pages */
    if (!nextToken) setHasNext(false);
    else {
      setLatestNextToken((existingValue) => {
        /** If we've already seen this nextToken, we don't need to update the latestNextToken */
        if (seenNextTokens.current.has(nextToken)) return existingValue;
        seenNextTokens.current.add(nextToken);

        /** If this is last page, or we are paginating forward, this nextToken will be the latest */
        if (!existingValue || paginationInputs.nextToken) {
          return nextToken;
        }

        /** Otherwise, this nextToken is not the latest */
        return existingValue;
      });
    }
  }, [
    items,
    nextToken,
    paginationInputs.nextToken,
    paginationInputs.prevToken,
    prevToken,
  ]);

  const reset = useCallback(() => {
    setHasInitialized(false);
    setHasPrevious(true);
    setHasNext(true);
    setOldestPrevToken(null);
    setLatestNextToken(null);
    seenPrevTokens.current.clear();
    seenNextTokens.current.clear();
  }, []);

  if (!hasInitialized) {
    return {
      nextToken: null,
      prevToken: null,
      reset,
    };
  }

  return {
    nextToken: hasNext ? latestNextToken || null : null,
    prevToken: hasPrevious ? oldestPrevToken || null : null,
    reset,
  };
}
