import {
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { OrganizationBigIssue } from '/generated/graphql';
import { KEY_GRAY } from '/constants';
import dayjs from 'dayjs';
import CrossCircle from '/assets/jsicons/CrossCircle';
import Button from '/components/Button';
import { DeepPartial } from '/types';
import GenericError from '../../common/Generic/GenericError';

interface Props {
  isVisible: boolean;
  drafts: DeepPartial<OrganizationBigIssue>[] | undefined;
  selectedDraftId: string | undefined;
  hasError: boolean;
  isFetching: boolean;
  onRetry: () => void;
  onSelectDraft: (id: string | undefined) => void;
  onDeleteDraft: (id: string) => Promise<void>;
}

export default function OrganizationBigIssueDraftMenu(props: Props) {
  const [promptDeleteId, setPromptDeleteId] = useState<string>();
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  useEffect(() => {
    setPromptDeleteId(undefined);
  }, [props.isVisible]);

  function onDeletePressed(id: string) {
    setPromptDeleteId(id);
  }

  function onConfirmDelete() {
    if (promptDeleteId === undefined) return;

    setDeletingIds((prevState) => [...prevState, promptDeleteId]);
    props.onDeleteDraft(promptDeleteId).then(() => {
      setDeletingIds((prevState) =>
        prevState.filter((id) => id !== promptDeleteId),
      );
    });
    setPromptDeleteId(undefined);
  }

  function onCancelDelete() {
    setPromptDeleteId(undefined);
  }

  return (
    <View style={styles.container}>
      {!props.drafts?.length ? (
        <Text style={styles.emptyText}>
          {props.isFetching ? 'Fetching drafts...' : 'You have no drafts.'}
        </Text>
      ) : null}
      <FlatList
        data={props.drafts}
        ListHeaderComponent={
          props.selectedDraftId !== undefined ? (
            <TouchableOpacity
              activeOpacity={0.72}
              style={styles.createNewButton}
              onPress={() => props.onSelectDraft(undefined)}
            >
              <Text style={styles.createNewButtonText}>+ Create New</Text>
            </TouchableOpacity>
          ) : null
        }
        renderItem={({ item }) => {
          if (!item.id) return null;

          const isSelected = props.selectedDraftId === item.id;

          return (
            <Pressable
              disabled={deletingIds.includes(item.id)}
              style={[
                styles.draftCard,
                {
                  backgroundColor: isSelected ? '#fafafa' : '#eaeaea',
                  opacity: deletingIds.includes(item.id) ? 0.5 : 1,
                },
              ]}
              onPress={() => props.onSelectDraft(item.id)}
            >
              {promptDeleteId === item.id ? (
                <>
                  <Text style={styles.deletePromptText}>Delete?</Text>
                  <View style={styles.deletePromptButtonContainer}>
                    <Button
                      label="Delete"
                      onPress={onConfirmDelete}
                      style={{
                        backgroundColor: 'crimson',
                      }}
                      containerStyle={{
                        marginRight: 8,
                      }}
                      labelStyle={{
                        color: 'white',
                        fontSize: 15,
                      }}
                    />
                    <Button
                      label="Cancel"
                      onPress={onCancelDelete}
                      labelStyle={{
                        fontSize: 15,
                      }}
                    />
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.draftCardLeftContainer}>
                    <Text numberOfLines={2} style={styles.draftTitle}>
                      {item.title?.text?.trim() || 'Untitled Issue'}
                    </Text>
                    <Text style={styles.draftUpdatedAt}>
                      Last updated {dayjs(Number(item.updated_at)).format('L')}
                    </Text>
                  </View>
                  <Pressable
                    style={{
                      paddingLeft: 4,
                    }}
                    onPress={() => onDeletePressed(item.id!)}
                  >
                    <CrossCircle fill="crimson" width={20} height={20} />
                  </Pressable>
                </>
              )}
            </Pressable>
          );
        }}
        ListFooterComponent={
          props.hasError && !props.drafts?.length ? (
            <GenericError
              onRetry={props.onRetry}
              message="There was a problem fetching drafts"
            />
          ) : null
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 8,
    marginLeft: 10,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginVertical: 24,
    textAlign: 'center',
  },
  draftCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'gray',
    padding: 8,
    paddingBottom: 16,
    marginVertical: 4,
    borderRadius: 6,
  },
  draftCardLeftContainer: {
    flex: 1,
    paddingRight: 8,
  },
  draftTitle: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginVertical: 2,
  },
  draftUpdatedAt: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 14,
  },
  deletePromptText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
  },
  deletePromptButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  createNewButton: {
    borderRadius: 6,
    backgroundColor: '#eee',
    padding: 12,
    marginBottom: 8,
  },
  createNewButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
});
