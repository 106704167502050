/**
 * Compares two version strings in the format "x.y.z" and determines their relative order.
 *
 * @param a - First version string to compare (e.g., "1.2.3")
 * @param b - Second version string to compare (e.g., "1.2.4")
 * @returns
 *  -1 if version a is lower than version b
 *   0 if versions are equal
 *   1 if version a is higher than version b
 *
 * @example
 * compareVersionStrings("1.2.3", "1.2.4") // returns -1
 * compareVersionStrings("2.0.0", "1.9.9") // returns 1
 * compareVersionStrings("1.2.3", "1.2.3") // returns 0
 * compareVersionStrings("1.2", "1.2.0") // returns 0
 * compareVersionStrings("1.2.4", "1.3") // returns -1
 */
export default function compareVersionStrings(a: string, b: string): number {
  const aParts = a?.split('.') ?? [];
  const bParts = b?.split('.') ?? [];

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const aPart = parseInt(aParts[i]);
    const bPart = parseInt(bParts[i]);
    if (aPart !== bPart) {
      return aPart < bPart ? -1 : 1;
    }
  }

  // If we get here, all common parts are equal
  // Compare lengths to handle cases like "1.2" vs "1.2.0"
  const lengthDiff = aParts.length - bParts.length;
  if (lengthDiff === 0) return 0;

  // Check if remaining parts are all zeros
  const longerParts = lengthDiff > 0 ? aParts : bParts;
  const startIdx = Math.min(aParts.length, bParts.length);

  for (let i = startIdx; i < longerParts.length; i++) {
    if (parseInt(longerParts[i]) !== 0) {
      return lengthDiff > 0 ? 1 : -1;
    }
  }

  return 0;
}
