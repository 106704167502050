import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import SearchableSection from '../ImpactTab/SearchableSection';
import ListLoading from '../ListLoading';
import NewsPostCard from '../NewsPostCard/NewsPostCard';
import TruncatedList from '../TruncatedList/TruncatedList';
import GenericError from '../common/Generic/GenericError';
import { useListNewsPostsQuery } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import { isOrganizationProfileComplete, shorten } from '/util';
import { Text, TextStyle, View } from 'react-native';
import Button from '../Button';
import { useTeamContext } from '/context';

type Props = {
  userId: string;
  hideUntilHasContent?: boolean;
  titleStyle?: TextStyle;
};

export default function MyNewsPosts(props: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const { activeTeam } = useTeamContext();

  const [, queryDebounced, setQuery] = useDebouncedState('');

  const [{ data, fetching, error }, listNewsPosts] = useListNewsPostsQuery({
    variables: {
      filter: {
        userId: props.userId,
        draft: false,
        query: queryDebounced,
      },
    },
  });

  function onViewMore() {
    navigation.navigate('ListNewsPosts', {
      userId: props.userId,
      q: queryDebounced,
    });
  }

  return props.hideUntilHasContent &&
    !data?.listNewsPosts.items.length ? null : (
    <SearchableSection
      title="News Posts"
      loading={fetching}
      titleStyle={props.titleStyle}
      contentContainerStyle={{
        backgroundColor: 'white',
        paddingHorizontal: 16,
      }}
      renderIcon={() => (
        <FontAwesome name="newspaper-o" size={21} color="black" />
      )}
      onQueryChange={setQuery}
    >
      {!data && fetching ? (
        <ListLoading />
      ) : !data && error ? (
        <GenericError onRetry={listNewsPosts} />
      ) : !data?.listNewsPosts.items.length ? (
        <View
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            padding: 24,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              fontFamily: 'Lato-Bold',
              fontSize: 16,
            }}
          >
            {!queryDebounced
              ? 'No news posts yet.'
              : `No news posts matching "${shorten(queryDebounced, 28)}".`}
          </Text>
          {!queryDebounced &&
          isOrganizationProfileComplete(activeTeam?.user) ? (
            <Button
              label="Create News Post"
              containerStyle={{
                marginTop: 8,
              }}
              onPress={() => {
                navigation.navigate('CreateNewsPost');
              }}
            />
          ) : null}
        </View>
      ) : (
        <TruncatedList
          items={data?.listNewsPosts.items ?? []}
          total={data?.listNewsPosts.total}
          maxLength={2}
          onViewMore={onViewMore}
          renderItem={(item) => (
            <NewsPostCard
              hideNewsHeader
              data={item}
              onPress={() => {
                navigation.navigate('ViewNewsPost', {
                  id: item.id,
                });
              }}
            />
          )}
        />
      )}
    </SearchableSection>
  );
}
