import { Entypo, FontAwesome } from '@expo/vector-icons';
import React from 'react';
import { Pressable, Text, View } from 'react-native';
import Tooltip from '../../Tooltip';
import styles from './PasswordTooltip.style';

interface IPasswordTooltipProps {
  password: string;
  show: boolean;
  onTapToHide: () => void;
  position?: 'top' | 'bottom';
}

const PasswordTooltip = (props: IPasswordTooltipProps) => {
  const showCircle = (checked: boolean) => {
    if (checked) {
      return <FontAwesome name="check-circle" size={12} color="green" />;
    } else return <Entypo name="circle-with-cross" size={12} color="red" />;
  };

  const subrequirements = [
    {
      desc: 'Lower case letters (a-z)',
      regex: /(?=.*[a-z])/g,
    },
    {
      desc: 'Upper case letters (A-Z)',
      regex: /(?=.*[A-Z])/g,
    },
    {
      desc: 'Numbers (i.e. 0-9)',
      regex: /(?=.*\d)/g,
    },
    {
      desc: `Special characters (e.g. !@#$%^&*)`,
      regex: /[^A-z\s\d][\\^]?/g,
    },
  ];

  const numVerified = subrequirements.filter((req) =>
    props.password.match(req.regex),
  ).length;

  return (
    <Tooltip
      position={props.position}
      opacity={0.85}
      align="center"
      maxWidth="100%"
      height={168}
      show={props.show}
      allowPointerEvents={props.show}
    >
      <Pressable
        style={{
          flex: 1,
        }}
        onPress={props.onTapToHide}
      >
        <Text style={styles.label}>Your password must contain:</Text>
        <View style={styles.passwordRequirement}>
          {showCircle(/^(?=.{8,24}$)/gi.test(props.password))}
          <Text style={styles.sublabel}>
            At least 8 characters, but no more than 24
          </Text>
        </View>
        <Text style={styles.label}>
          At least 3 of the following 4 types of characters:
        </Text>
        <View style={styles.subRequirements}>
          {subrequirements.map((requirement, index) => {
            const doesMatch = requirement.regex.test(props.password);
            return (
              <View key={index} style={styles.passwordRequirement}>
                {showCircle(numVerified >= 3 || doesMatch)}
                <Text
                  style={[
                    styles.sublabel,
                    {
                      textDecorationLine:
                        numVerified >= 3 && !doesMatch
                          ? 'line-through'
                          : 'none',
                    },
                  ]}
                >
                  {requirement.desc}
                </Text>
              </View>
            );
          })}
        </View>
        <Text style={styles.tapToHide}>TAP TO HIDE</Text>
      </Pressable>
    </Tooltip>
  );
};

export default PasswordTooltip;
