import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';

const fragment = gql`
  fragment NewDirectConversationMessageGetDirectConversation on Query {
    listDirectConversations(
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      total
      items {
        user1Id
        user2Id
        latest_message {
          id
          created_at
          body {
            id
            text
            language
          }
          sender {
            id
            name
            profile_image
          }
        }
      }
    }
  }
`;

const newDirectConversationMessage: UpdateResolver = (result, args, cache) => {
  const allFields = cache.inspectFields({
    __typename: 'Query',
    id: null,
  });
  const queryFields = allFields.filter(
    (field) => field.fieldName === 'listDirectConversations',
  );

  const alreadyInCache = queryFields.some((f) => {
    // Check each field `items` array for a conversation with the same user ids
    const data = cache.readFragment(
      fragment,
      {
        __typename: 'Query',
        id: null,
      },
      f.arguments || {},
    );

    if ((data as any)?.listDirectConversations?.items?.length) {
      const conversation = (data as any).listDirectConversations.items.find(
        (c: any) =>
          c.user1Id === (result.newDirectConversationMessage as any)?.user1Id &&
          c.user2Id === (result.newDirectConversationMessage as any)?.user2Id,
      );
      return !!conversation;
    }

    return false;
  });

  /**
   * If this conversation already exists in the cache, it will be updated automatically, so do nothing
   */
  if (alreadyInCache) {
    return;
  }

  /** Add item to response used in the conversations screen */
  addItemToPaginatedField({
    cache,
    entity: {
      __typename: 'Query',
      id: null,
    },
    newItem: result.newDirectConversationMessage,
    paginatedField: {
      name: 'listDirectConversations',
      fragment,
    },
  });
};

export default newDirectConversationMessage;
