import { KeyboardAwareFlatList } from '@mtourj/react-native-keyboard-aware-scroll-view';
import React, { useEffect, useState } from 'react';
import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  SortDirection,
  SystemSurveyPlacement,
  useSearchResearchTopicsQuery,
} from '/generated/graphql';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from '../Button';
import GenericListFooter from '../GenericListFooter';
import commonStyles from './common/styles';

interface Props {
  query: string;
}

export default function ResearchTopicSearch(props: Props) {
  const [nextToken, setNextToken] = useState<string>();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ data, fetching, error }] = useSearchResearchTopicsQuery({
    variables: {
      limit: 20,
      query: props.query || '',
      sortDirection: SortDirection.Asc,
      nextToken,
    },
    // pause: !props.query?.trim(),
  });

  useEffect(() => {
    // If we change our search query, nextToken is no longer valid in this context
    // and so we should set it to undefined
    setNextToken(undefined);
  }, [props.query]);

  function onTopicPressed(topicName: string) {
    navigate('ResearchTopicSummary', { topic: topicName });
  }

  function onPressSuggestNewTopic() {
    navigate('TakeSurvey', {
      placement: SystemSurveyPlacement.NewResearchTopicSuggestion,
    });
  }

  return (
    <KeyboardAwareFlatList
      enableResetScrollToCoords={false}
      onEndReached={() => {
        if (data?.searchResearchTopics.nextToken) {
          setNextToken(data.searchResearchTopics.nextToken);
        }
      }}
      onEndReachedThreshold={0.2}
      onScrollBeginDrag={() => {
        Keyboard.dismiss();
      }}
      ListEmptyComponent={
        fetching ? null : props.query && error ? (
          <Text style={commonStyles.errorText}>
            There was a problem searching research topics
          </Text>
        ) : props.query && !data?.searchResearchTopics.items.length ? (
          <Text style={commonStyles.emptyText}>No results</Text>
        ) : (
          <Text style={commonStyles.emptyText}>Search by research topic</Text>
        )
      }
      ListHeaderComponent={
        fetching || !props.query ? null : (
          <View
            style={{
              padding: 24,
              alignSelf: 'center',
              maxWidth: 320,
              paddingBottom: 0,
            }}
          >
            <Text
              style={{
                fontFamily: 'Lato-Bold',
                fontSize: 16,
                color: 'gray',
                textAlign: 'center',
              }}
            >
              Can't find what you're looking for and would like us to add it?
            </Text>
            <Button
              label="Suggest New Topic"
              containerStyle={{
                marginVertical: 6,
                alignSelf: 'center',
              }}
              onPress={onPressSuggestNewTopic}
            />
          </View>
        )
      }
      ItemSeparatorComponent={ItemSeparator}
      ListFooterComponent={
        <GenericListFooter
          hasMore={!!data?.searchResearchTopics.nextToken}
          isFirstPage={!nextToken}
          loading={fetching}
          onFetchMore={() => {
            data?.searchResearchTopics.nextToken &&
              setNextToken(data?.searchResearchTopics.nextToken);
          }}
        />
      }
      renderItem={({ item }) => {
        return (
          <TouchableOpacity
            key={item.topic}
            onPress={() => onTopicPressed(item.topic)}
            style={styles.itemContainer}
          >
            <Text style={styles.itemText} numberOfLines={2}>
              #{item.topic}
            </Text>
          </TouchableOpacity>
        );
      }}
      style={{ flex: 1 }}
      data={data?.searchResearchTopics.items}
    />
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    padding: 12,
    height: 64,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    textTransform: 'uppercase',
  },
});

function ItemSeparator() {
  return <View style={commonStyles.itemSeparator} />;
}
