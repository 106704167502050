import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import {
  createCommonCluster,
  ProfileScreenOptions,
  getStackNavigatorOptions,
} from './common';
import ProfileScreen from '/screens/ProfileScreen';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAppInfo } from '/context/AppInfoProvider';

const Stack = createStackNavigator<ProfileStackParamList>();

export type ProfileStackParamList = {
  MyProfile: {
    id: string;
    section?: 'big_issues';
  };
  Profile: {
    id: string;
    section?: 'big_issues';
  };
};

export default function ProfileStack() {
  const safeAreaInsets = useSafeAreaInsets();
  const { isFeatureFlagEnabled } = useAppInfo();
  return (
    <Stack.Navigator
      initialRouteName="MyProfile"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        name="MyProfile"
        component={ProfileScreen}
        options={ProfileScreenOptions}
      />
      {createCommonCluster(Stack, isFeatureFlagEnabled)}
    </Stack.Navigator>
  );
}
