import React, { useCallback } from 'react';
import { AppInfoQuery, useAppInfoQuery } from '/generated/graphql';

type AppInfoContextType = {
  appInfo: AppInfoQuery['appInfo'] | undefined;
  isFeatureFlagEnabled: (featureFlag: string) => boolean;
};

const Context = React.createContext({} as AppInfoContextType);

export const useAppInfo = () => React.useContext(Context);

export default function AppInfoProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [{ data }] = useAppInfoQuery();

  const isFeatureFlagEnabled = useCallback(
    (featureFlag: string) => {
      return (
        data?.appInfo?.feature_flags?.find((flag) => flag.name === featureFlag)
          ?.value === true
      );
    },
    [data],
  );

  return (
    <Context.Provider value={{ appInfo: data?.appInfo, isFeatureFlagEnabled }}>
      {children}
    </Context.Provider>
  );
}
