import React, { useRef, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Image,
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleProp,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import styles from '../../constants/Comments/WriteComment';
import Avatar from '../Avatar';

import { AntDesign, FontAwesome6, Ionicons } from '@expo/vector-icons';
import { RouteProp, useLinkTo, useRoute } from '@react-navigation/native';
import { StyleSheet } from 'react-native';
import ScrollView from '../common/ScrollView/ScrollView';
import UploadMedia from '../UploadMedia/UploadMedia';
import UserMentionTextInput, {
  IUserMention,
  UserMentionSuggestionContextProps,
} from '../UserMentionTextInput/UserMentionTextInput';
import { ALERT_RED, KEY_GRAY } from '/constants';
import { useAuthContext } from '/context';
import { determineIfVideo } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

const DEFAULT_MAX_HEIGHT = 150;

type ButtonBarProps = {
  onLayout?: (event: LayoutChangeEvent) => void;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  isSubmitting: boolean;
  enableMediaUpload?: boolean;
  media?: string[];
  mediaUploadIcon: JSX.Element | undefined;
  onUploadingMediaChanged: (
    media: { uri: string; thumbnailUri?: string }[],
  ) => void;
  onChangeMedia?: (media: string[]) => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  uploadMediaIconColor?: string;
  buttonBarExtensionComponent?: JSX.Element;
};

const ButtonBar = ({
  onLayout,
  style,
  contentContainerStyle,
  disabled,
  isSubmitting,
  enableMediaUpload,
  media = [],
  onUploadingMediaChanged,
  mediaUploadIcon,
  onChangeMedia,
  onUploadStart,
  onUploadEnd,
  uploadMediaIconColor,
  buttonBarExtensionComponent,
}: ButtonBarProps) => (
  <ScrollView
    horizontal
    onLayout={onLayout}
    style={[{ height: 32 }, style]}
    contentContainerStyle={[
      {
        paddingBottom: 0,
        alignItems: 'center',
      },
      contentContainerStyle,
    ]}
  >
    {enableMediaUpload ? (
      <UploadMedia
        multiple
        disabled={disabled || isSubmitting}
        selectionLimit={10}
        media={media.map((m) => ({ uri: m }))}
        onUploadingMediaChanged={onUploadingMediaChanged}
        onChangeMedia={(_media) => {
          onChangeMedia?.(_media.map((m) => m.uri));
        }}
        onUploadStart={onUploadStart}
        onUploadEnd={onUploadEnd}
        renderComponent={(pressed, onPress) => {
          return (
            <Pressable
              onPress={onPress}
              style={{
                minHeight: MIN_HEIGHT - 8,
                opacity: pressed ? 0.6 : 1,
                justifyContent: 'center',
                marginLeft: 8,
              }}
            >
              {mediaUploadIcon ?? (
                <FontAwesome6
                  name="image"
                  size={22}
                  color={uploadMediaIconColor ?? KEY_GRAY}
                />
              )}
            </Pressable>
          );
        }}
      />
    ) : null}
    {buttonBarExtensionComponent}
  </ScrollView>
);

export type WriteCommentProps = {
  /** Called when user submits. Expects `true` when comment posts successfully, `false` otherwise. */
  onSubmitComment: () => Promise<boolean>;
  onChangeText?: (text: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  returnToOnLogIn?: string;
  /** Maximum length of text */
  maxLength?: number;
  /** Specify the avatar to render in the component. Default is logged in user's profile image. */
  avatarUri?: string;
  inputRef?: React.RefObject<any>;
  avatarContainerStyle?: ViewStyle;
  /** Value of the TextInput */
  value: string | undefined;
  /** Disables interaction and reduces opacity */
  disabled?: boolean;
  /** Allows hiding submit button in case an external button is used instead */
  hideSubmitButton?: boolean;
  /** Label for the submission butotn. Default is 'Post' */
  submitLabel?: string;
  style?: StyleProp<ViewStyle>;
  /** Placeholder text for the TextInput */
  placeholderText?: string;
  placeholderTextColor?: string;
  textInputStyle?: StyleProp<TextStyle>;
  textInputContainerStyle?: StyleProp<ViewStyle>;
  loginPromptText?: string;
  loginPromptTextStyle?: StyleProp<TextStyle>;
  maxDynamicHeight?: number;
  /** Default is `left` */
  buttonBarPlacement?: 'left' | 'top' | 'right';
  /** Component to render in the button bar */
  buttonBarExtensionComponent?: JSX.Element;
  /** Media upload related props */
  media?: string[];
  mediaUploadIcon?: JSX.Element;
  onChangeMedia?: (media: string[]) => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  uploadMediaIconColor?: string;
} & (
  | {
      enableMentions: true;
      mentions: IUserMention[];
      onChangeMentions: (mentions: IUserMention[]) => void;
      mentionSuggestionContext: UserMentionSuggestionContextProps;
    }
  | { enableMentions?: false | undefined }
) &
  (
    | {
        enableMediaUpload: true;
        media: string[];
        onChangeMedia: (media: string[]) => void;
        uploadMediaIconColor?: string;
        onUploadStart?: () => void;
        onUploadEnd?: () => void;
      }
    | {
        enableMediaUpload?: false | undefined;
      }
  );

const MIN_HEIGHT = 20;

const MEDIA_HEIGHT = 96;

const WriteComment = (props: WriteCommentProps) => {
  const buttonBarPlacement = props.buttonBarPlacement ?? 'left';

  const [uploadingMedia, setUploadingMedia] = useState<
    { uri: string; thumbnailUri?: string }[]
  >([]);

  const [inputHeight, setInputHeight] = useState(MIN_HEIGHT);

  const mediaExtraHeight =
    props.enableMediaUpload && (props.media.length || uploadingMedia.length)
      ? MEDIA_HEIGHT
      : 0;

  const { userData } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const buttonBarHeight = useRef(32);
  const onButtonBarLayout = (event: LayoutChangeEvent) => {
    if (buttonBarPlacement === 'top')
      buttonBarHeight.current = event.nativeEvent.layout.height;
    else buttonBarHeight.current = 0;
  };

  const onSubmit = () => {
    if (isSubmitting || shouldDisableSubmit) return;

    setIsSubmitting(true);
    props
      .onSubmitComment()
      ?.then?.((successful) => {
        if (successful) {
          // Only clear text if the comment was successfully submitted
          props.onChangeText?.('');
          props.enableMediaUpload && props.onChangeMedia?.([]);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const shouldDisableSubmit =
    (!props.value?.trim() &&
      !(props.enableMediaUpload && props.media.length)) ||
    isSubmitting ||
    (!!props.enableMediaUpload && !!uploadingMedia.length);

  return (
    <View
      style={[
        styles.replyView,
        props.style,
        props.disabled
          ? {
              opacity: 0.6,
            }
          : null,
      ]}
      pointerEvents={props.disabled ? 'none' : 'auto'}
    >
      <View style={styles.replyAvatar}>
        <Avatar
          rounded
          size="small"
          source={{
            uri: props.avatarUri ?? userData?.profile_image ?? '',
          }}
          containerStyle={props.avatarContainerStyle}
        />
      </View>
      {!userData?.id ? (
        <LoginPrompt
          loginPromptText={props.loginPromptText}
          returnToOnLogIn={props.returnToOnLogIn}
          loginPromptTextStyle={props.loginPromptTextStyle}
        />
      ) : (
        <View
          style={[
            styles.inputWrapper,
            props.textInputContainerStyle,
            {
              minHeight:
                MIN_HEIGHT +
                mediaExtraHeight +
                (buttonBarPlacement === 'top' ? buttonBarHeight.current : 0),
              height: 'auto',
              pointerEvents: props.disabled || isSubmitting ? 'none' : 'auto',
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'column',
              height: 'auto',
              width: '100%',
            }}
          >
            {buttonBarPlacement === 'top' && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <ButtonBar
                  mediaUploadIcon={props.mediaUploadIcon}
                  onLayout={onButtonBarLayout}
                  style={{
                    flexGrow: 1,
                    flexShrink: 0,
                  }}
                  contentContainerStyle={{
                    flexGrow: 1,
                  }}
                  disabled={props.disabled}
                  isSubmitting={isSubmitting}
                  enableMediaUpload={props.enableMediaUpload}
                  media={props.media ?? []}
                  onUploadingMediaChanged={setUploadingMedia}
                  onChangeMedia={props.onChangeMedia}
                  onUploadStart={props.onUploadStart}
                  onUploadEnd={props.onUploadEnd}
                  uploadMediaIconColor={props.uploadMediaIconColor}
                  buttonBarExtensionComponent={
                    props.buttonBarExtensionComponent
                  }
                />
              </View>
            )}

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                flexGrow: 1,
              }}
            >
              {buttonBarPlacement !== 'top' &&
                buttonBarPlacement !== 'right' && (
                  <ButtonBar
                    mediaUploadIcon={props.mediaUploadIcon}
                    onLayout={onButtonBarLayout}
                    style={{
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                    contentContainerStyle={{
                      flexGrow: 0,
                    }}
                    disabled={props.disabled}
                    isSubmitting={isSubmitting}
                    enableMediaUpload={props.enableMediaUpload}
                    media={props.media ?? []}
                    onUploadingMediaChanged={setUploadingMedia}
                    onChangeMedia={props.onChangeMedia}
                    onUploadStart={props.onUploadStart}
                    onUploadEnd={props.onUploadEnd}
                    uploadMediaIconColor={props.uploadMediaIconColor}
                    buttonBarExtensionComponent={
                      props.buttonBarExtensionComponent
                    }
                  />
                )}

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  minWidth: 0,
                  marginRight: 8, // for scrollbar
                  height: 'auto',
                  alignSelf: 'stretch',
                }}
              >
                {props.enableMediaUpload && props.media.length ? (
                  <FlatList
                    horizontal
                    style={{
                      flexGrow: 0,
                      flexShrink: 0,
                      margin: 4,
                    }}
                    data={props.media.map((m) => ({
                      uri: m,
                      pendingUpload: uploadingMedia.some((u) => u.uri === m),
                    }))}
                    renderItem={({ item }) => {
                      return (
                        <View
                          style={{
                            marginTop: 8,
                            width: 50,
                            height: MEDIA_HEIGHT - 16,
                            marginRight: 10,
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              borderRadius: 6,
                              overflow: 'hidden',
                              backgroundColor: KEY_GRAY,
                            }}
                          >
                            <Image
                              source={{
                                uri: getCDNImageUri({
                                  uri: item.uri,
                                  isThumbnail: true,
                                }),
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                            {item.pendingUpload ? (
                              <View
                                style={[
                                  StyleSheet.absoluteFill,
                                  {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: 4,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  },
                                ]}
                              >
                                <ActivityIndicator size={12} color={'gray'} />
                              </View>
                            ) : null}

                            {determineIfVideo(item.uri) ? (
                              <Ionicons
                                name="videocam"
                                size={12}
                                color="white"
                                style={{
                                  position: 'absolute',
                                  top: 2,
                                  left: 2,
                                }}
                              />
                            ) : null}
                          </View>

                          <Pressable
                            onPress={() => {
                              props.onChangeMedia?.(
                                props.media.filter((m) => m !== item.uri),
                              );
                            }}
                            style={{
                              zIndex: 1,
                              position: 'absolute',
                              right: -7,
                              top: -7,
                              padding: 4,
                              backgroundColor: 'white',
                              borderRadius: 100,
                              elevation: 2,
                              shadowOffset: { width: 0, height: 0 },
                              shadowOpacity: 0.2,
                              shadowRadius: 2,
                              shadowColor: KEY_GRAY,
                            }}
                          >
                            <AntDesign
                              name="close"
                              size={14}
                              color={ALERT_RED}
                            />
                          </Pressable>
                        </View>
                      );
                    }}
                  />
                ) : null}
                {/* Shadow text input used to calculate text content height */}
                <TextInput
                  style={[
                    styles.input,
                    props.textInputStyle,
                    {
                      opacity: 0,
                      zIndex: -2,
                      pointerEvents: 'none',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      right: 0,
                      minHeight: MIN_HEIGHT,
                      maxHeight: props.maxDynamicHeight ?? DEFAULT_MAX_HEIGHT,
                      ...(Platform.OS === 'web'
                        ? {
                            // disable scrollbar completely
                            overflow: 'hidden',
                            outlineStyle: 'none',
                          }
                        : {}),
                    },
                  ]}
                  scrollEnabled={false}
                  value={props.value}
                  multiline
                  onContentSizeChange={(e) => {
                    const verticalMargin = 12;

                    // Use margins on web, and height on mobile to achieve
                    // the same appearance on both platforms
                    const heightOffset = Platform.select({
                      web: 0,
                      default: verticalMargin,
                    });

                    const newHeight =
                      Math.max(MIN_HEIGHT, e.nativeEvent.contentSize.height) +
                      heightOffset;
                    setInputHeight(
                      Math.min(
                        newHeight,
                        props.maxDynamicHeight ?? DEFAULT_MAX_HEIGHT,
                      ),
                    );
                  }}
                />
                <UserMentionTextInput
                  {...(props.enableMentions
                    ? props.mentionSuggestionContext
                    : { type: 'unspecified' })}
                  innerRef={props.inputRef}
                  disabled={props.disabled || isSubmitting}
                  placeholder={props.placeholderText ?? 'Write a comment...'}
                  placeholderTextColor={props.placeholderTextColor ?? '#3B3B3B'}
                  onChangeText={(text) => {
                    props.onChangeText?.(text);
                  }}
                  style={[
                    styles.input,
                    props.textInputStyle,
                    {
                      height: inputHeight,
                      minWidth: 0,
                      minHeight: MIN_HEIGHT,
                      maxHeight: props.maxDynamicHeight ?? DEFAULT_MAX_HEIGHT,
                      opacity: props.disabled || isSubmitting ? 0.6 : 1,
                      ...(Platform.OS === 'web'
                        ? {
                            outlineStyle: 'none',
                          }
                        : {}),
                    },
                  ]}
                  value={props.value}
                  textAlignVertical="center"
                  mentions={props.enableMentions ? props.mentions : []}
                  onChangeMentions={
                    props.enableMentions ? props.onChangeMentions : undefined
                  }
                  disableMentions={!props.enableMentions}
                  onBlur={props.onBlur}
                  onFocus={props.onFocus}
                  onSubmitEditing={onSubmit}
                  blurOnSubmit={false}
                  enterKeyHint="send"
                  multiline
                  maxLength={props.maxLength ?? 500}
                  scrollEnabled={false}
                />
              </View>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {buttonBarPlacement === 'right' && (
                  <ButtonBar
                    mediaUploadIcon={props.mediaUploadIcon}
                    style={{
                      flexGrow: 0,
                      flexShrink: 0,
                      marginLeft: 2,
                    }}
                    disabled={props.disabled}
                    isSubmitting={isSubmitting}
                    enableMediaUpload={props.enableMediaUpload}
                    media={props.media ?? []}
                    onUploadingMediaChanged={setUploadingMedia}
                    onChangeMedia={props.onChangeMedia}
                    onUploadStart={props.onUploadStart}
                    onUploadEnd={props.onUploadEnd}
                    uploadMediaIconColor={props.uploadMediaIconColor}
                    buttonBarExtensionComponent={
                      props.buttonBarExtensionComponent
                    }
                  />
                )}
                <Pressable
                  style={({ pressed }) => [
                    {
                      display: props.hideSubmitButton ? 'none' : 'flex',
                      opacity: pressed ? 0.6 : 1,
                      paddingHorizontal: 12,
                      paddingVertical: 4,
                      height: 32,
                      justifyContent: 'center',
                      marginLeft: 4,
                    },
                  ]}
                  disabled={shouldDisableSubmit}
                  onPress={onSubmit}
                >
                  {isSubmitting ? (
                    <ActivityIndicator
                      size={17}
                      style={{
                        alignSelf: 'center',
                      }}
                      color={KEY_GRAY}
                    />
                  ) : (
                    <Text
                      style={[
                        styles.commentButton,
                        {
                          color: shouldDisableSubmit
                            ? 'gray'
                            : styles.commentButton.color,
                        },
                      ]}
                    >
                      {props.submitLabel ?? 'Post'}
                    </Text>
                  )}
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

type LoginPromptProps = {
  returnToOnLogIn: string | undefined;
  loginPromptText: string | undefined;
  loginPromptTextStyle: StyleProp<TextStyle> | undefined;
};

function LoginPrompt(props: LoginPromptProps) {
  const route = useRoute<RouteProp<any>>();
  const linkTo = useLinkTo();

  return (
    <View style={styles.loginPromptContainer}>
      <Text style={[styles.loginPrompt, props.loginPromptTextStyle]}>
        <Text
          style={styles.loginPromptPressable}
          onPress={() => {
            let params = '';

            const returnTo = props.returnToOnLogIn || route.path;

            if (returnTo) {
              params = `?returnto=${encodeURIComponent(returnTo)}`;
            }

            linkTo(`/login${params}`);
          }}
        >
          Sign in
        </Text>{' '}
        to {props.loginPromptText ?? `leave a comment`}
      </Text>
    </View>
  );
}

//make this component available to the app
export default WriteComment;
