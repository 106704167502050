import ButtonToggle, { ButtonToggleProps } from '../ButtonToggle/ButtonToggle';

interface ISkillButtonProps extends Omit<ButtonToggleProps, 'label'> {
  skill: string;
}

export default function SkillButton({ skill, ...props }: ISkillButtonProps) {
  //   @ts-ignore
  return <ButtonToggle {...props} label={skill} />;
}
