import { AntDesign } from '@expo/vector-icons';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Keyboard, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import { v4 } from 'uuid';
import {
  CARD_TITLE_FONT_SIZE,
  DESKTOP_MAX_WIDTH,
  SECTION_CONTAINER,
} from '/constants';
import { useNavigationState } from '@react-navigation/native';

export interface IModalContext {
  /** Spawns a modal and returns an ID string */
  spawnModal: (modal: Omit<ModalProviderModal, 'id' | 'routeName'>) => string;
  closeModal: (id: string) => void;
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

export const useModalContext = () => useContext(ModalContext);

type ModalProviderModal = {
  id: string;
  title: string;
  style?: ViewStyle;
  /** Useful for preventing the modal from closing.
   * @returns true if the modal should close, false if it should not. */
  onWillClose?: () => boolean;
  disableCloseButton?: boolean;
  /** Disables entire header, including close button. */
  disableEntireHeader?: boolean;
  component?: React.ReactNode;
  /** Higher precedence will be rendered on top */
  precedence?: number;
  routeName: string;
};

type ModalProviderState = {
  modals: ModalProviderModal[];
};

export default function ModalProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [state, setState] = useState<ModalProviderState>({
    modals: [],
  } as ModalProviderState);

  const getCurrentRouteName = (navState: any): string => {
    if (!navState) return 'Unknown';

    if (navState.routes && navState.index !== undefined) {
      const currentRoute = navState.routes[navState.index];
      if (currentRoute.state) {
        return getCurrentRouteName(currentRoute.state);
      }
      return currentRoute.name;
    }

    if (navState.routes && navState.routes.length > 0) {
      const firstRoute = navState.routes[0];
      if (firstRoute.state) {
        return getCurrentRouteName(firstRoute.state);
      }
      return firstRoute.name;
    }

    return 'Unknown';
  };

  const currentRouteName = useNavigationState((navState) =>
    getCurrentRouteName(navState),
  );

  const spawnModal = useCallback(
    (modal: Omit<ModalProviderModal, 'id' | 'routeName'>) => {
      const id = v4();

      setState((prevState) => {
        const modals = [
          ...prevState.modals,
          { ...modal, id, routeName: currentRouteName },
        ];
        modals.sort((a, b) => (a.precedence || 0) - (b.precedence || 0));
        return { ...prevState, modals };
      });

      return id;
    },
    [currentRouteName],
  );

  const closeModal = (id: string) => {
    setState((prevState) => {
      const modals = prevState.modals.filter((m) => m.id !== id);
      return { ...prevState, modals };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        spawnModal,
        closeModal,
      }}
    >
      {children}
      <View pointerEvents="box-none" style={[StyleSheet.absoluteFill]}>
        {state.modals
          .filter((modal) => modal.routeName === currentRouteName)
          .map((modal) => {
            return (
              <CardModalComponent
                modal={modal}
                key={modal.id}
                onCloseModal={() => {
                  closeModal(modal.id);
                }}
              />
            );
          })}
      </View>
    </ModalContext.Provider>
  );
}

type CardModalComponentProps = {
  modal: ModalProviderModal;
  onCloseModal: () => void;
};

function CardModalComponent({ modal, ...props }: CardModalComponentProps) {
  const { styles } = useStyles();

  const safeAreaInsets = useSafeAreaInsets();

  const opacity = useSharedValue(0);

  const [keyboardOffset, setKeyboardOffset] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      (e) => {
        // Adjust the bottom position based on keyboard height
        setKeyboardOffset(e.endCoordinates.height);
      },
    );
    const keyboardWillHideListener = Keyboard.addListener(
      'keyboardWillHide',
      () => {
        // Reset the bottom position
        setKeyboardOffset(0);
      },
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  useEffect(() => {
    opacity.value = withTiming(1, {
      duration: 250,
      easing: Easing.out(Easing.poly(4)),
    });
  }, [opacity]);

  function onCloseModal() {
    if (
      typeof modal.onWillClose === 'function' &&
      modal.onWillClose() === false
    )
      return;

    opacity.value = withTiming(
      0,
      {
        duration: 220,
        easing: Easing.out(Easing.poly(4)),
      },
      () => {
        runOnJS(props.onCloseModal)();
      },
    );
  }

  const animatedContainerStyle = useAnimatedStyle(
    () => ({
      opacity: opacity.value,
    }),
    [],
  );

  return (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        styles('modalOverlay'),
        {
          zIndex: modal.precedence,
          marginBottom: keyboardOffset > 0 ? keyboardOffset : 0,
        },
        animatedContainerStyle,
      ]}
      onStartShouldSetResponder={() => {
        Keyboard.dismiss();
        return false;
      }}
    >
      <View
        style={{
          flex: 1,
          paddingTop: safeAreaInsets.top,
          paddingBottom: safeAreaInsets.bottom,
          paddingRight: safeAreaInsets.right,
          paddingLeft: safeAreaInsets.left,
          justifyContent: keyboardOffset > 0 ? 'flex-end' : 'center',
          alignItems: 'center',
        }}
      >
        <View style={[styles('modalCard'), modal.style]}>
          {modal.disableEntireHeader ? null : (
            <View style={styles('modalHeader')}>
              <Text style={styles('modalHeaderText')}>
                {modal.title?.toUpperCase()}
              </Text>

              {!modal.disableCloseButton ? (
                <AntDesign
                  name="close"
                  size={24}
                  style={{ padding: 4 }}
                  onPress={() => {
                    onCloseModal();
                  }}
                />
              ) : null}
            </View>
          )}

          {modal.component}
        </View>
      </View>
    </Animated.View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    modalOverlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      padding: 16,
    },
    modalCard: {
      ...SECTION_CONTAINER,
      width: '100%',
      maxWidth: DESKTOP_MAX_WIDTH.maxWidth,
      maxHeight: '100%',
      margin: 0,
      marginTop: 0,
      minWidth: 280,
      overflow: 'hidden',
    },
    modalHeader: {
      flexDirection: 'row',
      paddingBottom: 8,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    modalHeaderText: {
      fontFamily: 'LeagueSpartan-Bold',
      textTransform: 'uppercase',
      fontSize: CARD_TITLE_FONT_SIZE,
    },
  },
  {
    [minSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      modalCard: {
        minWidth: 400,
      },
    },
  },
);
