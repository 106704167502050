import { useAuthContext, useTeamContext } from '/context';
import { useAppInfo } from '/context/AppInfoProvider';
import { TeamMemberRole, TeamMembershipStatus, User } from '/generated/graphql';
import { isUnderPrivileged } from '/util';

const getUserIdNameAndProfileImage = (
  user: Pick<User, 'id' | 'name' | 'profile_image'>,
): Pick<User, 'id' | 'name' | 'profile_image'> => {
  return {
    id: user.id,
    name: user.name,
    profile_image: user.profile_image,
  };
};

/** Takes in user ID, and if signed in user has permission to send messages
 * on behalf of that user, returns that user's profile, otherwise returns
 * undefined.
 */
export const useDiscussionBoardSendingOnBehalfOfUser = (
  trySendAsUserId: string | undefined,
): Pick<User, 'id' | 'name' | 'profile_image'> | undefined => {
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();
  const { appInfo } = useAppInfo();

  /** Not signed in, cannot send messages on behalf of anyone */
  if (!userData) return;

  if (!trySendAsUserId || trySendAsUserId === userData.id) return undefined;

  /** If we are trying to send a message on behalf of the official account, then we simply have to be
   * an admin to do so */
  if (
    trySendAsUserId === appInfo?.official_account_profile?.id &&
    userData?.admin
  ) {
    return getUserIdNameAndProfileImage(appInfo?.official_account_profile);
  }

  /** Otherwise, check if we are authorized via team membership */
  const team = teams.find(
    (t) =>
      t.user.id === trySendAsUserId &&
      t.membership?.membership_status === TeamMembershipStatus.Confirmed &&
      !isUnderPrivileged(TeamMemberRole.Creator, t.membership?.team_role),
  );
  if (team) return getUserIdNameAndProfileImage(team.user);

  return undefined;
};
