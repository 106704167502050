import React, { useEffect } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Avatar from '../Avatar';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import { KEY_GRAY } from '/constants';
import { useGetUserMentionSuggestionsQuery, User } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';

type Props = {
  query: string;
  onMentionSelect: (user: Pick<User, 'id' | 'name'>) => void;
  discussionBoardId: string | null | undefined;
};

const SUGGESTION_ITEM_HEIGHT = 38;

export default function AtMentionSuggestions(props: Props) {
  const [, debouncedQuery, setDebouncedQuery] = useDebouncedState(props.query);
  useEffect(() => {
    setDebouncedQuery(props.query);
  }, [props.query, setDebouncedQuery]);

  const [{ data, fetching, error, stale }] = useGetUserMentionSuggestionsQuery({
    variables: {
      limit: 10,
      discussionBoardId: props.discussionBoardId,
      query: debouncedQuery,
    },
  });

  function onSelect(user: Pick<User, 'id' | 'name'>) {
    props.onMentionSelect(user);
  }

  const contentHeight =
    fetching || stale
      ? SUGGESTION_ITEM_HEIGHT
      : (data?.getUserMentionSuggestions.items.length ?? 1) *
        SUGGESTION_ITEM_HEIGHT;

  return props.query &&
    ((fetching && !data) || data?.getUserMentionSuggestions.items.length) &&
    !error ? (
    <View
      style={[
        styles.container,
        {
          height: contentHeight,
          top: -contentHeight,
        },
      ]}
    >
      {fetching || stale ? (
        <ActivityIndicator
          style={{
            alignSelf: 'center',
            padding: 12,
          }}
          color={KEY_GRAY}
        />
      ) : (
        <FlatList
          data={data?.getUserMentionSuggestions.items}
          keyExtractor={(item) => item.id}
          getItemLayout={(_, index) => ({
            index,
            length: SUGGESTION_ITEM_HEIGHT,
            offset: SUGGESTION_ITEM_HEIGHT * index,
          })}
          keyboardShouldPersistTaps="handled"
          style={{
            overflow: 'hidden',
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          renderItem={({ item }) => {
            return (
              <Pressable
                onPress={() => {
                  onSelect(item);
                }}
              >
                <HorizontalContainer
                  style={[
                    styles.suggestionContainer,
                    {
                      height: SUGGESTION_ITEM_HEIGHT,
                    },
                  ]}
                >
                  <Avatar
                    size={24}
                    rounded
                    source={{
                      uri: item.profile_image,
                    }}
                    containerStyle={{
                      marginRight: 4,
                    }}
                  />
                  <Text style={styles.suggestionName}>{item.name}</Text>
                </HorizontalContainer>
              </Pressable>
            );
          }}
        />
      )}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    zIndex: 9,
    position: 'absolute',
    left: 0,
    width: 300,
    maxHeight: 400,
    backgroundColor: 'white',
    shadowColor: '#111',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 2,
    borderRadius: 6,
  },
  suggestionContainer: {
    padding: 5,
  },
  itemSeparator: {
    height: 1,
    width: '100%',
    backgroundColor: '#ddd',
  },
  suggestionName: {},
});
