import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Badge } from '@rneui/base';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  Pressable,
  StyleProp,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import useStyles from '../TesterDashboard.style';
import TesterObjectiveCard from './TesterObjectiveCard';
import Button from '/components/Button';
import ListLoading from '/components/ListLoading';
import DiscussionBoardWidget from '/components/SkilledImpact/DiscussionBoardWidget';
import TruncatableText from '/components/TruncatableText';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import {
  BUTTON_LABEL_STYLE,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useLoadingContext } from '/context';
import {
  useListMyTesterGeneralFeedbackQuery,
  useListMyTesterObjectivesQuery,
} from '/generated/graphql';
import usePaginationHeaderAndFooter from '/hooks/usePaginationHeaderAndFooter';
import { truncateNumber } from '/util';

type PastFeedbackProps = {
  onFocusSection: () => void;
  initiallyFocusedObjectiveId?: string;
  mode: 'default' | 'section';
};

const PastFeedback = React.forwardRef<View, PastFeedbackProps>(
  ({ onFocusSection, mode, ...props }, ref) => {
    const navigation = useNavigation<StackNavigationProp<any>>();
    const { setShowLoading }: any = useLoadingContext();

    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedTab, setSelectedTab] = useState<
      'contributions' | 'general_feedback'
    >('contributions');

    const [generalFeedbackNextToken, setGeneralFeedbackNextToken] = useState<
      string | null
    >(null);
    const [objectivesNextToken, setObjectivesNextToken] = useState<
      string | null
    >(null);
    const [objectivesPrevToken, setObjectivesPrevToken] = useState<
      string | null
    >(null);
    const [expandedFeedbackId, setExpandedFeedbackId] = useState<string | null>(
      null,
    );
    const [expandedObjectiveId, setExpandedObjectiveId] = useState<
      string | null
    >(null);

    // Reset expanded states when switching tabs
    useEffect(() => {
      setExpandedFeedbackId(null);
      setExpandedObjectiveId(null);
    }, [selectedTab]);

    const focusedObjectiveAnimation = useRef(new Animated.Value(0)).current;

    const [initiallyFocusedObjectiveId, setInitiallyFocusedObjectiveId] =
      useState<string | undefined>(props.initiallyFocusedObjectiveId);

    const [
      {
        data: generalFeedbackData,
        fetching: fetchingGeneralFeedback,
        error: generalFeedbackError,
        stale: generalFeedbackStale,
      },
      refetchGeneralFeedback,
    ] = useListMyTesterGeneralFeedbackQuery({
      variables: {
        includeItems:
          selectedTab === 'general_feedback' &&
          (mode === 'section' ? isExpanded : true),
        nextToken: generalFeedbackNextToken,
        limit: mode === 'section' ? 5 : undefined,
      },
      requestPolicy: 'cache-and-network',
    });
    const [
      {
        data: objectivesData,
        fetching: fetchingObjectives,
        error: objectivesError,
        stale: objectivesStale,
      },
      refetchObjectives,
    ] = useListMyTesterObjectivesQuery({
      variables: {
        includeItems:
          selectedTab === 'contributions' &&
          (mode === 'section' ? isExpanded : true),
        completedOnly: true,
        nextToken: objectivesNextToken,
        prevToken: objectivesPrevToken,
        startWithId: initiallyFocusedObjectiveId,
        limit: mode === 'section' ? 5 : undefined,
      },
      requestPolicy: 'cache-and-network',
    });

    const paginatePrev = () => {
      if (selectedTab === 'general_feedback') {
        // setGeneralFeedbackNextToken(
        //   generalFeedbackData?.me?.tester_general_feedback?.nextToken,
        // );
      } else {
        if (objectivesData?.listMyTesterObjectives.prevToken) {
          setObjectivesNextToken(null); // clear nextToken because server prioritizes it over prevToken
          setObjectivesPrevToken(
            objectivesData.listMyTesterObjectives.prevToken,
          );
        }
      }
    };

    const paginateNext = () => {
      if (selectedTab === 'general_feedback') {
        if (generalFeedbackData?.me?.tester_general_feedback?.nextToken)
          setGeneralFeedbackNextToken(
            generalFeedbackData.me.tester_general_feedback.nextToken,
          );
      } else {
        if (objectivesData?.listMyTesterObjectives.nextToken) {
          setObjectivesNextToken(
            objectivesData.listMyTesterObjectives.nextToken,
          );
          setObjectivesPrevToken(null);
        }
      }
    };

    const isFetchingInitiallyFocusedObjective =
      !!initiallyFocusedObjectiveId &&
      !!fetchingObjectives &&
      !objectivesNextToken &&
      !objectivesPrevToken;

    const { Header: ObjectivesHeader, Footer: ObjectivesFooter } =
      usePaginationHeaderAndFooter({
        paginatedResponse: objectivesData?.listMyTesterObjectives,
        paginationInputs: {
          nextToken: objectivesNextToken,
          prevToken: objectivesPrevToken,
        },
        queryState: {
          fetching: fetchingObjectives,
          stale: objectivesStale,
          error: objectivesError,
        },
        emptyContainerStyle: {
          marginHorizontal: 0,
        },
        errorMessage: 'There was a problem fetching your completed objectives',
        onRetry: () => refetchObjectives(),
        onFetchNext: () => paginateNext(),
        onFetchPrevious: () => paginatePrev(),
      });

    const { Header: GeneralFeedbackHeader, Footer: GeneralFeedbackFooter } =
      usePaginationHeaderAndFooter({
        paginatedResponse: generalFeedbackData?.me?.tester_general_feedback,
        paginationInputs: {
          nextToken: generalFeedbackNextToken,
          prevToken: null,
        },
        queryState: {
          fetching: fetchingGeneralFeedback,
          stale: generalFeedbackStale,
          error: generalFeedbackError,
        },
        emptyContainerStyle: {
          marginHorizontal: 0,
        },
        emptyMessage: 'No submissions',
        errorMessage:
          'There was a problem fetching your submitted general feedback',
        onRetry: () => refetchGeneralFeedback(),
        onFetchNext: () => paginateNext(),
        onFetchPrevious: () => paginatePrev(),
      });

    useEffect(
      function updateFocusedObjectiveId() {
        if (props.initiallyFocusedObjectiveId)
          setInitiallyFocusedObjectiveId((prev) => {
            if (prev !== props.initiallyFocusedObjectiveId) {
              setSelectedTab('contributions');
              setObjectivesNextToken(null);
              setObjectivesPrevToken(null);
            }

            return props.initiallyFocusedObjectiveId;
          });
      },
      [props.initiallyFocusedObjectiveId],
    );

    useEffect(
      function resetShowLoading() {
        if (!fetchingObjectives) setShowLoading(false);
      },
      [fetchingObjectives, setShowLoading],
    );

    useFocusEffect(
      useCallback(() => {
        hasFocusedObjectiveId.current = false;
      }, []),
    );

    const hasFocusedObjectiveId = useRef(false);
    useEffect(
      function handleFocusObjectiveId() {
        if (!initiallyFocusedObjectiveId) return;
        if (hasFocusedObjectiveId.current) return;
        if (fetchingObjectives) {
          setShowLoading(true);
          return;
        }

        hasFocusedObjectiveId.current = true;
        setShowLoading(false);

        if (
          !objectivesData?.listMyTesterObjectives.items?.find(
            (item) => item.id === initiallyFocusedObjectiveId,
          )
        ) {
          return;
        }

        // Set to 1 immediately
        focusedObjectiveAnimation.setValue(1);

        // After 6 seconds, start a slow 3-second fade out
        setTimeout(() => {
          Animated.timing(focusedObjectiveAnimation, {
            toValue: 0,
            duration: 3000, // 3 second fade
            useNativeDriver: false,
          }).start(() => {});
        }, 6000);

        onFocusSection();
      },
      [
        objectivesData?.listMyTesterObjectives.items,
        navigation,
        fetchingObjectives,
        setShowLoading,
        onFocusSection,
        focusedObjectiveAnimation,
        props.initiallyFocusedObjectiveId,
        initiallyFocusedObjectiveId,
      ],
    );

    return (
      <View
        ref={ref}
        style={{
          marginTop: 40,
        }}
      >
        <View style={{ marginBottom: 16, flexDirection: 'row' }}>
          <ScrollView horizontal>
            <PastFeedbackTabButton
              hasUnread={!!objectivesData?.listMyTesterObjectives.totalUnreads}
              label="Testing Contributions"
              count={
                objectivesData?.listMyTesterObjectives
                  .totalCompleteIncludingExpired ?? 0
              }
              selected={
                mode === 'section'
                  ? isExpanded && selectedTab === 'contributions'
                  : selectedTab === 'contributions'
              }
              onPress={() => {
                if (mode === 'section') {
                  setIsExpanded(true);
                }
                setSelectedTab('contributions');
              }}
              buttonColors={
                mode === 'section'
                  ? {
                      unselectedBackgroundColor: '#ebebeb',
                    }
                  : {
                      unselectedBackgroundColor: '#e0e0e0',
                    }
              }
              style={{
                marginRight: 8,
              }}
            />
            <PastFeedbackTabButton
              label="Submitted Ideas"
              hasUnread={
                !!generalFeedbackData?.me?.tester_general_feedback?.totalUnreads
              }
              count={
                generalFeedbackData?.me?.tester_general_feedback?.total ?? 0
              }
              buttonColors={
                mode === 'section'
                  ? {
                      unselectedBackgroundColor: '#ebebeb',
                    }
                  : {
                      unselectedBackgroundColor: '#e0e0e0',
                    }
              }
              selected={
                mode === 'section'
                  ? isExpanded && selectedTab === 'general_feedback'
                  : selectedTab === 'general_feedback'
              }
              onPress={() => {
                if (mode === 'section') {
                  setIsExpanded(true);
                }
                setSelectedTab('general_feedback');
              }}
            />
          </ScrollView>

          {mode === 'section' ? (
            <Pressable
              onPress={() => setIsExpanded(!isExpanded)}
              style={{
                padding: 14.5,
              }}
            >
              <FontAwesome5
                name={isExpanded ? 'chevron-up' : 'chevron-down'}
                size={17}
                color={KEY_GRAY}
              />
            </Pressable>
          ) : null}
        </View>

        {mode === 'section' && !isExpanded ? null : (
          <>
            {selectedTab === 'contributions' ? (
              isFetchingInitiallyFocusedObjective ? (
                <ListLoading />
              ) : (
                <View
                  style={{
                    pointerEvents: 'box-none',
                  }}
                >
                  {ObjectivesHeader}
                  {objectivesData?.listMyTesterObjectives.items
                    ?.sort((a, b) =>
                      dayjs(b.feedback?.createdAt).diff(
                        dayjs(a.feedback?.createdAt),
                        'milliseconds',
                      ),
                    )
                    .map((item) => (
                      <TesterObjectiveCard
                        key={item.id}
                        collapsible
                        expanded={
                          initiallyFocusedObjectiveId === item.id ||
                          expandedObjectiveId === item.id
                        }
                        onToggleExpanded={(expanded) => {
                          setExpandedObjectiveId(expanded ? item.id : null);
                        }}
                        loading={fetchingObjectives}
                        objective={item}
                        refreshObjectives={() => refetchObjectives()}
                        style={{
                          marginBottom: 8,
                        }}
                        focusAnimationValue={
                          initiallyFocusedObjectiveId === item.id
                            ? focusedObjectiveAnimation
                            : undefined
                        }
                      />
                    ))}
                  {ObjectivesFooter}
                </View>
              )
            ) : (
              <>
                {GeneralFeedbackHeader}

                {generalFeedbackData?.me?.tester_general_feedback?.items?.map(
                  (item) => (
                    <View
                      key={item.id}
                      style={{
                        padding: 10,
                        borderWidth: 1,
                        borderRadius: 8,
                        marginBottom: 8,
                        backgroundColor: KEY_LIGHT_GRAY,
                      }}
                    >
                      <Pressable
                        onPress={() => {
                          setExpandedFeedbackId(
                            expandedFeedbackId === item.id ? null : item.id,
                          );
                        }}
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              color: 'gray',
                              fontFamily: 'Lato-Bold',
                              fontSize: 15,
                              marginBottom: 12,
                            }}
                          >
                            Submitted on{' '}
                            {dayjs(Number(item.created_at)).format('LLL')}
                          </Text>
                          <Text
                            style={{
                              fontFamily: 'FranklinGothic-Demi',
                              fontSize: 16,
                              color: KEY_GRAY,
                              marginBottom: 8,
                            }}
                          >
                            Original Feedback:
                          </Text>
                          <TruncatableText
                            text={
                              item.discussionBoard.messages?.items[0].body ?? ''
                            }
                            style={{
                              fontFamily: 'Lato',
                              fontSize: 16,
                              color: KEY_GRAY,
                            }}
                          />
                        </View>
                        <HorizontalContainer
                          style={{
                            marginLeft: 12,
                            marginTop: 4,
                            alignItems: 'center',
                          }}
                        >
                          {item.isUnread ? (
                            <HorizontalContainer
                              style={{ alignItems: 'center' }}
                            >
                              <MaterialCommunityIcons
                                name="message-badge"
                                size={16}
                                color="red"
                                style={{ marginRight: 4 }}
                              />
                              <Text
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: 13,
                                  color: KEY_GRAY,
                                  marginRight: 12,
                                }}
                              >
                                New
                              </Text>
                            </HorizontalContainer>
                          ) : null}
                          <FontAwesome5
                            name={
                              expandedFeedbackId === item.id
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                            size={17}
                            color={KEY_GRAY}
                            style={{ marginLeft: 10 }}
                          />
                        </HorizontalContainer>
                      </Pressable>

                      {expandedFeedbackId === item.id && (
                        <View style={{ marginTop: 24 }}>
                          <Text
                            style={{
                              fontFamily: 'FranklinGothic-Demi',
                              fontSize: 16,
                              color: KEY_GRAY,
                              marginBottom: 8,
                            }}
                          >
                            Continue the Discussion:
                          </Text>
                          <Text
                            style={{
                              fontFamily: 'Lato',
                              fontSize: 16,
                              color: KEY_GRAY,
                              marginBottom: 16,
                            }}
                          >
                            Have more thoughts to share about this feedback?
                            Feel free to add to the discussion below. Your
                            ongoing input helps us make the platform even
                            better.
                          </Text>
                          <DiscussionBoardWidget
                            data={{ id: item.discussionBoard.id }}
                            viewMoreAction="paginate-in-place"
                            scrollable
                            containerStyle={{
                              flex: 1,
                              maxHeight: 360,
                              backgroundColor: 'white',
                              borderRadius: 6,
                              marginTop: 10,
                              padding: 5,
                            }}
                            inputProps={{
                              buttonBarPlacement: 'right',
                              mediaUploadIcon: (
                                <MaterialCommunityIcons
                                  name="paperclip"
                                  size={22}
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </View>
                      )}
                    </View>
                  ),
                )}

                {GeneralFeedbackFooter}
              </>
            )}
          </>
        )}
      </View>
    );
  },
);

export default PastFeedback;

function PastFeedbackTabButton(props: {
  label: string;
  count: number;
  hasUnread: boolean;
  selected: boolean;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  buttonColors?: {
    selectedBackgroundColor?: string;
    unselectedBackgroundColor?: string;
  };
}) {
  const { styles } = useStyles();
  return (
    <>
      <Button
        label={
          <HorizontalContainer
            style={{
              padding: 4,
              height: 32,
            }}
          >
            {props.hasUnread ? (
              <Badge
                badgeStyle={{
                  backgroundColor: 'red',
                  borderWidth: 0,
                  width: 10,
                  height: 10,
                  borderRadius: 12,
                  marginRight: 5,
                }}
              />
            ) : null}
            <Text
              style={[BUTTON_LABEL_STYLE, styles('pastFeedbackTabButtonText')]}
            >
              {props.label}
            </Text>
            {props.count ? (
              <View
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: 'rgba(20, 20, 20, 0.3)',
                  borderRadius: 12,
                  marginLeft: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={[
                    BUTTON_LABEL_STYLE,
                    {
                      fontSize: 12,
                    },
                  ]}
                >
                  {truncateNumber(props.count, 99)}
                </Text>
              </View>
            ) : null}
          </HorizontalContainer>
        }
        containerStyle={[
          {
            shadowOpacity: 0,
          },
          props.style,
        ]}
        style={[
          {
            backgroundColor: props.selected
              ? props.buttonColors?.selectedBackgroundColor ?? KEY_GREEN
              : props.buttonColors?.unselectedBackgroundColor ?? KEY_LIGHT_GRAY,
          },
          styles('pastFeedbackTabButton'),
        ]}
        onPress={() => props.onPress()}
      />
    </>
  );
}
