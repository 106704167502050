/**
 * TODO: Write documentation for this hook
 */
import { fetchAuthSession } from 'aws-amplify/auth';
import { uploadData } from 'aws-amplify/storage';
import { v4 as uuid } from 'uuid';
// @ts-ignore
import mime from 'react-native-mime-types';

import awsconfig from '/aws-exports';

import { useAuthContext } from '/context';

type UploadFileOptions = {
  /** Location of file to upload */
  uri: string;
  /** If true, file will be stored in _allUsers directory instead of {sub} */
  persistAfterUserDeletion?: boolean;
  stripGPSMetadata?: boolean;
  /** Specify a name for the file. If left unspecified, it will be assigned a random ID */
  filename?: string;
  /** Specify a content disposition for the file. */
  contentDisposition?: 'attachment' | 'inline';
  /** Default is `public` */
  accessLevel?: 'public' | 'protected' | 'private';
  onUploadProgress: (loadedBytes: number, totalBytes: number) => void;
  onUploadComplete: (resultUri: string) => void;
  onUploadFailed: (error: any) => void;
};

type UploadMultipleOptions = Omit<UploadFileOptions, 'uri' | 'filename'> & {
  /** Locations of files to upload */
  uris: string[];
  /** Specify names for files. Same order as `uris`. If left unspecified, files will be
   * assigned a random ID
   */
  filenames?: string[];
  /** resultUris will be in the same order as 'uris' passed in options */
  onUploadComplete: (resultUris: string[]) => void;
};

export interface useFileUploadReturns {
  /** Upload to S3 user-assets bucket
   * @returns A cancel function
   */
  uploadFile: (options: UploadFileOptions) => (() => void) | undefined;
  /**
   * Upload multiple files to S3 user-assets bucket
   * @param options Upload options
   * @returns A cancel function to cancel in-progress uploads (some uploads may already be completed)
   */
  uploadMultiple: (options: UploadMultipleOptions) => () => void;
}

export default function useFileUpload(): useFileUploadReturns {
  const { userAttributes } = useAuthContext();

  function uploadFile(options: UploadFileOptions) {
    // Set default value for accessLevel
    const accessLevel = options.accessLevel ?? 'public';

    let fileUri: string = options.uri;

    // Takes URI, including base64 encoded URIs, and returns a BLOB.
    async function getFile(uri: string) {
      const response = await fetch(uri);
      return response.blob();
    }

    let isCancelled = false; // Used to cancel upload before upload begins
    let cancel: () => void = () => {
      isCancelled = true;
    };

    getFile(fileUri)
      .then((file) => {
        const fileExt = mime.extension(file.type);

        const directory = options.persistAfterUserDeletion
          ? '_allUsers'
          : userAttributes?.sub;

        // Store file in {sub}/{fileExt}/{uuid}.{fileExt}
        const Key = `${directory}/${fileExt}/${
          options.filename
            ? `${uuid()}.${options.filename}`
            : `${uuid()}${fileExt ? '.' + fileExt : ''}`
        }`;

        const accessLevelMap = {
          public: 'guest',
          protected: 'protected',
          private: 'private',
        } as const;

        if (isCancelled) return;

        const { cancel: _cancel, result } = uploadData({
          key: Key,
          data: file,
          options: {
            onProgress(event) {
              options.onUploadProgress(
                event.transferredBytes,
                event.totalBytes ?? 0,
              );
            },
            // @ts-ignore
            tagging:
              options.stripGPSMetadata === false
                ? undefined
                : 'strip-metadata=true',
            accessLevel: accessLevelMap[accessLevel],
            contentType: file.type,
            contentDisposition: options.contentDisposition,
          },
        });

        cancel = _cancel;

        return result;
      })
      .then(async (result: any) => {
        let key = result.key;

        if (accessLevel === 'private' || accessLevel === 'protected') {
          const identityId = (await fetchAuthSession()).identityId;

          key = `${identityId}/${key}`;
        }

        if (result.key) {
          options.onUploadComplete(
            encodeURI(
              `https://${awsconfig.aws_user_files_s3_bucket}.s3.amazonaws.com/${accessLevel}/${key}`,
            ),
          );
        } else {
          throw new Error('Upload error: result.key is invalid\n' + result);
        }
      })
      .catch((err) => {
        options.onUploadFailed(err);
      });

    /** Cancel upload */
    return () => {
      cancel();
    };
  }

  function uploadMultiple(options: UploadMultipleOptions) {
    const totalRequests = options.uris.length;
    let finishedRequests = 0;

    const progress: { total: number; loaded: number }[] = [];
    const results: (string | undefined)[] = [];
    const errors: any[] = [];

    // Prefill arrays
    progress.fill(
      {
        total: 1,
        loaded: 0,
      },
      0,
      totalRequests,
    );
    results.fill(undefined, 0, totalRequests);
    errors.fill(undefined, 0, totalRequests);

    const _uploadComplete = (result: string, index: number) => {
      results[index] = result;
      finishedRequests += 1;
      _invokeCallbacks();
    };

    const _uploadFailed = (error: any, index: number) => {
      errors[index] = error;
      finishedRequests += 1;
      _invokeCallbacks();
    };

    const _uploadProgress = (loaded: number, total: number, index: number) => {
      progress[index] = {
        loaded,
        total,
      };
      _invokeCallbacks();
    };

    const _invokeCallbacks = () => {
      // Only invoke 'complete' and 'failed' if all requests have been completed
      if (finishedRequests === totalRequests) {
        const hasErrors = errors.some((err) => err !== undefined);

        if (hasErrors) {
          options.onUploadFailed?.({
            message: 'One or more uploads have failed',
            errors,
            results,
          });
        } else {
          // If we succeeded, none of `results` should be undefined
          options.onUploadComplete?.(results as string[]);
        }
      }
      // Otherwise, we call onUploadProgress
      else {
        // Aggregate 'loaded' and 'total' values from 'progress' array
        const { loaded, total } = progress.reduce(
          (accum, curr) => ({
            loaded: accum.loaded + curr.loaded,
            total: accum.total + curr.total,
          }),
          {
            loaded: 0,
            total: 0,
          },
        );

        options.onUploadProgress?.(loaded, total);
      }
    };

    const cancelFunctions = options.uris.map((uri, index) => {
      const filename =
        options.filenames?.length ?? index < 0
          ? options.filenames?.[index]
          : undefined;

      return uploadFile({
        uri,
        accessLevel: options.accessLevel,
        filename: filename,
        onUploadComplete: (result) => _uploadComplete(result, index),
        onUploadFailed: (err) => _uploadFailed(err, index),
        onUploadProgress: (loaded, total) =>
          _uploadProgress(loaded, total, index),
      });
    });

    return function cancel() {
      // Invoke all cancel functions
      cancelFunctions.forEach((func) => func?.());
    };
  }

  return { uploadFile, uploadMultiple };
}
