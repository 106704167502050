import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { getStackNavigatorOptions } from './common';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import Testers from '/screens/admin/Testers';
import EditTesterObjective from '/screens/admin/Testers/EditTesterObjective';

const Stack = createStackNavigator();

const createScreen = (Component: React.LazyExoticComponent<any>) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const ManageTestersScreen = createScreen(
  React.lazy(() => import('/screens/admin/Testers/ManageTesters')),
);
const ListTesterGeneralFeedbackScreen = createScreen(
  React.lazy(() => import('/screens/admin/Testers/ListTesterGeneralFeedback')),
);
const ListTesterObjectives = createScreen(
  React.lazy(() => import('/screens/admin/Testers/ListTesterObjectives')),
);
const ListTesterObjectiveFeedback = createScreen(
  React.lazy(
    () => import('/screens/admin/Testers/ListTesterObjectiveFeedback'),
  ),
);
const ListTesterObjectiveScheduledSessions = createScreen(
  React.lazy(
    () => import('/screens/admin/Testers/ListTesterObjectiveScheduledSessions'),
  ),
);
const CreateTesterObjective = createScreen(
  React.lazy(() => import('/screens/admin/Testers/CreateTesterObjective')),
);

export default function TesterStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      initialRouteName="Testers"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={Testers}
        name="Testers"
        options={TestersScreenOptions}
      />
      <Stack.Screen
        component={ManageTestersScreen}
        name="ManageTesters"
        options={ManageTestersScreenOptions}
      />
      <Stack.Screen
        component={ListTesterGeneralFeedbackScreen}
        name="ListTesterGeneralFeedback"
        options={ListTesterGeneralFeedbackScreenOptions}
      />
      <Stack.Screen
        component={ListTesterObjectiveFeedback}
        name="ListTesterObjectiveFeedback"
        options={ListTesterObjectiveFeedbackScreenOptions}
      />
      <Stack.Screen
        component={ListTesterObjectives}
        name="ListTesterObjectives"
        options={ListTesterObjectivesScreenOptions}
      />
      <Stack.Screen
        component={CreateTesterObjective}
        name="CreateTesterObjective"
        options={CreateTesterObjectiveScreenOptions}
      />
      <Stack.Screen
        component={EditTesterObjective}
        name="EditTesterObjective"
        options={EditTesterObjectiveScreenOptions}
      />
      <Stack.Screen
        component={ListTesterObjectiveScheduledSessions}
        name="ListTesterObjectiveScheduledSessions"
        options={ListTesterObjectiveScheduledSessionsScreenOptions}
      />
    </Stack.Navigator>
  );
}

const TestersScreenOptions = (): StackNavigationOptions => ({
  title: 'Testers',
  headerTitle: 'TESTERS',
});

const ManageTestersScreenOptions = (): StackNavigationOptions => ({
  title: 'Manage Testers',
  headerTitle: 'MANAGE TESTERS',
});

const ListTesterGeneralFeedbackScreenOptions = (): StackNavigationOptions => ({
  title: 'General Feedback',
  headerTitle: 'GENERAL FEEDBACK',
});

const ListTesterObjectivesScreenOptions = (): StackNavigationOptions => ({
  title: 'Test Objectives',
  headerTitle: 'TEST OBJECTIVES',
});

const CreateTesterObjectiveScreenOptions = (): StackNavigationOptions => ({
  title: 'Create Objective',
  headerTitle: 'CREATE OBJECTIVE',
});

const EditTesterObjectiveScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Objective',
  headerTitle: 'EDIT OBJECTIVE',
});

const ListTesterObjectiveFeedbackScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Feedback',
    headerTitle: 'FEEDBACK',
  });

const ListTesterObjectiveScheduledSessionsScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Scheduled Sessions',
    headerTitle: 'SCHEDULED SESSIONS',
  });
