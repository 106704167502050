import { MaterialCommunityIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import {
  ImageBackground,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Button from './Button';
import {
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_YELLOW,
  TITLE_FONT_SIZE,
} from '/constants';

const KEY_DONATE_URL = `https://keyconservation.org/donate`;

const ASYNC_STORAGE_LAST_DISMISSED_KEY = 'SUPPORT_KEY_BANNER_LAST_DISMISSED';

export default function SupportKeyBanner() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    /** Only show this banner if user has not dismissed it in the past 7 days */

    AsyncStorage.getItem(ASYNC_STORAGE_LAST_DISMISSED_KEY).then(
      (lastDismissed) => {
        const maximumLastDismissed = Date.now() - 1000 * 3600 * 24 * 7; // Now - 7 days

        if (!lastDismissed || Number(lastDismissed) < maximumLastDismissed) {
          setVisible(true);
        }
      },
    );
  }, []);

  function onDonate() {
    // @ts-ignore - the second argument is untyped
    Linking.openURL(KEY_DONATE_URL, '__blank');
  }

  function onDismiss() {
    setVisible(false);

    AsyncStorage.setItem(
      ASYNC_STORAGE_LAST_DISMISSED_KEY,
      Date.now().toString(),
    );
  }

  return visible ? (
    <ImageBackground
      style={styles.container}
      source={require('/assets/images/bongo.jpg')}
    >
      <View style={styles.headerFooter}>
        <TouchableOpacity style={styles.dismissButton} onPress={onDismiss}>
          <MaterialCommunityIcons name="close-thick" size={24} color="white" />
          <Text style={styles.dismissButtonText}>DISMISS</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.content}>
        <Text style={styles.titleText}>HELP BUILD KEY</Text>
        <Text style={styles.paragraph}>
          Did you know the Key Conservation platform is 100% free for
          conservation organizations to use?{`\n\n`}
          Help us keep it free and build more features to support conservation
          efforts by donating or becoming a monthly supporter. Every cent helps!
        </Text>
        <Button
          containerStyle={styles.donateButtonContainer}
          style={styles.donateButton}
          labelStyle={styles.donateButtonLabel}
          label="SUPPORT KEY"
          onPress={onDonate}
        />
      </View>
      <View style={styles.headerFooter} />
    </ImageBackground>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 400,
  },
  headerFooter: {
    height: 64,
    padding: 10,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: 10,
    justifyContent: 'center',
  },
  titleText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
    marginBottom: 4,
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
  },
  donateButtonContainer: {
    alignSelf: 'center',
    marginTop: 8,
  },
  donateButton: {
    backgroundColor: KEY_YELLOW,
  },
  donateButtonLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 22,
    padding: 8,
  },
  dismissButton: {
    backgroundColor: 'transparent',
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dismissButtonText: {
    fontFamily: 'LeagueSpartan-Bold',
    color: 'white',
    fontSize: 21,
    marginLeft: 4,
  },
});
