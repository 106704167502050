import React from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';

type Props = {
  onEdit: () => void;
  style: StyleProp<ViewStyle>;
  uploading: boolean;
  disabled: boolean;
  renderComponent: (pressed: boolean, onEdit: () => void) => React.ReactNode;
};

export default function CustomUploadComponent(props: Props) {
  return (
    <Pressable
      onPress={props.onEdit}
      style={[
        props.style,
        {
          opacity: props.uploading ? 0.5 : 1,
          pointerEvents: props.disabled || props.uploading ? 'none' : 'auto',
        },
      ]}
    >
      {({ pressed }) =>
        props.renderComponent?.(pressed, () => {
          props.onEdit();
        })
      }
    </Pressable>
  );
}
