import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import React from 'react';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getStackNavigatorOptions } from './common';

const Stack = createStackNavigator();

const createScreen = (Component: React.LazyExoticComponent<any>) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const Organizations = createScreen(
  React.lazy(() => import('/screens/admin/Organizations')),
);
const ManageOrganizations = createScreen(
  React.lazy(() => import('/screens/admin/Organizations/ManageOrganizations')),
);
const ListOrganizationsInterestedInGodwit = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/admin/Organizations/ListOrganizationsInterestedInGodwit'
      ),
  ),
);

export default function OrganizationsStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      initialRouteName="Organizations"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={Organizations}
        name="Organizations"
        options={OrganizationsScreenOptions}
      />
      <Stack.Screen
        component={ManageOrganizations}
        name="ManageOrganizations"
        options={ManageOrganizationsScreenOptions}
      />
      <Stack.Screen
        component={ListOrganizationsInterestedInGodwit}
        name="ListOrganizationsInterestedInGodwit"
        options={ListOrganizationsInterestedInGodwitScreenOptions}
      />
    </Stack.Navigator>
  );
}

const OrganizationsScreenOptions = (): StackNavigationOptions => ({
  title: 'Organizations',
  headerTitle: 'ORGANIZATIONS',
});
const ManageOrganizationsScreenOptions = (): StackNavigationOptions => ({
  title: 'Manage Organizations',
  headerTitle: 'MANAGE ORGANIZATIONS',
});
const ListOrganizationsInterestedInGodwitScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Organizations Interested in Godwit',
    headerTitle: 'ORGANIZATIONS INTERESTED IN GODWIT',
  });
