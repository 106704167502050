import {
  AntDesign,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp, useLinkTo } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Easing,
  FlatList,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Dots from 'react-native-dots-pagination';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DEVICE_SIZES } from 'rn-responsive-styles';
import TesterDashboardReadyToJumpInSplash from '../TesterOnlyMode/TesterDashboardReadyToJumpInSplash';
import PastFeedback from './components/PastFeedback';
import TesterObjectiveCard from './components/TesterObjectiveCard';
import useStyles, { DRAWER_WIDTH } from './TesterDashboard.style';
import Alert from '/Alert';
import AccountSettingsDrawer from '/components/AccountSettingsDrawer/AccountSettingsDrawer';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import WriteComment from '/components/Comments/WriteComment';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionText from '/components/common/Section/SectionText';
import GridList from '/components/GridList';
import ListLoading from '/components/ListLoading';
import withAuthRequired from '/components/withAuthRequired';
import {
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useAuthContext } from '/context';
import env from '/env';
import {
  TesterStatus,
  useCreateTesterGeneralFeedbackMutation,
  useListMyTesterObjectivesQuery,
  UserRole,
  useSignupAsTesterMutation,
  useUpdateUserProfileMutation,
  useWithdrawAsTesterMutation,
} from '/generated/graphql';
import { isValidEmail, isValidJson, shortenEmail } from '/util';

// Standard drawer width
const ANIMATION_DURATION = 250; // Animation duration in ms

type Props = {
  route: RouteProp<any>;
  navigation: StackNavigationProp<any>;
  /** If set to `section`, the back button and burger menu willbe hidden.
   * Padding will be reduced and scrolling will be disabled. Background color will be white. */
  mode?: 'default' | 'section';
};

function TesterDashboard(props: Props) {
  const { styles, deviceSize } = useStyles();
  const safeAreaInsets = useSafeAreaInsets();
  const linkTo = useLinkTo();
  const { userData } = useAuthContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const slideAnim = React.useRef(new Animated.Value(1)).current;
  const scrollViewRef = React.useRef<any>();
  const pastFeedbackRef = React.useRef<View>(null);

  const isExtraSmallDevice = deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE;

  const [hasSeenReadyToJumpInSplash, setHasSeenReadyToJumpInSplash] =
    useState(true);

  useEffect(() => {
    if (!env.TESTER_ONLY_MODE) return;

    const checkIfHasSeenReadyToJumpInSplash = async () => {
      const userIds = await AsyncStorage.getItem(
        'has-seen-ready-to-jump-in-splash',
      );
      if (!userIds) {
        setHasSeenReadyToJumpInSplash(false);
        return;
      }
      const userIdsArray = isValidJson(userIds) ? JSON.parse(userIds) : [];
      setHasSeenReadyToJumpInSplash(userIdsArray.includes(userData?.id));
    };
    checkIfHasSeenReadyToJumpInSplash();
  }, [userData?.id]);

  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || !!userData?.admin;

  function onReturnToPlatform() {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      linkTo('/impact');
    }
  }

  function closeReadyToJumpInSplash() {
    setHasSeenReadyToJumpInSplash(true);
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      easing: Easing.inOut(Easing.poly(4)),
      useNativeDriver: true,
    }).start();
  };

  const closeDrawer = () => {
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: ANIMATION_DURATION,
      easing: Easing.inOut(Easing.poly(4)),
      useNativeDriver: true,
    }).start(() => {
      setIsDrawerOpen(false);
    });
  };

  function onFocusPastFeedback() {
    if (pastFeedbackRef.current && scrollViewRef.current) {
      // Add a small delay to ensure layout is complete
      setTimeout(() => {
        pastFeedbackRef.current?.measureLayout(
          // @ts-ignore - getInnerViewNode exists but is not in types
          scrollViewRef.current?.getInnerViewNode(),
          (x, y) => {
            // Add some offset to account for header content
            const scrollOffset = Math.max(0, y);
            scrollViewRef.current?.scrollTo({
              y: scrollOffset,
              animated: true,
            });
          },
          () => {
            // Error callback - fallback to scrollToEnd if measurement fails
            console.warn(
              'Failed to measure layout, falling back to scrollToEnd',
            );
            scrollViewRef.current?.scrollToEnd({ animated: true });
          },
        );
      }, 100);

      props.navigation.setParams({
        focusObjectiveId: undefined,
      });
    }
  }

  const { Component: ContainerComponent, props: containerProps } =
    useMemo(() => {
      if (props.mode === 'section') {
        return {
          Component: View,
          props: {} as ComponentProps<typeof View>,
        };
      }
      return {
        Component: KeyboardAwareScrollView,
        props: {
          contentInset: safeAreaInsets,
          innerRef: (ref) => {
            if (ref) scrollViewRef.current = ref;
          },
          contentContainerStyle: styles('contentContainer'),
        } as ComponentProps<typeof KeyboardAwareScrollView>,
      };
    }, [props.mode, safeAreaInsets, styles]);

  return (
    <>
      {hasSeenReadyToJumpInSplash ? null : (
        <TesterDashboardReadyToJumpInSplash
          onDismiss={closeReadyToJumpInSplash}
        />
      )}
      <View
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: props.mode === 'section' ? 'white' : KEY_LIGHT_GRAY,
        }}
      >
        <ContainerComponent
          {...containerProps}
          style={[
            {
              flex: 1,
              height: '100%',
              overflow:
                Platform.OS === 'web' && props.mode !== 'section'
                  ? 'scroll'
                  : 'hidden',
            },
            props.mode === 'section'
              ? {
                  padding: 16,
                }
              : undefined,
          ]}
        >
          {props.mode !== 'section' ? (
            <HorizontalContainer
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: 12,
              }}
            >
              {isApprovedTester ? (
                <Pressable
                  onPress={onReturnToPlatform}
                  style={{
                    flexShrink: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <AntDesign name="arrowleft" size={24} color={KEY_GRAY} />
                  <Text style={styles('backToKeyPlatformText')}>
                    Back to My Impact tab
                  </Text>
                </Pressable>
              ) : null}

              {isExtraSmallDevice ? (
                <Pressable
                  onPress={openDrawer}
                  style={{
                    paddingLeft: 8,
                  }}
                >
                  <Feather size={40} name="menu" />
                </Pressable>
              ) : null}
            </HorizontalContainer>
          ) : null}
          <HorizontalContainer
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={[
                styles('title'),
                {
                  flexShrink: 1,
                },
                props.mode === 'section'
                  ? {
                      fontSize: 24,
                    }
                  : undefined,
              ]}
            >
              Your Key {env.TESTER_ONLY_MODE ? 'Migration' : 'Insider'}{' '}
              Dashboard
            </Text>

            {props.mode === 'section' && !isExtraSmallDevice ? (
              <FontAwesome5
                name="expand-alt"
                size={24}
                style={{
                  padding: 6,
                }}
                onPress={() => {
                  props.navigation.navigate('TesterDashboard');
                }}
                color="black"
              />
            ) : isExtraSmallDevice ? null : (
              <Pressable
                onPress={openDrawer}
                style={{
                  padding: 6,
                }}
              >
                <Feather size={40} name="menu" />
              </Pressable>
            )}
          </HorizontalContainer>

          <HorizontalContainer
            style={{
              marginVertical: 12,
            }}
          >
            <Avatar
              source={{ uri: userData?.profile_image }}
              rounded
              size={56}
              containerStyle={{ marginRight: 12 }}
            />
            <Text style={styles('welcomeText')}>
              Welcome, {userData?.name ?? 'insider'}!
            </Text>
          </HorizontalContainer>

          <SectionText
            style={[
              styles('textColor'),
              {
                marginTop: 8,
              },
              props.mode !== 'section'
                ? {
                    fontSize: 22,
                  }
                : undefined,
            ]}
          >
            Stay involved during our migration into Phase 2!{`\n\n`}
            <Text
              style={{
                fontFamily: 'Lato-Italic',
              }}
            >
              Choose how you'd like to participate:
            </Text>
          </SectionText>

          <GodwitSignup />

          <TesterSignup mode={props.mode ?? 'default'} />

          <TesterObjectives mode={props.mode ?? 'default'} />

          <GeneralFeedbackWidget />

          <RelaunchNotificationSignup />

          {userData?.tester_status === TesterStatus.Approved ||
          userData?.admin ? (
            <PastFeedback
              ref={pastFeedbackRef}
              mode={props.mode ?? 'default'}
              onFocusSection={onFocusPastFeedback}
              initiallyFocusedObjectiveId={props.route.params?.focusObjectiveId}
            />
          ) : null}
        </ContainerComponent>
      </View>

      {/* Drawer Overlay */}
      {isDrawerOpen && (
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.5)',
              opacity: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0],
              }),
            },
          ]}
        >
          <Pressable style={StyleSheet.absoluteFill} onPress={closeDrawer} />
        </Animated.View>
      )}

      {/* Animated Drawer */}
      {props.mode !== 'section' ? (
        <Animated.View
          style={[
            styles('drawer'),
            {
              transform: [
                {
                  translateX: slideAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, DRAWER_WIDTH],
                  }),
                },
              ],
            },
          ]}
        >
          {isDrawerOpen && (
            <AccountSettingsDrawer
              isTesterDashboard
              onCloseDrawer={closeDrawer}
            />
          )}
        </Animated.View>
      ) : null}
    </>
  );
}

function GodwitSignup() {
  const { userData } = useAuthContext();
  const { styles } = useStyles();

  const [signingUpForGodwit, setSigningUpForGodwit] = useState(false);
  const [, updateUser] = useUpdateUserProfileMutation();

  function onSignUpForGodwit() {
    setSigningUpForGodwit(true);
    updateUser({
      input: {
        interested_in_godwit: true,
      },
    }).finally(() => {
      setSigningUpForGodwit(false);
    });
  }

  function onWithdrawInterestInGodwit() {
    Alert.alert(
      'Withdraw',
      'Are you sure you no longer want to hear about Godwit and potentially get early access?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Withdraw',
          style: 'destructive',
          onPress: () => {
            setSigningUpForGodwit(true);
            updateUser({
              input: {
                interested_in_godwit: false,
              },
            }).finally(() => {
              setSigningUpForGodwit(false);
            });
          },
        },
      ],
    );
  }

  return userData?.role === UserRole.Conservationist ? (
    <HorizontalContainer style={styles('signupHorizontalContainer')}>
      <View style={{ flex: 1 }}>
        <Text style={styles('smallHeading')}>
          The Next Era of Conservation Funding
        </Text>

        <SectionText
          style={[
            styles('textColor'),
            {
              marginTop: 12,
              maxWidth: 1024,
            },
          ]}
        >
          We're launching Godwit, a groundbreaking platform connecting
          conservation organizations to larger funding opportunities to sustain
          your work, strengthen your team, and drive your mission long-term.
          Sign up now to express your interest and be part of the first cohort
          with exclusive access. While interest doesn't guarantee a spot, it
          ensures you'll be the first to know as we roll out this transformative
          opportunity.
        </SectionText>

        <HorizontalContainer
          style={{
            marginRight: 24,
            alignSelf: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
          }}
        >
          <Image
            source={require('/assets/images/nsf-logo.png')}
            resizeMode="contain"
            style={{
              marginHorizontal: 8,
              width: 60,
              height: 60,
              marginTop: 16,
              marginBottom: 24,
            }}
          />
          <Image
            source={require('/assets/images/keyFullBlack.png')}
            resizeMode="contain"
            style={{
              marginHorizontal: 8,
              marginTop: 16,
              width: 50,
              height: 50,
              marginBottom: 29,
            }}
          />
          <Image
            source={require('/assets/images/godwit-logo.png')}
            resizeMode="contain"
            style={{
              marginHorizontal: 0,
              width: 86,
              height: 86,
            }}
          />
        </HorizontalContainer>
      </View>

      <View style={styles('signupButtonContainer')}>
        <Button
          loading={signingUpForGodwit}
          disabled={signingUpForGodwit}
          onPress={() => {
            if (!userData?.interested_in_godwit) {
              onSignUpForGodwit();
            } else {
              onWithdrawInterestInGodwit();
            }
          }}
          label={userData?.interested_in_godwit ? 'Signed up' : 'Sign Up'}
          style={{
            borderWidth: 2,
            minWidth: 240,
            borderColor: 'black',
            backgroundColor: userData?.interested_in_godwit
              ? KEY_GREEN
              : KEY_LIGHT_GRAY,
          }}
          labelStyle={{
            paddingHorizontal: 20,
            paddingVertical: 4,
          }}
        />
        <SectionText
          style={[
            styles('textColor'),
            {
              fontSize: 11,
              marginTop: 4,
            },
          ]}
        >
          {!userData?.interested_in_godwit
            ? `Click to sign up`
            : `Click to withdraw`}
        </SectionText>
      </View>
    </HorizontalContainer>
  ) : null;
}

function TesterSignup(props: { mode: 'section' | 'default' }) {
  const { styles } = useStyles();
  const { userData } = useAuthContext();
  const [{ fetching: signingUp }, signUpAsTester] = useSignupAsTesterMutation();
  const [{ fetching: withdrawing }, withdrawAsTester] =
    useWithdrawAsTesterMutation();

  return (
    <HorizontalContainer
      style={[
        styles('signupHorizontalContainer'),
        {
          flexDirection: props.mode === 'section' ? 'column' : undefined,
        },
      ]}
    >
      <View style={{ flex: 1 }}>
        <Text style={styles('smallHeading')}>
          {userData?.tester_status === TesterStatus.Approved
            ? 'Thank you for being a tester!'
            : `Become a tester`}
        </Text>

        <SectionText
          style={[
            styles('textColor'),
            {
              marginTop: 12,
              maxWidth: 1024,
            },
          ]}
        >
          {userData?.tester_status === TesterStatus.Approved
            ? 'Your feedback is invaluable in helping us create a better, more intuitive experience for ' +
              'everyone in our community. Thank you for being part of this journey with us! You can opt ' +
              'out at any time to no longer receive testing opportunities notifications.'
            : 'Join us in helping us create a better, more intuitive experience for everyone in our ' +
              'community by testing new and upgraded features. You will receive weekly notifications of ' +
              'testing opportunities You can opt out at any time.'}{' '}
          You can opt-out at any time by clicking the button, and you'll no
          longer receive notifications about testing opportunities.
        </SectionText>
      </View>

      <View
        style={[
          styles('signupButtonContainer'),
          props.mode === 'section'
            ? { marginLeft: 0, marginTop: 16 }
            : undefined,
        ]}
      >
        <Button
          loading={signingUp || withdrawing}
          disabled={
            signingUp ||
            withdrawing ||
            userData?.tester_status === TesterStatus.Revoked ||
            !!userData?.admin
          }
          onPress={() => {
            if (userData?.tester_status === TesterStatus.Revoked) return;

            if (userData?.tester_status === TesterStatus.None) {
              signUpAsTester({}).then(({ error, data }) => {
                if (error) {
                  console.error(error);
                  Alert.alert(
                    'Error',
                    'There was a problem signing up. Please try again later.',
                  );
                  return;
                }

                if (
                  data?.signupAsTester.tester_status === TesterStatus.Approved
                ) {
                  Alert.notify({
                    message: 'You have been added as a tester!',
                    color: KEY_GREEN,
                  });
                }

                if (
                  data?.signupAsTester.tester_status === TesterStatus.Interested
                ) {
                  Alert.notify({
                    message: "Signed up. You will be notified once you're in!",
                    color: KEY_GREEN,
                  });
                }
              });
            } else {
              Alert.alert(
                'Withdraw',
                'Are you sure you want to withdraw as a tester?',
                [
                  {
                    text: 'Cancel',
                    style: 'cancel',
                  },
                  {
                    text: 'Withdraw',
                    style: 'destructive',
                    onPress: () => withdrawAsTester({}),
                  },
                ],
              );
            }
          }}
          label={
            userData?.tester_status === TesterStatus.None
              ? 'Sign Up'
              : userData?.tester_status === TesterStatus.Interested
              ? 'Pending Approval'
              : userData?.tester_status === TesterStatus.Revoked
              ? 'Revoked'
              : 'Opt Out'
          }
          style={{
            borderWidth: 2,
            minWidth: 240,
            borderColor: 'black',
            backgroundColor:
              userData?.tester_status === TesterStatus.Approved
                ? KEY_GREEN
                : KEY_LIGHT_GRAY,
            pointerEvents:
              userData?.tester_status === TesterStatus.Revoked
                ? 'none'
                : 'auto',
          }}
          labelStyle={{
            paddingHorizontal: 20,
            paddingVertical: 4,
          }}
        />
        <SectionText
          style={[
            styles('textColor'),
            {
              fontSize: 11,
              marginTop: 4,
            },
          ]}
        >
          {userData?.admin
            ? 'Admins always have access'
            : userData?.tester_status === TesterStatus.None
            ? ''
            : userData?.tester_status === TesterStatus.Revoked
            ? 'Ineligible to test'
            : `Click to opt out`}
        </SectionText>
      </View>
    </HorizontalContainer>
  );
}

function TesterObjectives(props: { mode: 'section' | 'default' }) {
  const { userData } = useAuthContext();
  const { styles, deviceSize } = useStyles();

  const isTester =
    !!userData?.admin || userData?.tester_status === TesterStatus.Approved;
  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [flatListWidth, setFlatListWidth] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
    minimumViewTime: 0,
  });

  const onViewableItemsChanged = useRef(
    ({
      viewableItems,
    }: {
      viewableItems: Array<{
        key?: string;
        isViewable: boolean;
        item: any;
        index: number | null;
        section?: any;
      }>;
    }) => {
      if (!viewableItems.length) return;

      // Use the scroll ratio to bias which viewable item should be focused
      const biasedIndex = Math.min(
        Math.floor(scrollProgress * viewableItems.length),
        viewableItems.length - 1,
      );

      const focusedItem = viewableItems[biasedIndex];
      if (focusedItem && focusedItem.index !== null) {
        setActiveObjectiveIndex(focusedItem.index);
      }
    },
  );

  const [nextToken, setNextToken] = useState<string | null>(null);

  const [{ data, fetching }, refetch] = useListMyTesterObjectivesQuery({
    pause: !isTester,
    variables: {
      nextToken,
      limit: 8,
    },
    requestPolicy: 'cache-and-network',
  });

  const renderData = useMemo(() => {
    if (!data?.listMyTesterObjectives.items) return [];

    const list: Array<
      | NonNullable<(typeof data.listMyTesterObjectives.items)[number]>
      | 'load-more'
    > = [...data.listMyTesterObjectives.items];

    if (data.listMyTesterObjectives.nextToken) {
      list.push('load-more' as const);
    }

    return list;
  }, [data]);

  return !isTester ? (
    <Text
      style={{
        fontFamily: 'Lato-Italic',
        color: '#e41152',
        fontSize: 17,
        marginTop: 16,
      }}
    >
      Not signed up. Click the button above to sign up and get access to testing
      opportunities.
    </Text>
  ) : !data ? (
    fetching ? (
      <ListLoading />
    ) : (
      <GenericError
        message="There was a problem fetching test objectives"
        onRetry={refetch}
      />
    )
  ) : (
    <View>
      <HorizontalContainer
        style={{
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Text
          style={[
            styles('smallHeading'),
            {
              marginRight: 32,
            },
          ]}
        >
          Current Testing Objectives: {data.listMyTesterObjectives.total}
        </Text>
        <Text
          style={[
            styles('smallHeading'),
            {
              marginRight: 32,
            },
          ]}
        >
          Completed: {data.listMyTesterObjectives.totalComplete}/
          {data.listMyTesterObjectives.total}
        </Text>
      </HorizontalContainer>
      {props.mode === 'section' ||
      deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? (
        <>
          <FlatList
            horizontal
            onLayout={({ nativeEvent }) => {
              setFlatListWidth(nativeEvent.layout.width);
            }}
            onScroll={({ nativeEvent }) => {
              setScrollProgress(
                nativeEvent.contentOffset.x / nativeEvent.contentSize.width,
              );
            }}
            viewabilityConfig={viewabilityConfig.current}
            onViewableItemsChanged={onViewableItemsChanged.current}
            data={renderData}
            contentContainerStyle={{
              paddingVertical: 8,
            }}
            renderItem={({ item }) => {
              if (item === 'load-more') {
                return (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      label="Load More"
                      loading={fetching}
                      containerStyle={{
                        shadowOpacity: 0,
                        borderWidth: 2,
                        margin: 12,
                        marginHorizontal: 24,
                      }}
                      style={{
                        backgroundColor: KEY_LIGHT_GRAY,
                      }}
                      onPress={() => {
                        if (data.listMyTesterObjectives.nextToken) {
                          setNextToken(data.listMyTesterObjectives.nextToken);
                        }
                      }}
                    />
                  </View>
                );
              }

              return (
                <View>
                  <TesterObjectiveCard
                    objective={item}
                    style={{
                      marginRight: 10,
                      minWidth: 280,
                      maxWidth: 540,
                      width: flatListWidth - 24,
                    }}
                    loading={fetching}
                    refreshObjectives={() => {
                      refetch({
                        requestPolicy: 'network-only',
                      });
                    }}
                  />
                </View>
              );
            }}
          />
          <Dots
            alignDotsOnXAxis
            activeBorder={false}
            activeDotHeight={12}
            passiveDotWidth={12}
            passiveDotHeight={12}
            activeDotWidth={12}
            active={activeObjectiveIndex}
            length={renderData.length}
            activeColor={KEY_DARK_GREEN}
            passiveColor={'gray'}
          />
        </>
      ) : (
        <GridList
          style={{
            marginTop: 32,
          }}
          maxTileWidth={540}
          data={renderData}
          renderItem={({ item }) => {
            if (item === 'load-more') {
              return (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    label="Load More"
                    loading={fetching}
                    containerStyle={{
                      shadowOpacity: 0,
                      borderWidth: 2,
                      margin: 12,
                    }}
                    style={{
                      backgroundColor: KEY_LIGHT_GRAY,
                    }}
                    onPress={() => {
                      if (data.listMyTesterObjectives.nextToken) {
                        setNextToken(data.listMyTesterObjectives.nextToken);
                      }
                    }}
                  />
                </View>
              );
            }

            return (
              <TesterObjectiveCard
                objective={item}
                loading={fetching}
                refreshObjectives={() => {
                  refetch({
                    requestPolicy: 'network-only',
                  });
                }}
              />
            );
          }}
        />
      )}
    </View>
  );
}

function GeneralFeedbackWidget() {
  const { styles } = useStyles();

  const [media, setMedia] = useState<string[]>([]);
  const [body, _setBody] = useState('');
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const setBody = (newBody: string) => {
    _setBody(newBody);
  };

  const { userData } = useAuthContext();

  const [{ fetching: submitting }, createTesterGeneralFeedback] =
    useCreateTesterGeneralFeedbackMutation();

  async function onSubmit() {
    if (isUploadingMedia) {
      Alert.alert(
        'Media Uploading',
        'Please wait for media to finish uploading',
      );
      return false;
    }

    const { error } = await createTesterGeneralFeedback({
      input: {
        body,
        media,
      },
    });

    if (error) {
      Alert.alert('Error', 'There was a problem submitting your feedback');
      return false;
    }

    Alert.notify({
      message: 'Feedback submitted!',
      color: KEY_GREEN,
    });

    setBody('');
    setMedia([]);

    return true;
  }

  return (
    <View>
      <Text style={styles('smallHeading')}>Share Your Ideas:</Text>

      <SectionText style={styles('textColor')}>
        Have some suggestions or feedback on ways we can make the platform even
        better? We want to hear it. Send us a direct message here:
      </SectionText>

      <WriteComment
        enableMediaUpload
        media={media}
        onUploadStart={() => setIsUploadingMedia(true)}
        onUploadEnd={() => setIsUploadingMedia(false)}
        onChangeMedia={setMedia}
        value={body}
        style={{ marginTop: 8 }}
        textInputContainerStyle={{
          borderWidth: 2,
          borderRadius: 7,
          paddingVertical: 2,
        }}
        maxDynamicHeight={500}
        hideSubmitButton
        placeholderText="Write a message..."
        maxLength={5000}
        avatarUri={userData?.profile_image ?? undefined}
        buttonBarPlacement="right"
        onChangeText={setBody}
        mediaUploadIcon={
          <MaterialCommunityIcons name="paperclip" size={22} color="black" />
        }
        enableMentions={false}
        onSubmitComment={onSubmit}
      />

      <Button
        label="Submit"
        disabled={!body.trim() || isUploadingMedia}
        loading={submitting}
        onPress={onSubmit}
        containerStyle={styles('greenButtonContainer')}
        style={styles('greenButton')}
      />
    </View>
  );
}

function RelaunchNotificationSignup() {
  const { styles } = useStyles();
  const { userData } = useAuthContext();

  const [notifyRelaunchEmail, setNotifyRelaunchEmail] = useState('');
  const [
    signingUpForRelaunchNotification,
    setSigningUpForRelaunchNotification,
  ] = useState(false);
  const [, updateUser] = useUpdateUserProfileMutation();

  function onSignUpForRelaunchNotification() {
    if (!isValidEmail(notifyRelaunchEmail)) {
      Alert.alert(
        'Invalid email',
        'Please ensure you have entered a valid email address',
      );
      return;
    }

    setSigningUpForRelaunchNotification(true);
    updateUser({
      input: {
        notify_relaunch_email: notifyRelaunchEmail,
      },
    })
      .then(({ error }) => {
        if (error) {
          Alert.alert(
            'Error',
            'There was a problem signing up for relaunch notifications. Please try again or report this issue if it persists.',
          );
          return;
        }

        Alert.alert(
          'Signed Up!',
          `You will be notified at ${shortenEmail(
            notifyRelaunchEmail,
            42,
          )} when we relaunch in the spring.`,
        );
      })
      .finally(() => {
        setSigningUpForRelaunchNotification(false);
      });
  }

  function onUnsubscribeRelaunchNotification() {
    Alert.alert(
      'Unsubscribe',
      'Are you sure you no longer want to be notified when we relaunch in the spring?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Unsubscribe',
          style: 'destructive',
          onPress: () => {
            setSigningUpForRelaunchNotification(true);
            updateUser({
              input: {
                notify_relaunch_email: null,
              },
            }).finally(() => {
              setSigningUpForRelaunchNotification(false);
            });
          },
        },
      ],
    );
  }

  return userData && !userData?.notify_relaunch_email ? (
    <View style={{ width: '100%', minWidth: 0 }}>
      <Text style={styles('smallHeading')}>Stay in the Loop:</Text>

      <SectionText style={styles('textColor')}>
        Sign up to be notified when we relaunch in the spring.
      </SectionText>

      <View
        style={{
          marginTop: 12,
          width: '100%',
          minWidth: 0,
        }}
      >
        {userData?.notify_relaunch_email ? (
          <HorizontalContainer
            style={{
              alignItems: 'flex-start',
              width: '100%',
              minWidth: 0,
            }}
          >
            <Ionicons
              name="checkmark-circle"
              size={18}
              style={{
                marginTop: 3,
                marginRight: 3,
                flexShrink: 0,
              }}
              color={KEY_DARK_GREEN}
            />
            <View
              style={{
                flex: 1,
                minWidth: 0,
              }}
            >
              <SectionText
                style={{
                  fontFamily: 'Lato-Bold',
                  color: KEY_GRAY,
                }}
              >
                You signed up to be notified at{' '}
                {userData?.notify_relaunch_email}.{' '}
                <Button
                  label="Unsubscribe"
                  loading={signingUpForRelaunchNotification}
                  onPress={onUnsubscribeRelaunchNotification}
                  style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                  }}
                  containerStyle={{
                    backgroundColor: 'transparent',
                    shadowOpacity: 0,
                  }}
                  labelStyle={{
                    color: KEY_DARK_GREEN,
                  }}
                />
              </SectionText>
            </View>
          </HorizontalContainer>
        ) : (
          <>
            <TextInput
              style={styles('textInput')}
              editable={!signingUpForRelaunchNotification}
              placeholder="Enter your email"
              onChangeText={setNotifyRelaunchEmail}
              onSubmitEditing={onSignUpForRelaunchNotification}
              defaultValue={userData?.notify_relaunch_email ?? ''}
            />

            <Button
              label="Sign Up"
              disabled={!notifyRelaunchEmail.trim()}
              loading={signingUpForRelaunchNotification}
              onPress={onSignUpForRelaunchNotification}
              containerStyle={styles('greenButtonContainer')}
              style={styles('greenButton')}
            />
          </>
        )}
      </View>
    </View>
  ) : null;
}

export default withAuthRequired(TesterDashboard);
