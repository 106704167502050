import { createStackNavigator } from '@react-navigation/stack';
import {
  ChangeEmailScreenOptions,
  ChangePasswordScreenOptions,
  DeleteAccountScreenOptions,
  getStackNavigatorOptions,
  NotificationPreferencesScreenOptions,
} from './nested/common';
import DeleteAccount from '/screens/DeleteAccount/DeleteAccount';
import NotificationPreferences from '/screens/NotificationPreferences/NotificationPreferences';
import TesterDashboard from '../../screens/TesterDashboard/TesterDashboard';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ChangeEmail from '/screens/ChangeEmail/ChangeEmail';
import ChangePassword from '/screens/ChangePassword/ChangePassword';

const Stack = createStackNavigator();

export default function TesterSignupStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
      initialRouteName="TesterDashboard"
    >
      <Stack.Screen
        name="TesterDashboard"
        component={TesterDashboard}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={NotificationPreferences}
        name="NotificationPreferences"
        options={NotificationPreferencesScreenOptions}
      />
      <Stack.Screen
        component={DeleteAccount}
        name="DeleteAccount"
        options={DeleteAccountScreenOptions}
      />
      <Stack.Screen
        component={ChangePassword}
        name="ChangePassword"
        options={ChangePasswordScreenOptions}
      />
      <Stack.Screen
        component={ChangeEmail}
        name="ChangeEmail"
        options={ChangeEmailScreenOptions}
      />
    </Stack.Navigator>
  );
}
