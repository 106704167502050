import React, { useState } from 'react';
import { View } from 'react-native';
import Button from '/components/Button';
import SkillSelect from '/components/SkillSelect';
import { KEY_GREEN } from '/constants';
import ScrollView from '/components/common/ScrollView/ScrollView';

interface Props {
  initialSkillName: string;
  onRequestClose: (skillName: string) => void;
}

export default function JobBoardSkillFilterModal(
  props: React.PropsWithChildren<Props>,
) {
  const [state, setState] = useState<string>(props.initialSkillName ?? '');

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        paddingBottom: 2,
      }}
    >
      <ScrollView
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <SkillSelect
          onSkillsChanged={(skills) => {
            setState(skills[0]);
          }}
          onlyOne
          skills={state ? [state] : []}
          pageSize={20}
        />
      </ScrollView>
      <View
        style={{
          backgroundColor: 'white',
        }}
      >
        <Button
          label="Apply"
          containerStyle={{
            marginTop: 8,
            alignSelf: 'flex-end',
          }}
          style={{
            backgroundColor: KEY_GREEN,
          }}
          onPress={() => {
            props.onRequestClose(state);
          }}
        />
      </View>
    </View>
  );
}
