import { forwardRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import ActionSheetPressableCore, {
  ActionSheetPressableRef,
} from './ActionSheetPressableCore';
import Alert from '/Alert';
import { KEY_GREEN } from '/constants';
import {
  Message,
  User,
  useDeleteDiscussionBoardMessageMutation,
} from '/generated/graphql';
import { useAuthContext } from '/context';

// Usage:

// To show
/*
...
  actionSheetRef.current.show();
...
*/
interface IMessage extends Pick<Message, 'id' | 'body'> {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}

type DiscussionBoardMessageActionSheetProps = {
  message: IMessage;
  style?: StyleProp<ViewStyle> | ((props: { pressed: boolean }) => ViewStyle);
  disabled?: boolean;
  isAuthorizedToManageDiscussionBoard: boolean;
  onDelete?: () => void;
  onDeleteFailed?: () => void;
  onDeleteSuccess?: () => void;
};

export default forwardRef<
  ActionSheetPressableRef,
  React.PropsWithChildren<DiscussionBoardMessageActionSheetProps>
>(
  (
    props: React.PropsWithChildren<DiscussionBoardMessageActionSheetProps>,
    ref,
  ) => {
    const { userData } = useAuthContext();

    const [, _deleteMessage] = useDeleteDiscussionBoardMessageMutation();

    const deleteMessage = async () => {
      if (!props.message?.id) {
        console.warn(
          'DirectMessageActionSheet: `message` prop invalid - action canceled',
        );
        return;
      }
      try {
        props.onDelete?.();

        const { error } = await _deleteMessage({
          messageId: props.message.id,
        });

        if (error) throw error;

        props.onDeleteSuccess?.();

        Alert.notify({
          message: 'Deleted for everyone',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.log('failed to delete message', error);
        props.onDeleteFailed?.();
      } finally {
      }
    };

    const ACTIONSHEET_OPTIONS = (() => {
      const options: string[] = [];

      const labels = {
        delete: 'Delete',
        cancel: 'Cancel',
      };

      if (
        userData?.id === props.message.user.id ||
        props.isAuthorizedToManageDiscussionBoard
      ) {
        options.push(labels.delete);
      }
      options.push(labels.cancel);

      const destructiveButtonIndex = 0;
      const cancelButtonIndex = options.length - 1;

      return {
        title: 'Actions',
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        onPress: (index: number | undefined) => {
          if (index === undefined) return;

          const buttonLabel = options[index];

          switch (buttonLabel) {
            case labels.delete: {
              deleteMessage();
              break;
            }
            default:
              return undefined;
          }
        },
      };
    })();

    return ACTIONSHEET_OPTIONS.options.length > 1 ? (
      <ActionSheetPressableCore
        disabled={props.disabled}
        options={ACTIONSHEET_OPTIONS}
        style={props.style}
        ref={ref}
      >
        {props.children}
      </ActionSheetPressableCore>
    ) : null;
  },
);
