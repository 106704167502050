import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { InView } from 'react-native-intersection-observer';

export interface ViewportAwareViewProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  threshold?: number;
  onViewportEnter?: () => void;
  onViewportLeave?: () => void;
  testID?: string;
}

export default function ViewportAwareView({
  children,
  style,
  threshold = 0,
  onViewportEnter,
  onViewportLeave,
  testID,
}: ViewportAwareViewProps) {
  return (
    <InView
      style={style}
      threshold={threshold}
      onChange={(inView) => {
        if (inView) {
          console.log('inView', inView);
          onViewportEnter?.();
        } else {
          console.log('outView', inView);
          onViewportLeave?.();
        }
      }}
      testID={testID}
    >
      {children}
    </InView>
  );
}
