import { Video, VideoProps } from 'expo-av';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import ViewportAwareView from './ViewportAwareView';

interface Props extends VideoProps {
  innerRef?: (ref: Video) => void;
  containerStyle?: StyleProp<ViewStyle>;
  threshold?: number;
  onViewportEnter?: () => void;
  onViewportLeave?: () => void;
}

export default function ViewportAwareVideo({
  onViewportEnter,
  onViewportLeave,
  threshold,
  containerStyle,
  innerRef,
  ...videoProps
}: Props) {
  return (
    <ViewportAwareView
      style={containerStyle}
      threshold={threshold}
      onViewportEnter={onViewportEnter}
      onViewportLeave={onViewportLeave}
    >
      <Video {...videoProps} ref={innerRef} />
    </ViewportAwareView>
  );
}
