import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';

import DiscoverScreen from '/screens/maps/DiscoverScreen';

import { createCommonCluster, getStackNavigatorOptions } from './common';
import SearchScreen from '/screens/SearchScreen';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAppInfo } from '/context/AppInfoProvider';

const Stack = createStackNavigator();

export default function MapStack() {
  const safeAreaInsets = useSafeAreaInsets();
  const { isFeatureFlagEnabled } = useAppInfo();
  return (
    <Stack.Navigator
      initialRouteName="MapHome"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={DiscoverScreen}
        name="MapHome"
        options={WideMapScreenOptions}
      />
      <Stack.Screen
        component={SearchScreen}
        name="Search"
        options={SearchScreenOptions}
      />
      {createCommonCluster(Stack, isFeatureFlagEnabled)}
    </Stack.Navigator>
  );
}

const WideMapScreenOptions = (): StackNavigationOptions => ({
  title: 'Discover',
  headerTitle: 'DISCOVER',
  headerShown: false,
  headerTitleAlign: 'center',
});

const SearchScreenOptions = (): StackNavigationOptions => ({
  title: 'Search',
  headerShown: false,
});
