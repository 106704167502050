import { FontAwesome5 } from '@expo/vector-icons';
import React, { useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import EventCard from '/components/Events/EventCard/EventCard';
import ListLoading from '/components/ListLoading';
import { CARD_TITLE_FONT_SIZE, DROPDOWN_STYLE, KEY_GRAY } from '/constants';
import { useListMyEventsQuery, UserRole } from '/generated/graphql';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuthContext, useTeamContext } from '/context';
import Button from '/components/Button';
import { isOrganizationProfileComplete } from '/util';

type Props = {
  userId: string;
};

export default function MyEvents(props: Props) {
  const { userData } = useAuthContext();
  const { activeTeam } = useTeamContext();
  const { navigate } = useNavigation<StackNavigationProp<any>>();
  const [hostedByMe, setHostedByMe] = useState(false);

  const [{ data, fetching, error }, refetch] = useListMyEventsQuery({
    variables: {
      userId: props.userId,
      hostedByMe,
      current_events: true,
      past_events: true,
    },
  });

  const onSeeAll = (type: 'current' | 'past') => {
    navigate('ListMyEvents', {
      type,
      userId: props.userId,
      host: hostedByMe,
    });
  };

  return (
    <SectionContainer>
      <HorizontalContainer>
        <FontAwesome5 name="calendar-alt" size={21} color="black" />
        <Text style={styles.headerText}>
          {userData?.id !== props.userId ? 'Our' : 'My'} Events
        </Text>
      </HorizontalContainer>

      <Dropdown
        style={[
          DROPDOWN_STYLE,
          {
            marginTop: 10,
          },
        ]}
        value={hostedByMe ? 'hostedByMe' : 'all'}
        data={[
          {
            label: 'All Events',
            value: 'all',
          },
          {
            label: 'Hosted by Me',
            value: 'hostedByMe',
          },
        ]}
        labelField="label"
        valueField="value"
        onChange={({ value }) => {
          setHostedByMe(value === 'hostedByMe');
        }}
      />

      {fetching ? (
        <ListLoading />
      ) : error ? (
        <GenericError onRetry={refetch} />
      ) : (
        <>
          {data?.listMyEvents.past_events?.items.length ? (
            // Only show these headers of there are past events
            <Text style={styles.subsectionTitle}>CURRENT EVENTS</Text>
          ) : null}
          {data?.listMyEvents.current_events?.items.length === 0 ? (
            <View
              style={{
                alignItems: 'center',
                marginVertical: 24,
              }}
            >
              <Text
                style={[
                  styles.emptyText,
                  {
                    marginVertical: 0,
                  },
                ]}
              >
                No{' '}
                {data.listMyEvents.past_events?.items.length ? 'current ' : ''}
                events.
              </Text>
              {(props.userId === userData?.id &&
                userData?.role === UserRole.Supporter) ||
                (activeTeam?.user &&
                  isOrganizationProfileComplete(activeTeam?.user) && (
                    <Button
                      label="Create Event"
                      containerStyle={{
                        marginTop: 8,
                      }}
                      onPress={() => {
                        navigate('CreateEvent');
                      }}
                    />
                  ))}
            </View>
          ) : (
            <FlatList
              horizontal
              data={data?.listMyEvents.current_events?.items}
              contentContainerStyle={{
                paddingBottom: 6,
              }}
              renderItem={({ item }) => {
                return (
                  <EventCard
                    event={item}
                    style={{ marginRight: 8 }}
                    showControls
                    viewingAsUserId={props.userId}
                  />
                );
              }}
            />
          )}

          {data?.listMyEvents.current_events?.nextToken ? (
            <Text
              style={styles.seeAllButton}
              onPress={() => onSeeAll('current')}
            >
              See All {`>`}
            </Text>
          ) : null}

          {data?.listMyEvents.past_events?.items.length ? (
            <Text
              style={[
                styles.subsectionTitle,
                {
                  marginTop: 16,
                },
              ]}
            >
              PAST EVENTS
            </Text>
          ) : null}

          {!data?.listMyEvents.past_events?.items.length ? null : (
            <FlatList
              horizontal
              data={data?.listMyEvents.past_events?.items}
              contentContainerStyle={{
                paddingBottom: 6,
              }}
              renderItem={({ item }) => {
                return (
                  <EventCard
                    event={item}
                    style={{
                      marginRight: 8,
                    }}
                    viewingAsUserId={props.userId}
                  />
                );
              }}
            />
          )}

          {data?.listMyEvents.past_events?.nextToken ? (
            <Text style={styles.seeAllButton} onPress={() => onSeeAll('past')}>
              See All {`>`}
            </Text>
          ) : null}
        </>
      )}
    </SectionContainer>
  );
}
const styles = StyleSheet.create({
  headerText: {
    fontSize: CARD_TITLE_FONT_SIZE,
    fontFamily: 'Lato-Bold',
    marginLeft: 8,
  },
  subsectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    color: 'black',
    marginTop: 16,
    marginBottom: 8,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'black',
    marginVertical: 24,
    textAlign: 'center',
    alignSelf: 'center',
  },
  seeAllButton: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: KEY_GRAY,
    marginVertical: 8,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
});
