import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';

const InteractionsFragment = gql`
  fragment AddCommentInteractionsFragment on PostInteractions {
    id
    comments(
      limit: $limit
      nextToken: $nextToken
      startWithCommentId: $startWithCommentId
    ) {
      total
      items {
        id
        created_at
        body {
          id
          text
          language
        }
        mentions {
          id
          start
          end
          userId
        }
        user {
          id
        }
        replies {
          total
          nextToken
          items {
            id
            created_at
            body {
              id
              text
              language
            }
            mentions {
              id
              start
              end
              userId
            }
            user {
              id
              name
              profile_image
            }
          }
        }
      }
    }
  }
`;

const RepliesFragment = gql`
  fragment AddCommentRepliesFragment on NewComment {
    id
    replies(
      limit: $limit
      nextToken: $nextToken
      prevToken: $prevToken
      sortDirection: $sortDirection
      startWithId: $startWithId
    ) {
      total
      nextToken
      items {
        id
        created_at
        body {
          id
          text
          language
        }
        mentions {
          id
          start
          end
          userId
        }
        user {
          id
        }
      }
    }
  }
`;

const addComment: UpdateResolver = (result, args, cache) => {
  if ((result.addComment as any)?.replyToId) {
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'NewComment',
        id: (result.addComment as any)?.replyToId,
      },
      newItem: Object.assign(result.addComment ?? {}, {
        replies: {
          __typename: 'PaginatedNewComments',
          total: 0,
          nextToken: null,
          items: [],
        },
      }),
      paginatedField: {
        name: 'replies',
        fragment: RepliesFragment,
      },
    });
  } else {
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'PostInteractions',
        id: args.interactionsId as string,
      },
      newItem: Object.assign(result.addComment ?? {}, {
        replies: {
          __typename: 'PaginatedNewComments',
          total: 0,
          nextToken: null,
          items: [],
        },
      }),
      paginatedField: {
        name: 'comments',
        fragment: InteractionsFragment,
      },
    });
  }
};

export default addComment;
