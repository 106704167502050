import { KeyboardAwareFlatList } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import commonStyles from './common/styles';
import { KEY_GRAY } from '/constants';
import {
  PaginatedUsersFilterInput,
  SearchUsersQuery,
  useSearchUsersQuery,
} from '/generated/graphql';
import { isEmpty } from '/util';

interface Props {
  query: string;
  filter?: Omit<PaginatedUsersFilterInput, 'query'>;
  /** By default, requesting user is excluded from search results. */
  includeSelf?: boolean;
  excludeUsers?: string[];
  style?: ViewStyle;
  emptyPlaceholderText?: string;
  /** Overrides default behavior */
  onSelectUser?: (data: SearchUsersQuery['searchUsers']['items'][0]) => void;
}

export default function UserSearch(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const hasQueryOrFilter = !!props.query.trim() || !isEmpty(props.filter, true);

  const [{ data, fetching }] = useSearchUsersQuery({
    variables: {
      filter: {
        query: props.query,
        ...props.filter,
      },
      excludeUsers: props.excludeUsers,
      includeSelf: props.includeSelf,
      nextToken,
    },
    pause: !hasQueryOrFilter,
    requestPolicy: 'cache-and-network',
  });

  const nextPage = () => {
    setNextToken(data?.searchUsers.nextToken ?? undefined);
  };

  const renderUser = (user: SearchUsersQuery['searchUsers']['items'][0]) => {
    return (
      <TouchableOpacity
        key={user.id}
        onPress={() => {
          if (props.onSelectUser) {
            props.onSelectUser(user);
          } else navigate('Profile', { id: user.id });
        }}
        style={styles.userContainer}
      >
        <View style={styles.userContainer}>
          <Avatar
            containerStyle={styles.userImage}
            size={55}
            rounded
            source={{
              uri: user.profile_image ?? '',
            }}
          />
          <Text style={styles.usernameText}>{user.name}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <KeyboardAwareFlatList
      enableResetScrollToCoords={false}
      style={[{ flex: 1 }, props.style]}
      keyExtractor={(_, index) => index.toString()}
      data={!hasQueryOrFilter ? [] : data?.searchUsers.items}
      renderItem={({ item }) => {
        return renderUser(item);
      }}
      ItemSeparatorComponent={ItemSeparator}
      onScrollBeginDrag={() => {
        // Dismiss keyboard whenever user begins scrolling
        Keyboard.dismiss();
      }}
      ListEmptyComponent={
        fetching ? null : hasQueryOrFilter &&
          !data?.searchUsers.items.length ? (
          <Text style={commonStyles.emptyText}>No results</Text>
        ) : (
          <Text style={[commonStyles.emptyText]}>
            {props.emptyPlaceholderText ??
              'Discover conservation organizations and individuals'}
          </Text>
        )
      }
      ListHeaderComponent={
        fetching ? (
          <View style={commonStyles.activityIndicatorContainer}>
            <ActivityIndicator size="large" color={KEY_GRAY} />
          </View>
        ) : null
      }
      ListFooterComponent={
        !data?.searchUsers.nextToken ? null : (
          <View style={commonStyles.activityIndicatorContainer}>
            {fetching ? (
              <ActivityIndicator color={KEY_GRAY} />
            ) : (
              <Button label="Load More" loading={fetching} onPress={nextPage} />
            )}
          </View>
        )
      }
      // onEndReachedThreshold={0.8}
      onEndReached={() => {
        if (data?.searchUsers.nextToken !== nextToken) nextPage();
      }}
    />
  );
}

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    height: 80,
    alignItems: 'center',
  },
  usernameText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 20,
    flex: 1,
  },
  userImage: {
    marginHorizontal: 10,
  },
});

function ItemSeparator() {
  return <View style={commonStyles.itemSeparator} />;
}
