import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteNotification: UpdateResolver = (result, args, cache) => {
  if (typeof args.notificationId !== 'string') return;

  // Find all instances of getNotificationsForUser query
  const allFields = cache.inspectFields('Query');
  const notificationQueries = allFields.filter(
    (field) => field.fieldName === 'getNotificationsForUser',
  );

  // Update each query instance
  notificationQueries.forEach((query) => {
    const data = cache.resolve('Query', query.fieldName, query.arguments);
    if (!data) return;

    // Update actionable notifications
    const actionable = cache.resolve(data as string, 'actionable') as any[];
    if (actionable) {
      actionable.forEach((group: any) => {
        const notifications = cache.resolve(
          group as string,
          'notifications',
        ) as any[];
        if (notifications) {
          // Filter out the deleted notification
          const updatedNotifications = notifications.filter(
            (notification: any) => {
              const id = cache.resolve(notification as string, 'id');
              return id !== args.notificationId;
            },
          );

          // Write back the filtered notifications
          cache.link(group as string, 'notifications', updatedNotifications);
        }
      });
    }

    // Update non-actionable notifications
    const nonActionable = cache.resolve(
      data as string,
      'non_actionable',
    ) as any[];
    if (nonActionable) {
      // Filter out the deleted notification
      const updatedNonActionable = nonActionable.filter((notification: any) => {
        const id = cache.resolve(notification as string, 'id');
        return id !== args.notificationId;
      });

      // Write back the filtered notifications
      cache.link(data as string, 'non_actionable', updatedNonActionable);
    }
  });

  // Also invalidate the notification entity
  cache.invalidate({
    __typename: 'Notification',
    id: args.notificationId,
  });
};

export default deleteNotification;
