import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Button from '../Button';
import WriteComment from '../Comments/WriteComment';
import SectionText from '../common/Section/SectionText';
import Alert from '/Alert';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext } from '/context';
import { useCreateTesterGeneralFeedbackMutation } from '/generated/graphql';

type Props = {
  onRequestClose?: () => void;
};

export default function TesterGeneralFeedbackWidget({ onRequestClose }: Props) {
  const [media, setMedia] = useState<string[]>([]);
  const [body, _setBody] = useState('');
  const setBody = (newBody: string) => {
    _setBody(newBody);
  };

  const { userData } = useAuthContext();

  const [{ fetching: submitting }, createTesterGeneralFeedback] =
    useCreateTesterGeneralFeedbackMutation();

  async function onSubmit() {
    const { error } = await createTesterGeneralFeedback({
      input: {
        body,
        media,
      },
    });

    if (error) {
      Alert.alert('Error', 'There was a problem submitting your feedback');
      return false;
    }

    Alert.notify({
      message: 'Feedback submitted!',
      color: KEY_GREEN,
    });

    setBody('');
    setMedia([]);

    onRequestClose?.();

    return true;
  }

  return (
    <View>
      <SectionText style={styles.textColor}>
        Have some suggestions or feedback on ways we can make the platform even
        better? We want to hear it. Send us a direct message here:
      </SectionText>

      <WriteComment
        enableMediaUpload
        media={media}
        onChangeMedia={setMedia}
        value={body}
        style={{ marginTop: 8 }}
        textInputContainerStyle={{
          borderWidth: 2,
          borderRadius: 7,
          paddingVertical: 2,
        }}
        maxDynamicHeight={500}
        hideSubmitButton
        placeholderText="Write a message..."
        maxLength={5000}
        avatarUri={userData?.profile_image ?? undefined}
        buttonBarPlacement="right"
        onChangeText={setBody}
        mediaUploadIcon={
          <MaterialCommunityIcons name="paperclip" size={22} color="black" />
        }
        enableMentions={false}
        onSubmitComment={onSubmit}
      />

      <Button
        label="Submit"
        disabled={!body.trim()}
        loading={submitting}
        onPress={onSubmit}
        containerStyle={styles.greenButtonContainer}
        style={styles.greenButton}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  textColor: {
    color: KEY_GRAY,
  },
  greenButtonContainer: {
    alignSelf: 'flex-end',
    shadowOpacity: 0,
    marginTop: 8,
  },
  greenButton: {
    backgroundColor: KEY_GREEN,
    paddingVertical: 10,
    minWidth: 100,
  },
});
