import { useMemo } from 'react';
import { ViewStyle } from 'react-native';
import GenericError from '/components/common/Generic/GenericError';
import GenericListEmptyComponent from '/components/common/Generic/GenericListEmptyComponent';
import GenericListFooter from '/components/GenericListFooter';
import { GenericPaginatedResponse } from '/types';

type Options = {
  paginationInputs: {
    nextToken?: string | null | undefined;
    prevToken?: string | null | undefined;
  };
  queryState: {
    fetching: boolean;
    stale: boolean;
    error: any;
  };
  paginatedResponse: GenericPaginatedResponse | null | undefined;
  /** If true, the header and footer will be inverted */
  inverted?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  emptyContainerStyle?: ViewStyle;
  onRetry?: () => void;
  onFetchPrevious?: () => void;
  onFetchNext?: () => void;
};

/**
 *
 */
export default function usePaginationHeaderAndFooter({
  paginatedResponse,
  inverted,
  paginationInputs,
  queryState,
  errorMessage,
  emptyMessage,
  emptyContainerStyle,
  onRetry,
  onFetchPrevious,
  onFetchNext,
}: Options) {
  const { nextToken, prevToken } = paginatedResponse || {};

  const isPaginatingBackward =
    !paginationInputs.nextToken && !!paginationInputs.prevToken;

  const showHeaderLoading =
    isPaginatingBackward && (queryState.fetching || queryState.stale);

  const showHeaderError = isPaginatingBackward && !!queryState.error;

  const showFooterLoading =
    !isPaginatingBackward && (queryState.fetching || queryState.stale);

  const showFooterError = !isPaginatingBackward && !!queryState.error;

  const Header = useMemo(() => {
    return !showHeaderLoading && showHeaderError ? (
      <GenericError message={errorMessage} onRetry={onRetry} />
    ) : (
      <GenericListFooter
        loading={showHeaderLoading}
        onFetchMore={onFetchPrevious}
        isFirstPage
        hasMore={!!prevToken}
      />
    );
  }, [
    showHeaderLoading,
    showHeaderError,
    errorMessage,
    onRetry,
    onFetchPrevious,
    prevToken,
  ]);

  const Footer = useMemo(() => {
    return !showFooterLoading && showFooterError ? (
      <GenericError message={errorMessage} onRetry={onRetry} />
    ) : !showFooterLoading && !paginatedResponse?.items?.length ? (
      <GenericListEmptyComponent
        style={emptyContainerStyle}
        placeholderText={emptyMessage}
      />
    ) : (
      <GenericListFooter
        hasMore={!!nextToken}
        onFetchMore={onFetchNext}
        loading={showFooterLoading}
      />
    );
  }, [
    showFooterLoading,
    showFooterError,
    errorMessage,
    onRetry,
    paginatedResponse?.items?.length,
    emptyContainerStyle,
    emptyMessage,
    nextToken,
    onFetchNext,
  ]);

  return {
    Header: inverted ? Footer : Header,
    Footer: inverted ? Header : Footer,
  };
}
