import React from "react";
import { View } from "react-native";
import { BaseItemAnimator } from "recyclerlistview";
import { DefaultJSItemAnimator } from "recyclerlistview/dist/reactnative/platform/reactnative/itemanimators/defaultjsanimator/DefaultJSItemAnimator";

const invertedWheelEventHandler = (e: WheelEvent) => {
  const node = e.currentTarget as HTMLElement;
  
  // For inverted lists, we want to scroll in the opposite direction
  // So when deltaY is positive (scroll down), we want to scroll up
  const deltaY = -e.deltaY;
  const deltaX = -e.deltaX;
  
  // Scroll by the inverted delta
  node.scrollBy({
    top: deltaY,
    left: deltaX,
    behavior: 'auto'
  });
  
  // Prevent the default scroll
  e.preventDefault();
};

const PlatformConfig = {
  defaultDrawDistance: 2000,
  invertedTransformStyle: { 
    transform: [{ scaleY: -1 }], 
    transformOrigin: "center",
    '& > div': {
      transform: 'scaleY(-1)'
    }
  },
  invertedTransformStyleHorizontal: { 
    transform: [{ scaleX: -1 }], 
    transformOrigin: "center",
    '& > div': {
      transform: 'scaleX(-1)'
    }
  },
  setupInvertedWheelHandler: (node: HTMLElement) => {
    if (node) {
      node.addEventListener('wheel', invertedWheelEventHandler, { passive: false });
    }
  },
  teardownInvertedWheelHandler: (node: HTMLElement) => {
    if (node) {
      node.removeEventListener('wheel', invertedWheelEventHandler);
    }
  }
};

const getCellContainerPlatformStyles = (
  inverted: boolean,
  parentProps: { x: number; y: number; isHorizontal?: boolean }
): { transform: string; WebkitTransform: string } | undefined => {
  const transformValue = `translate(${parentProps.x}px,${parentProps.y}px)${
    inverted ? ` ${parentProps.isHorizontal ? `scaleX` : `scaleY`}(-1)` : ``
  }`;
  return { transform: transformValue, WebkitTransform: transformValue };
};

const getItemAnimator = (): BaseItemAnimator | undefined => {
  return new DefaultJSItemAnimator();
};

const getFooterContainer = (): React.ComponentClass | undefined => {
  return View;
};

export {
  PlatformConfig,
  getCellContainerPlatformStyles,
  getItemAnimator,
  getFooterContainer,
};
