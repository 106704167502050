import React, { useEffect, useRef } from 'react';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import WebView from 'react-native-webview';

interface CalBookingModalProps {
  /** Metadata to be stored with the booking */
  metadata: any;
  eventLink: string; // e.g. "mohammad-tourjoman-sr2pny/test-event-type"
  mode?: 'create' | 'reschedule';
  bookingUid?: string; // Required when mode is 'reschedule'
}

const getCalScript = (
  cleanEventLink: string,
  metadata: any,
  mode?: 'create' | 'reschedule',
  bookingUid?: string,
) => {
  const baseScript = `
  (function (C, A, L) { 
    let p = function (a, ar) { a.q.push(ar); }; 
    let d = C.document; 
    C.Cal = C.Cal || function () { 
      let cal = C.Cal; 
      let ar = arguments; 
      if (!cal.loaded) { 
        cal.ns = {}; 
        cal.q = cal.q || []; 
        d.head.appendChild(d.createElement("script")).src = A; 
        cal.loaded = true; 
      } 
      if (ar[0] === L) { 
        const api = function () { p(api, arguments); }; 
        const namespace = ar[1]; 
        api.q = api.q || []; 
        if(typeof namespace === "string"){
          cal.ns[namespace] = cal.ns[namespace] || api;
          p(cal.ns[namespace], ar);
          p(cal, ["initNamespace", namespace]);
        } else p(cal, ar); 
        return;
      } 
      p(cal, ar); 
    }; 
  })(window, "https://app.cal.com/embed/embed.js", "init");
  
  Cal("init", "booking", {origin:"https://cal.com"});
  `;

  const config = {
    layout: 'month_view',
    theme: 'light',
    ...(mode === 'reschedule' && bookingUid
      ? { rescheduleUid: bookingUid }
      : {}),
    ...(metadata
      ? Object.fromEntries(
          Object.entries(metadata).map(([key, value]) => [
            `metadata[${key}]`,
            value,
          ]),
        )
      : {}),
  };

  const inlineConfig = `
  Cal.ns["booking"]("inline", {
    elementOrSelector:"#my-cal-inline",
    config: ${JSON.stringify(config)},
    calLink: "${cleanEventLink}",
  });
  
  Cal.ns["booking"]("ui", {
    "theme":"light",
    "cssVarsPerTheme":{"light":{"cal-brand":"#000"}},
    "hideEventTypeDetails":false,
    "layout":"month_view"
  });`;

  return baseScript + inlineConfig;
};

export default function CalBookingModal({
  metadata,
  eventLink,
  mode = 'create',
  bookingUid,
}: CalBookingModalProps) {
  if (mode === 'reschedule' && !bookingUid) {
    throw new Error('bookingUid is required when mode is "reschedule"');
  }

  const windowDimensions = useWindowDimensions();

  const cleanEventLink = eventLink.replace('https://cal.com/', '');
  const containerRef = useRef<HTMLDivElement>(null);

  // Web implementation
  useEffect(() => {
    if (Platform.OS === 'web' && containerRef.current) {
      const container = containerRef.current;
      const script = document.createElement('script');
      script.innerHTML = getCalScript(
        cleanEventLink,
        metadata,
        mode,
        bookingUid,
      );

      containerRef.current.innerHTML =
        '<div id="my-cal-inline" style="width:100%;height:100%;overflow:scroll"></div>';
      containerRef.current.appendChild(script);

      return () => {
        if (container) {
          container.innerHTML = '';
        }
      };
    }
  }, [cleanEventLink, metadata, mode, bookingUid]);

  const bookingUrl = `https://cal.com/${cleanEventLink}?${new URLSearchParams({
    ...(mode === 'reschedule' && bookingUid
      ? { rescheduleUid: bookingUid }
      : {}),
    ...(metadata ? { metadata: JSON.stringify(metadata) } : {}),
    embed: 'true',
    layout: 'month_view',
    theme: 'light',
  }).toString()}`;

  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <div
          ref={containerRef}
          style={{ width: '100%', height: '100%', overflow: 'auto' }}
        />
      </View>
    );
  }

  return (
    <View
      style={[
        styles.container,
        {
          height: Math.min(windowDimensions.height * 0.75, 640),
          justifyContent: 'space-between',
        },
      ]}
    >
      <WebView
        source={{ uri: bookingUrl }}
        style={styles.webview}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        originWhitelist={['*']}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn('WebView error: ', nativeEvent);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: Platform.OS === 'web' ? 1 : 0,
    backgroundColor: '#ffffff',
  },
  webview: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
