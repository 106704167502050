import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import BackArrowHeader from '../assets/jsicons/miscIcons/BackArrowHeader';
import styles from '../constants/screens/AccountSettingsScreen';

interface IBackButtonHeaderProps {
  onPress: () => void;
  /** Default is 35 */
  size?: number;
}

const BackButtonHeader = (props: IBackButtonHeaderProps) => {
  return (
    <TouchableOpacity onPress={props.onPress} style={{ padding: 18 }}>
      <View style={styles.backArrowTouch}>
        <BackArrowHeader width={props.size ?? 35} height={props.size ?? 35} />
      </View>
    </TouchableOpacity>
  );
};

export default BackButtonHeader;
