import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';

const createMessage: UpdateResolver = (result, args, cache) => {
  if (!(args.input as any)?.discussionBoardId) return;

  const fragment = gql`
    fragment CreateDiscussionBoardMessage on DiscussionBoard {
      id
      messages(
        limit: $limit
        sortDirection: $sortDirection
        nextToken: $nextToken
        prevToken: $prevToken
      ) {
        total
        nextToken
        items {
          id
          body
          created_at
          read
          media
          mentions {
            id
            userId
            start
            end
          }
          user {
            id
            name
            profile_image
          }
          on_behalf_of {
            id
            name
            profile_image
          }
        }
      }
    }
  `;

  addItemToPaginatedField({
    cache,
    entity: {
      __typename: 'DiscussionBoard',
      id: (args.input as any)?.discussionBoardId,
    },
    newItem: result.createMessage,
    paginatedField: {
      name: 'messages',
      fragment,
    },
  });
};

export default createMessage;
