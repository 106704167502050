import { View, Text } from 'react-native';
import React from 'react';
import Accordion from '/components/Accordion/Accordion';
import Button from '/components/Button';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import ChecklistItem from '../common/CheckListItem';
import { KEY_GREEN } from '/constants';
import { getOrganizationProfileCompletionProgress } from '/util';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import commonStyles from '../common.style';
import { IProfileCompletionWidgetUser } from '../ProfileCompletionWidget';

interface Props {
  hideGoToProfileButton: boolean;
  profile: IProfileCompletionWidgetUser | undefined | null;
}

export default function ConservationistProfileCompletionChecklist(
  props: Props,
) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const progress = props.profile
    ? getOrganizationProfileCompletionProgress(props.profile)
    : 0;

  function goToProfile() {
    navigate('ProfileStack');
  }

  return progress < 100 ? (
    <Accordion
      title={`Complete Profile (${progress}%)`}
      subtitle={
        <View>
          <Text
            style={{
              paddingTop: 4,
            }}
          >
            Fill out your profile so that you can begin creating campaigns. Open
            to see what you need to add.
          </Text>
          {props.hideGoToProfileButton ? null : (
            <Button
              containerStyle={{
                marginTop: 8,
                alignSelf: 'flex-end',
              }}
              label="Go to profile"
              onPress={goToProfile}
            />
          )}
        </View>
      }
      subtitleStyle={{
        fontFamily: 'Lato',
      }}
      /** Progress Circle */
      icon={
        <AnimatedCircularProgress
          size={120}
          width={15}
          rotation={0}
          fill={progress}
          tintColor={KEY_GREEN}
          backgroundColor="#dfdfdf"
          style={{ margin: 2 }}
        >
          {() => <Text style={commonStyles.progressText}>{progress}%</Text>}
        </AnimatedCircularProgress>
      }
    >
      <ChecklistItem
        text="Add a profile cover"
        completed={!!props.profile?.cover_image}
      />
      <ChecklistItem
        text='Add your mission in "About Us"'
        completed={!!props.profile?.bio?.text?.trim()}
      />
      {/* TODO: Uncomment when implemented */}
      {/* <ChecklistItem text="Add your research areas" onPress={() => {
        // TODO: Implement
      }} /> */}
    </Accordion>
  ) : null;
}
