import { StyleSheet } from 'react-native';

export default function mergeStyleSheets<
  Style extends StyleSheet.NamedStyles<any> | StyleSheet.NamedStyles<Style>,
  OverrideStyle extends Style,
>(style: Style, overrideStyle: StyleSheet.NamedStyles<Partial<OverrideStyle>>) {
  let mergedStyles = { ...style };

  Object.entries(overrideStyle).forEach(([key, value]) => {
    mergedStyles[key as keyof typeof style] = Object.assign(
      {},
      mergedStyles[key as keyof typeof style],
      value,
    );
  });

  return StyleSheet.create(mergedStyles);
}
