import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import Button, { IButtonProps } from './Button';

export default function TrashButton(props: Partial<IButtonProps>) {
  return (
    <Button
      containerStyle={{
        marginLeft: 6,
      }}
      label={<FontAwesome name="trash-o" size={24} color="black" />}
      {...props}
    />
  );
}
