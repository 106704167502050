import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import dayjs from 'dayjs';
import React from 'react';
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import Link from '/components/Link';
import { BUTTON_SHADOW, KEY_GRAY, PRIMARY_BUTTON_BACKGROUND } from '/constants';
import {
  EventCardFragment,
  EventPrivacy,
  EventType,
  useBookmarkEventMutation,
  useUnbookmarkEventMutation,
} from '/generated/graphql';
import getCDNImageUri from '/util/getCDNImageUri';
import AttendEventButton from '../AttendEventButton/AttendEventButton';
import { useAuthContext } from '/context';
import Button from '/components/Button';
import ShareButton from '/components/Sharing/ShareButton';
import { createUniversalURL } from '/util';
import { eventTypeToLabel } from '../../../util/eventTypeLabels';

interface IEvent extends EventCardFragment {}

type Props = {
  event: IEvent;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  showControls?: boolean;
  viewingAsUserId?: string;
};

const SECTION_MARGIN_TOP = 8;

export default function EventCard(props: Props) {
  const { userData } = useAuthContext();

  const viewingAsUserId = props.viewingAsUserId ?? userData?.id;

  const [, bookmarkEvent] = useBookmarkEventMutation();
  const [, unbookmarkEvent] = useUnbookmarkEventMutation();

  const onBookmarkButtonPress = () => {
    if (props.event.is_bookmarked) unbookmarkEvent({ eventId: props.event.id });
    else bookmarkEvent({ eventId: props.event.id });
  };

  return (
    <Link
      noLinkProps
      to={{
        screen: 'ViewEvent',
        params: {
          id: props.event.id,
          ...(props.viewingAsUserId ? { vUId: props.viewingAsUserId } : {}),
        },
      }}
      target="_blank"
      disabled={props.disabled}
      style={[styles.container, props.style]}
    >
      <View style={styles.imageContainer}>
        <View style={styles.imageOverlay}>
          <Text style={styles.overlayText}>
            {dayjs(props.event.start_date).format('MMM D')}
          </Text>
          <Text
            style={[
              styles.overlayText,
              {
                fontSize: 17,
                color: KEY_GRAY,
              },
            ]}
          >
            {dayjs(props.event.start_date).format('h:mm A')}
          </Text>
        </View>

        <Image
          source={{
            uri: getCDNImageUri({
              uri: props.event.media[0],
              isThumbnail: true,
            }),
          }}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#333',
          }}
          resizeMode="cover"
        />
      </View>

      <View style={styles.bottomHalfContainer}>
        <View>
          <Text style={styles.title} numberOfLines={2}>
            {props.event.title}
          </Text>

          {props.event.type !== EventType.Online && props.event.location ? (
            <HorizontalContainer>
              <MapMarker />
              <Text numberOfLines={1} style={styles.locationText}>
                {props.event.location?.name}
              </Text>
            </HorizontalContainer>
          ) : (
            <Text style={styles.eventTypeText}>
              {eventTypeToLabel(props.event.type)}
            </Text>
          )}

          <HorizontalContainer style={{ flexWrap: 'wrap-reverse' }}>
            {props.event.type === EventType.Online ? null : (
              <HorizontalContainer
                style={{ marginTop: SECTION_MARGIN_TOP, marginRight: 6 }}
              >
                <FontAwesome5
                  style={styles.suitabilityIcon}
                  name="child"
                  size={24}
                  color={props.event.kid_friendly ? 'black' : 'lightgray'}
                />
                <FontAwesome
                  style={styles.suitabilityIcon}
                  name="wheelchair-alt"
                  size={24}
                  color={
                    props.event.accessibility_friendly ? 'black' : 'lightgray'
                  }
                />
                <FontAwesome5
                  style={styles.suitabilityIcon}
                  name="building"
                  size={24}
                  color={props.event.bathrooms_nearby ? 'black' : 'lightgray'}
                />
              </HorizontalContainer>
            )}

            <Text
              style={[
                styles.eventPrivacyText,
                {
                  marginTop: SECTION_MARGIN_TOP,
                },
              ]}
            >
              {props.event.privacy === EventPrivacy.Private
                ? 'Private Event'
                : 'Public Event'}
            </Text>
          </HorizontalContainer>
        </View>

        <View>
          {props.showControls ? (
            <HorizontalContainer
              style={{
                marginTop: SECTION_MARGIN_TOP,
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
              }}
            >
              <View
                style={{
                  flex: 1,
                  minWidth: 110,
                  marginTop: SECTION_MARGIN_TOP,
                }}
              >
                {!props.event.partnership?.confirmed_at &&
                props.event.userId !== viewingAsUserId &&
                dayjs(props.event.end_date).isAfter(dayjs()) ? (
                  <AttendEventButton
                    attendee={props.event.attendee}
                    eventId={props.event.id}
                    userId={viewingAsUserId}
                  />
                ) : props.event.partnership?.confirmed_at ||
                  props.event.userId === viewingAsUserId ? (
                  <Button label="Hosting" disabled />
                ) : (
                  <Button label={'Event Ended'} disabled />
                )}
              </View>
              <HorizontalContainer
                style={{
                  marginTop: SECTION_MARGIN_TOP,
                }}
              >
                {userData?.id && viewingAsUserId === userData?.id ? (
                  <Button
                    onPress={onBookmarkButtonPress}
                    label={
                      <FontAwesome
                        name={
                          props.event.is_bookmarked ? 'bookmark' : 'bookmark-o'
                        }
                        size={20.5}
                        color="black"
                      />
                    }
                    containerStyle={{
                      marginLeft: 8,
                    }}
                  />
                ) : null}
                <ShareButton
                  iconSize={19}
                  url={createUniversalURL(`events/${props.event.id}`)}
                  shareMessage="Check out this event on Key Conservation!"
                  style={{
                    marginLeft: 8,
                    borderRadius: 6,
                    padding: 13,
                    marginTop: 0,
                    paddingVertical: 7,
                    width: 'auto',
                    height: 'auto',
                    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
                    ...BUTTON_SHADOW,
                  }}
                />
              </HorizontalContainer>
            </HorizontalContainer>
          ) : null}
        </View>
      </View>
    </Link>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    overflow: 'hidden',
    maxWidth: 420,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    alignSelf: 'stretch',
    minHeight: 200,
  },
  imageContainer: {
    width: '100%',
    height: 100,
    flexShrink: 0,
  },
  imageOverlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    width: '30%',
    minWidth: 100,
    maxWidth: 200,
    backgroundColor: 'rgba(240, 240, 240, 0.85)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayText: {
    fontFamily: 'Lato-Bold',
    fontSize: 19,
    color: '#000',
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    marginBottom: 2,
  },
  locationText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: KEY_GRAY,
    marginLeft: 4,
  },
  suitabilityIcon: {
    marginHorizontal: 8,
  },
  eventPrivacyText: {
    fontSize: 16,
    color: KEY_GRAY,
    fontFamily: 'Lato-BoldItalic',
    marginRight: 8,
  },
  bottomHalfContainer: {
    minHeight: 100,
    width: '100%',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: 2,
    borderTopWidth: 0,
    borderColor: 'rgba(24, 24, 24, 0.1)',
    padding: 8,
    justifyContent: 'space-between',
  },
  eventTypeText: {
    color: 'gray',
    fontFamily: 'Lato',
    fontSize: 16,
  },
});
