import { UpdateResolver } from '@urql/exchange-graphcache';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';
import { gql } from 'urql';

const fragment = gql`
  fragment CreateDirectMessageGetDirectConversation on Query {
    getDirectConversation(
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) {
      total
      items {
        id
        read_at
        created_at
        senderId
        receiverId
        body {
          id
          text
          language
        }
        link_preview {
          url
          image
          title
          description
        }
        sender {
          id
          name
          profile_image
        }
        officialBroadcastRecipientRole
      }
    }
  }
`;

const createDirectMessage: UpdateResolver = (result, args, cache) => {
  const data = cache.readFragment(
    fragment,
    {
      __typename: 'Query',
      id: null,
    },
    {
      userId: args.userId,
    },
  ) as any;

  const existingMessage = data?.getDirectConversation?.items?.find(
    (item: any) => item.id === (result.createDirectMessage as any)?.id,
  );

  if (!existingMessage) {
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'Query',
        id: null,
      },
      newItem: result.createDirectMessage,
      paginatedField: {
        fragment,
        name: 'getDirectConversation',
      },
    });
  }
};

export default createDirectMessage;
