import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import withAuthRequired from '../../components/withAuthRequired';
import Alert from '/Alert';
import Button from '/components/Button';
import CampaignPreview from '/components/CampaignPreview/CampaignPreview';
import SearchableSection from '/components/ImpactTab/SearchableSection';
import SelectedApplicationCTA from '/components/ImpactTab/SelectedApplicationCTA';
import { KEY_GRAY } from '/constants';
import {
  SkillRequestApplication,
  SkillRequestApplicationStatus,
  useGetMySkillRequestApplicationsQuery,
  UserRole,
} from '/generated/graphql';
import { isCloseToBottom } from '/util';
import ScrollView from '/components/common/ScrollView/ScrollView';

interface ISupViewAllApplications {
  route: RouteProp<any>;
  navigation: StackNavigationProp<any>;
}

function SupViewAllApplications({
  route,
  navigation,
}: ISupViewAllApplications) {
  const { title, status } = route.params ?? {};

  const [query, setQuery] = useState('');

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }] =
    useGetMySkillRequestApplicationsQuery({
      variables: {
        status: status,
        nextToken,
        query,
      },
    });

  const applications = data?.me?.skill_request_applications?.items;

  useEffect(() => {
    if (error) {
      Alert.alert(
        'Oh no',
        'There was a problem fetching your applications. Please try again later.',
        [
          {
            text: 'Dismiss',
            onPress: () => {
              if (!applications?.length) {
                navigation.goBack();
              }
            },
          },
        ],
      );
    }
  }, [applications?.length, error, fetching, navigation]);

  function viewApplicationDetails(
    application: Pick<SkillRequestApplication, 'id'>,
  ) {
    navigation.navigate('ViewApplicationDetails', {
      applicationId: application.id,
    });
  }

  function onLoadMore() {
    if (data?.me?.skill_request_applications?.nextToken) {
      setNextToken(data.me.skill_request_applications.nextToken);
    }
  }

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const hasMore = !!data?.me?.skill_request_applications?.nextToken;

    if (hasMore && isCloseToBottom(event.nativeEvent) && !fetching) {
      onLoadMore();
    }
  }

  return (
    <ScrollView onScroll={onScroll}>
      <SearchableSection
        onQueryChange={setQuery}
        contentContainerStyle={{
          paddingTop: 8,
          backgroundColor: 'white',
        }}
        title={title}
      >
        {applications?.map((app, i) => (
          <CampaignPreviewContainer key={app.id}>
            <CampaignPreview
              onPress={() => viewApplicationDetails(app)}
              key={app.id ?? i}
              bottomDivider={i < applications?.length - 1}
              showChevronArrow
              showSocialControls
              widget={
                app.status ===
                SkillRequestApplicationStatus.SelectedUnconfirmed ? (
                  <SelectedApplicationCTA
                    applicationId={app.id ?? ''}
                    selectedAt={app.selected_at ?? ''}
                  />
                ) : undefined
              }
              campaign={app.skill_request?.campaign}
              campaignPost={app.skill_request?.campaign?.original_post}
            />
          </CampaignPreviewContainer>
        )) ||
          (fetching && (
            <ActivityIndicator
              size="large"
              color={KEY_GRAY}
              style={{
                alignSelf: 'center',
              }}
            />
          ))}
        {data?.me?.skill_request_applications?.nextToken &&
          (fetching || stale ? (
            <ActivityIndicator
              size="large"
              color={KEY_GRAY}
              style={{
                alignSelf: 'center',
                padding: 24,
                paddingTop: 36,
              }}
            />
          ) : (
            <Button
              label="Load more"
              onPress={onLoadMore}
              containerStyle={{
                padding: 24,
                paddingTop: 36,
                alignSelf: 'center',
              }}
            />
          ))}
      </SearchableSection>
    </ScrollView>
  );
}

export default withAuthRequired(SupViewAllApplications, {
  requireUserRole: UserRole.Supporter,
});

const CampaignPreviewContainer = (props: React.PropsWithChildren<{}>) => (
  <View
    style={{
      marginVertical: 4,
    }}
  >
    {props.children}
  </View>
);
