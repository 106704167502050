import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { KEY_DARK_GREEN, KEY_GRAY, KEY_GREEN } from '/constants';

export const DRAWER_WIDTH = 375;

const useStyles = CreateResponsiveStyle(
  {
    contentContainer: {
      padding: 50,
      paddingTop: 80,
      width: '100%',
      maxWidth: 1800,
      alignSelf: 'center',
    },
    title: {
      fontFamily: 'FranklinGothic-Demi',
      fontSize: 48,
      color: KEY_GRAY,
    },
    drawer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: DRAWER_WIDTH,
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
        width: -2,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    backToKeyPlatformText: {
      marginTop: 4,
      fontFamily: 'FranklinGothic-Demi',
      fontSize: 20,
      color: KEY_GRAY,
      marginLeft: 8,
    },
    welcomeText: {
      fontFamily: 'FranklinGothic-Demi',
      fontSize: 27,
      color: KEY_GRAY,
    },
    textColor: {
      color: KEY_GRAY,
    },
    smallHeading: {
      fontFamily: 'FranklinGothic-Demi',
      fontSize: 20,
      color: KEY_GRAY,
      marginTop: 40,
    },
    textInput: {
      fontFamily: 'Lato',
      fontSize: 16,
      padding: 8,
      borderWidth: 2,
      borderRadius: 7,
    },
    greenButtonContainer: {
      alignSelf: 'flex-end',
      shadowOpacity: 0,
      marginTop: 8,
    },
    greenButton: {
      backgroundColor: KEY_GREEN,
      paddingVertical: 10,
      minWidth: 100,
    },
    unsubscribeText: {
      fontFamily: 'Lato-Bold',
      fontSize: 16,
      color: KEY_DARK_GREEN,
    },
    signupHorizontalContainer: {
      alignItems: 'flex-start',
    },
    signupButtonContainer: {
      alignItems: 'center',
      marginLeft: 16,
      marginTop: 40,
    },
    pastFeedbackTabButtonText: {
      fontSize: 17,
    },
    pastFeedbackTabButton: {},
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      pastFeedbackTabButtonText: {
        fontSize: 15,
      },
      pastFeedbackTabButton: {
        paddingVertical: 6,
        paddingHorizontal: 10,
      },
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      contentContainer: {
        padding: 20,
        paddingTop: 40,
      },
      title: {
        fontSize: 40,
      },
      drawer: {
        width: DRAWER_WIDTH * 0.8,
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      signupHorizontalContainer: {
        flexDirection: 'column',
      },
      signupButtonContainer: {
        marginLeft: 0,
        marginTop: 16,
      },
    },
  },
);

export default useStyles;
