import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import SectionContainer from '/components/common/Section/SectionContainer';
import CreativeConnectProjectCard from '/components/CreativeConnect/CreativeConnectProjectCard/CreativeConnectProjectCard';
import { CREATIVE_CONNECT_BACKGROUND_GRAY, KEY_LIGHT_GRAY } from '/constants';
import { UserProfileFragmentFragment } from '/generated/graphql';
import { base64EncodeObject, shorten } from '/util';

type Props = {
  data: UserProfileFragmentFragment;
};

export default function UserCreativeConnect(props: Props) {
  const projects = props.data.creative_connect_projects;
  const { styles: commonStyles } = useCommonStyles();
  const { navigate } = useNavigation<StackNavigationProp<any>>();
  return !projects?.items.length ? null : (
    <SectionContainer
      style={{
        backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
      }}
    >
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={[commonStyles('sectionTitle'), { color: 'white' }]}>
          CREATIVE PROJECTS ({projects.total})
        </Text>
      </View>
      <Text style={styles.sectionText}>
        Creative projects{' '}
        <Text style={{ fontFamily: 'Lato-Bold' }}>
          {shorten(props.data.name, 28)}
        </Text>{' '}
        is involved in
      </Text>

      <FlatList
        horizontal
        contentContainerStyle={styles.horizontalFlatListContentContainer}
        data={projects.items ?? []}
        renderItem={({ item }) => {
          return (
            <View>
              <CreativeConnectProjectCard
                data={item}
                displayCreativeTypeAndStatus
                style={{
                  width: 300,
                  marginRight: 8,
                }}
                viewAsUserId={props.data.id}
              />
            </View>
          );
        }}
      />

      {projects.nextToken ? (
        <Text
          onPress={() => {
            navigate('ListCreativeConnectProjects', {
              ccf: base64EncodeObject({
                confirmedCollaboratorOrCreatedByUserId: props.data.id,
              }),
            });
          }}
          style={styles.seeAllButton}
        >
          See All {`>`}
        </Text>
      ) : null}
    </SectionContainer>
  );
}

const styles = StyleSheet.create({
  sectionTitle: {
    marginTop: 8,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    color: KEY_LIGHT_GRAY,
  },
  sectionText: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 15,
    marginBottom: 8,
  },
  horizontalFlatListContentContainer: {
    paddingBottom: 8,
  },
  seeAllButton: {
    alignSelf: 'flex-end',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'white',
    marginTop: 8,
  },
});
