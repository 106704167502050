import { createStackNavigator } from '@react-navigation/stack';
import {
  CommonClusterParamList,
  createCommonCluster,
  getStackNavigatorOptions,
} from './common';
import OrgOurImpact from '/screens/OurImpact/OrgOurImpact';
import SupporterMyImpact from '/screens/MyImpact/SupporterMyImpact';
import SupViewAllApplications from '/screens/MyImpact/SupViewAllApplications';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAppInfo } from '/context/AppInfoProvider';

const Stack = createStackNavigator<ImpactStackParamList>();

export type ImpactStackParamList = {
  OrgSkilledImpact: {};
  SupSkilledImpact: {};
  SupViewAllApplications: {};
  ViewApplicants: {};
  ViewApplicantDetails: {};
} & CommonClusterParamList;

export default function ImpactStack() {
  const safeAreaInsets = useSafeAreaInsets();
  const { isFeatureFlagEnabled } = useAppInfo();
  return (
    <Stack.Navigator
      initialRouteName={'SupSkilledImpact'}
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={OrgOurImpact}
        name="OrgSkilledImpact"
        options={OrgSkilledImpactScreenOptions}
      />
      <Stack.Screen
        component={SupporterMyImpact}
        name="SupSkilledImpact"
        options={SupSkilledImpactScreenOptions}
      />
      <Stack.Screen
        component={SupViewAllApplications}
        name="SupViewAllApplications"
        options={SupViewAllApplicationsOptions}
      />
      {createCommonCluster(Stack, isFeatureFlagEnabled)}
    </Stack.Navigator>
  );
}

const OrgSkilledImpactScreenOptions = () => ({
  title: 'Our Impact',
  headerTitle: 'OUR IMPACT',
});

const SupSkilledImpactScreenOptions = () => ({
  title: 'My Impact',
  headerTitle: 'MY IMPACT',
});

const SupViewAllApplicationsOptions = () => ({
  title: 'My Applications',
  headerTitle: 'VIEW ALL',
});
