import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import LanguagePicker from '/components/LanguagePicker/LanguagePicker';
import RemovableBadge from '/components/RemovableBadge';
import SkillButton from '/components/SkilledImpact/SkillButton';
import { KEY_YELLOW, SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import { User } from '/generated/graphql';
import { DeepPartial } from '/types';

type LanguageCategory =
  | 'languages_fluent'
  | 'languages_conversational'
  | 'languages_beginner';

interface IData
  extends Pick<
    User,
    'languages_beginner' | 'languages_conversational' | 'languages_fluent'
  > {}

interface Props {
  data: Partial<IData> | undefined;
  setData: (data: DeepPartial<IData>) => void;
  isEditing: boolean;
}

export default function MyLanguages(props: Props) {
  const { userData } = useAuthContext();

  const { styles: commonStyles } = useCommonStyles();

  function onRemoveLanguage(category: LanguageCategory, language: string) {
    props.setData({
      [category]: (props.data?.[category] ?? []).filter(
        (lang) => lang !== language,
      ),
    });
  }

  const totalFluent = props.data?.languages_fluent?.length ?? 0;
  const totalConversational = props.data?.languages_conversational?.length ?? 0;
  const totalBeginner = props.data?.languages_beginner?.length ?? 0;

  const totalLanguages = totalFluent + totalConversational + totalBeginner;

  return !totalLanguages ? null : (
    <View style={SECTION_CONTAINER}>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>MY LANGUAGES</Text>
      </View>

      {totalFluent || props.isEditing ? (
        <>
          <Text style={styles.languageSubsectionTitle}>Fluent</Text>
          <View style={styles.flexWrapContainer}>
            {props.data?.languages_fluent?.map((lang) =>
              props.isEditing ? (
                <RemovableBadge
                  color={KEY_YELLOW}
                  key={lang}
                  label={lang}
                  onRemove={() => onRemoveLanguage('languages_fluent', lang)}
                />
              ) : (
                <SkillButton
                  key={lang}
                  skill={lang}
                  color={
                    userData?.languages_fluent.includes(lang)
                      ? KEY_YELLOW
                      : undefined
                  }
                />
              ),
            )}
          </View>
          {props.isEditing ? (
            <LanguagePicker
              buttonContainerStyle={styles.addLanguageButtonContainer}
              onSelectLanguage={(language) => {
                props.setData({
                  languages_fluent: Array.from(
                    new Set([
                      ...(props.data?.languages_fluent ?? []),
                      language,
                    ]),
                  ),
                });
              }}
              buttonLabel="Add fluent languages"
            />
          ) : null}
        </>
      ) : null}

      {totalConversational || props.isEditing ? (
        <>
          <Text style={styles.languageSubsectionTitle}>Intermediate</Text>
          <View style={styles.flexWrapContainer}>
            {props.data?.languages_conversational?.map((lang) =>
              props.isEditing ? (
                <RemovableBadge
                  color={KEY_YELLOW}
                  key={lang}
                  label={lang}
                  onRemove={() =>
                    onRemoveLanguage('languages_conversational', lang)
                  }
                />
              ) : (
                <SkillButton
                  key={lang}
                  skill={lang}
                  color={
                    userData?.languages_conversational.includes(lang)
                      ? KEY_YELLOW
                      : undefined
                  }
                />
              ),
            )}
          </View>
          {props.isEditing ? (
            <LanguagePicker
              buttonContainerStyle={styles.addLanguageButtonContainer}
              onSelectLanguage={(language) => {
                props.setData({
                  languages_conversational: Array.from(
                    new Set([
                      ...(props.data?.languages_conversational ?? []),
                      language,
                    ]),
                  ),
                });
              }}
              buttonLabel="Add intermediate languages"
            />
          ) : null}
        </>
      ) : null}

      {totalBeginner || props.isEditing ? (
        <>
          <Text style={styles.languageSubsectionTitle}>Basic</Text>
          <View style={styles.flexWrapContainer}>
            {props.data?.languages_beginner?.map((lang) =>
              props.isEditing ? (
                <RemovableBadge
                  key={lang}
                  color={KEY_YELLOW}
                  label={lang}
                  onRemove={() => onRemoveLanguage('languages_beginner', lang)}
                />
              ) : (
                <SkillButton
                  key={lang}
                  skill={lang}
                  color={
                    userData?.languages_beginner.includes(lang)
                      ? KEY_YELLOW
                      : undefined
                  }
                />
              ),
            )}
          </View>
          {props.isEditing ? (
            <LanguagePicker
              buttonContainerStyle={styles.addLanguageButtonContainer}
              onSelectLanguage={(language) => {
                props.setData({
                  languages_beginner: Array.from(
                    new Set([
                      ...(props.data?.languages_beginner ?? []),
                      language,
                    ]),
                  ),
                });
              }}
              buttonLabel="Add beginner languages"
            />
          ) : null}
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  languageSubsectionTitle: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
  },
  flexWrapContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  addLanguageButtonContainer: {
    marginBottom: 6,
  },
});
