import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';

import { createCommonCluster, getStackNavigatorOptions } from './common';

import NotificationsMain from '/screens/Notifications/NotificationsMain';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ListActionableNotifications from '/screens/Notifications/ListActionableNotifications';
import { useAppInfo } from '/context/AppInfoProvider';

const Stack = createStackNavigator();

export default function NotificationStack() {
  const safeAreaInsets = useSafeAreaInsets();
  const { isFeatureFlagEnabled } = useAppInfo();
  return (
    <Stack.Navigator
      initialRouteName="Notifications"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={NotificationsMain}
        name="Notifications"
        options={NotificationScreenOptions}
      />
      <Stack.Screen
        component={ListActionableNotifications}
        name="ListActionableNotifications"
        options={ListActionableNotificationsScreenOptions}
      />
      {createCommonCluster(Stack, isFeatureFlagEnabled)}
    </Stack.Navigator>
  );
}

const NotificationScreenOptions = (): StackNavigationOptions => ({
  title: 'Notifications',
  headerTitle: 'NOTIFICATIONS',
  headerTitleAlign: 'center',
});

const ListActionableNotificationsScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Notifications',
    headerTitle: 'NOTIFICATIONS',
    headerTitleAlign: 'center',
  });
