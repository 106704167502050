import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  LayoutChangeEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import ProgressiveImageBackground from '../../ProgressiveImageBackground';
import { getRandomScrapPattern } from '/assets/KeyAnimalPattern';
import Avatar from '/components/Avatar';
// @ts-ignore
import {
  AntDesign,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from '@expo/vector-icons';
import { HeaderBackButton } from '@react-navigation/elements';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ResizeMode, Video } from 'expo-av';
// @ts-ignore
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DEVICE_SIZES, minSize } from 'rn-responsive-styles';
import SocialLinks from '../SocialLinks';
import useStyles from './ProfileHeader.style';
import Alert from '/Alert';
import Ellipse from '/assets/jsicons/Ellipse';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import UserActionSheetPressable from '/components/ActionSheet/UserActionSheetPressable';
import LocationPickerModal, {
  LocationPickerResult,
} from '/components/LocationPickerModal';
import TextInputWithIcon from '/components/TextInputWithIcon';
import UploadMedia from '/components/UploadMedia/UploadMedia';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import { useAuthContext, useModalContext } from '/context';
import {
  Location,
  TeamMember,
  User,
  UserProfileFragmentFragment,
  UserRole,
} from '/generated/graphql';
import { ProfileStackParamList } from '/navigation/navigators/nested/ProfileStack';
import { DeepPartial } from '/types';
import { determineIfVideo, shorten } from '/util';
import useCDNVideoSource from '/hooks/useCDNVideoSource';
import ProfileToolbar from '../conservationist/ProfileToolbar';

interface IFormData
  extends Pick<
    User,
    | 'name'
    | 'cover_image'
    | 'profile_image'
    | 'is_verified'
    | 'locations'
    | 'role'
    | 'id'
  > {
  teams?: ISupporterTeamsTeam[] | undefined;
}

interface IProfileHeaderProps {
  data: DeepPartial<UserProfileFragmentFragment> | undefined | null;
  formData: IFormData | undefined | null;
  scrollY: Animated.Value | undefined;
  /** When editing, we add height to the header and display
   * buttons allowing user to add cover image/social links */
  isEditing: boolean;
  isSaving: boolean;
  onEditProfile: () => void;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
  /** Set parent to busy to prevent saving until we are ready for it */
  setBusy: (busy: boolean) => void;
  setData: (data: DeepPartial<User>) => void;
  onLayout?: (height: number, minimumHeight: number) => void;
}

const HEADER_WHITE_BACKGROUND = 'rgba(255, 255, 255, 0.68)';
const HEADER_BLACK_BACKGROUND = 'rgba(0, 0, 0, 0.4)';

const MINIMUM_BASE_HEIGHT = 100;

export default function ProfileHeader({
  onLayout,
  ...props
}: IProfileHeaderProps) {
  const { height: window_height } = useWindowDimensions();

  const [disableSmallHeaderInteractions, setDisableSmallHeaderInteractions] =
    useState(false);

  const { userData } = useAuthContext();

  const headerVideoSource = useCDNVideoSource({
    uri: props.formData?.cover_image,
  });

  const [toolbarHeight, setToolbarHeight] = useState(0);

  const COMPONENT_HEIGHT = useMemo(
    () => Math.min(window_height * 0.95, 920),
    [window_height],
  );

  const { goBack, getState } =
    useNavigation<NavigationProp<ProfileStackParamList, 'Profile'>>();

  // Used to disable input to the UploadMedia while uploading to prevent unpredictable behavior
  const [isUploadingProfileImage, setUploadingProfileImage] = useState(false);
  const [profileImageUploadProgress, setProfileImageUploadProgress] =
    useState(0);

  const [shouldPlayCoverVideo, setShouldPlayCoverVideo] = useState(false);

  /** Used to revert name input back to previously set value if the user unfocuses the
   * input with an empty value */
  const nameBeforeEditing = useRef(props.formData?.name);

  const currentRouteIndex = useRef(getState().index);

  const animalPattern = useMemo(() => getRandomScrapPattern(), []);

  const { top: safeAreaInsetTop } = useSafeAreaInsets();

  const [smallHeaderHeight, setSmallHeaderHeight] = useState(0);

  const MINIMUM_COMPONENT_HEIGHT = useMemo(
    () => Math.max(MINIMUM_BASE_HEIGHT + safeAreaInsetTop, smallHeaderHeight),
    [safeAreaInsetTop, smallHeaderHeight],
  );

  const { styles, deviceSize } = useStyles();

  useFocusEffect(
    useCallback(() => {
      currentRouteIndex.current = getState().index;

      setShouldPlayCoverVideo(true);
      return () => setShouldPlayCoverVideo(false);
    }, [getState]),
  );

  const [layoutHeight, setLayoutHeight] = useState(0);
  const handleLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayoutHeight(nativeEvent.layout.height);
  };

  useEffect(() => {
    onLayout?.(layoutHeight, MINIMUM_COMPONENT_HEIGHT);
  }, [layoutHeight, onLayout, MINIMUM_COMPONENT_HEIGHT]);

  const onNameInputBlur = () => {
    // When user unfocuses, make sure there is a value. If the value is
    // falsy even after trimming, restore value it had before the user started editing
    if (!props.formData?.name?.trim() && nameBeforeEditing.current) {
      props.setData({
        name: nameBeforeEditing.current,
      });
    } else {
      // Otherwise, just trim user's input in-case it has trailing or preceding spaces
      props.setData({
        name: props.formData?.name?.trim() || '',
      });
    }
    nameBeforeEditing.current = undefined;
  };

  const onNameInputFocus = () => {
    // Remember the value of the input when we first focus
    nameBeforeEditing.current = props.formData?.name;
  };

  const onToolbarLayout = (event: LayoutChangeEvent) => {
    setToolbarHeight(event.nativeEvent.layout.height);
  };

  const isSmallDevice = useMemo(
    () =>
      deviceSize === DEVICE_SIZES.SMALL_DEVICE ||
      deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE,
    [deviceSize],
  );

  // Use refs to store this information so that we can mutate their values later and have the changes
  // propagate in the animations
  const shouldRemainCollapsed = useMemo(
    () => !props.formData?.cover_image && isSmallDevice,
    [isSmallDevice, props.formData],
  );

  const inputMax = useRef<number>(0.1);

  inputMax.current = shouldRemainCollapsed
    ? 0.1
    : COMPONENT_HEIGHT - MINIMUM_COMPONENT_HEIGHT;

  const scrollY = useMemo(
    () => props.scrollY || new Animated.Value(0),
    [props.scrollY],
  );

  useEffect(() => {
    const listenerId = scrollY.addListener(({ value }) => {
      if (isSmallDevice) {
        /** If we are on a small device and the small device header is rendered at all times, escape */
        if (disableSmallHeaderInteractions) {
          setDisableSmallHeaderInteractions(false);
        }

        return;
      }

      const enableThreshold = inputMax.current - 75;

      if (disableSmallHeaderInteractions && value > enableThreshold) {
        setDisableSmallHeaderInteractions(false);
      } else if (!disableSmallHeaderInteractions && value < enableThreshold) {
        setDisableSmallHeaderInteractions(true);
      }
    });

    return () => scrollY.removeListener(listenerId);
  }, [disableSmallHeaderInteractions, isSmallDevice, scrollY]);

  const diffClampScrollY = scrollY.interpolate({
    inputRange: [inputMax.current, inputMax.current + MINIMUM_COMPONENT_HEIGHT],
    outputRange: [0, MINIMUM_COMPONENT_HEIGHT],
    extrapolateLeft: 'clamp',
  });

  const diffClamp = Animated.diffClamp(
    diffClampScrollY,
    inputMax.current,
    inputMax.current + MINIMUM_COMPONENT_HEIGHT - safeAreaInsetTop,
  );
  const diffTranslateY = diffClamp.interpolate({
    inputRange: [inputMax.current, inputMax.current + MINIMUM_COMPONENT_HEIGHT],
    outputRange: [0, -MINIMUM_COMPONENT_HEIGHT],
    extrapolate: 'clamp',
  });

  const translateY =
    scrollY?.interpolate({
      inputRange: [0, inputMax.current],
      outputRange: [0, -inputMax.current],
      extrapolate: 'clamp',
    }) ?? 0;

  const totalTranslateY = Animated.add(translateY, diffTranslateY);

  const translateYInverted =
    scrollY?.interpolate({
      inputRange: [0, inputMax.current],
      outputRange: [0, inputMax.current],
      extrapolate: 'clamp',
    }) ?? 0;

  const imageOffsetY =
    scrollY?.interpolate({
      inputRange: [0, inputMax.current],
      outputRange: [0, inputMax.current / 2],
      extrapolate: 'clamp',
    }) ?? 0;

  const imageScale = shouldRemainCollapsed
    ? 1.5
    : scrollY?.interpolate({
        inputRange: [0, 2 * (inputMax.current / 3), inputMax.current],
        outputRange: [1, 1, 1.5],
        extrapolate: 'clamp',
      }) ?? 1;

  const smallHeaderOpacity =
    scrollY?.interpolate({
      inputRange: [0, (inputMax.current / 3) * 2, inputMax.current],
      outputRange: [0, 0, 1],
      extrapolate: 'clamp',
    }) ?? undefined;

  const largeHeaderOpacity =
    scrollY?.interpolate({
      inputRange: [0, (inputMax.current / 3) * 2, inputMax.current],
      outputRange: [1, 0, 0],
      extrapolate: 'clamp',
    }) ?? undefined;

  const headerBackgroundColor = isSmallDevice
    ? HEADER_WHITE_BACKGROUND
    : scrollY?.interpolate({
        inputRange: [0, 2 * (inputMax.current / 3), inputMax.current],
        outputRange: [
          HEADER_BLACK_BACKGROUND,
          HEADER_BLACK_BACKGROUND,
          HEADER_WHITE_BACKGROUND,
        ],
        extrapolate: 'clamp',
      }) ?? HEADER_BLACK_BACKGROUND;

  const headerDarkEllipseOpacity = isSmallDevice
    ? 0
    : scrollY?.interpolate({
        inputRange: [
          0,
          2 * (inputMax.current / 3),
          3 * (inputMax.current / 4),
          inputMax.current,
        ],
        outputRange: [0, 0, 1, 1],
      });

  const shouldRenderVideo =
    determineIfVideo(props.formData?.cover_image ?? '') &&
    !headerVideoSource.fetching;

  return (
    <Animated.View
      style={[
        styles('container'),
        {
          minHeight:
            (shouldRemainCollapsed
              ? MINIMUM_COMPONENT_HEIGHT
              : COMPONENT_HEIGHT) + toolbarHeight,
          transform: [{ translateY: totalTranslateY }],
        },
      ]}
      pointerEvents={'box-none'}
      onLayout={handleLayout}
    >
      {shouldRenderVideo ? (
        <Video
          resizeMode={ResizeMode.COVER}
          shouldPlay={shouldPlayCoverVideo}
          isMuted
          isLooping
          pointerEvents="none"
          style={{
            height: COMPONENT_HEIGHT,
            width: '100%',
            pointerEvents: 'none',
          }}
          videoStyle={{ height: COMPONENT_HEIGHT, width: '100%' }}
          source={headerVideoSource.source}
        />
      ) : (
        <ProgressiveImageBackground
          source={{
            uri: props.formData?.cover_image || animalPattern.uri,
          }}
          style={{
            transform: [{ translateY: imageOffsetY }, { scale: imageScale }],
          }}
        />
      )}
      <Animated.View
        pointerEvents={'box-none'}
        style={[
          styles('content'),
          {
            maxHeight: isSmallDevice ? MINIMUM_COMPONENT_HEIGHT : undefined,
            transform: [
              {
                translateY: shouldRemainCollapsed ? 0 : translateYInverted,
              },
            ],
            backgroundColor: headerBackgroundColor,
          },
        ]}
      >
        {/* On small devices (appears on large devices when header collapses) */}
        <Animated.View
          pointerEvents={'box-none'}
          onLayout={(e) => {
            if (smallHeaderHeight !== e.nativeEvent.layout.height) {
              setSmallHeaderHeight(e.nativeEvent.layout.height);
            }
          }}
          style={[
            styles('smallDeviceHeaderContent'),
            {
              // height: MINIMUM_COMPONENT_HEIGHT,
              paddingTop:
                safeAreaInsetTop ||
                styles('smallDeviceHeaderContent').paddingTop,
            },
          ]}
        >
          {currentRouteIndex.current === 0 ? null : (
            <View>
              <HeaderBackButton
                tintColor={isSmallDevice ? '#000' : '#fff'}
                onPress={goBack}
                labelVisible={false}
              />
              <Animated.View
                style={{
                  position: 'absolute',
                  opacity: headerDarkEllipseOpacity,
                }}
              >
                <HeaderBackButton
                  labelVisible={false}
                  tintColor="black"
                  onPress={goBack}
                />
              </Animated.View>
            </View>
          )}
          <Animated.View
            pointerEvents={disableSmallHeaderInteractions ? 'none' : 'box-none'}
            style={[
              styles('smallDeviceHeaderHiddenContent'),
              isSmallDevice
                ? null
                : {
                    opacity: smallHeaderOpacity,
                  },
            ]}
          >
            <View
              pointerEvents="box-none"
              style={{
                paddingRight: 8,
                alignSelf: 'flex-start',
              }}
            >
              {props.isEditing ? (
                <EditAvatarComponent
                  size={
                    deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 60 : 75
                  }
                  disabled={disableSmallHeaderInteractions}
                  isUploading={isUploadingProfileImage}
                  media={props.formData?.profile_image}
                  progress={profileImageUploadProgress}
                  setBusy={props.setBusy}
                  setData={props.setData}
                  setUploadProgress={setProfileImageUploadProgress}
                  setUploading={setUploadingProfileImage}
                />
              ) : (
                <View pointerEvents="none">
                  <Avatar
                    rounded
                    size={75}
                    source={{ uri: props.formData?.profile_image ?? '' }}
                  />
                </View>
              )}
            </View>
            <View style={{ flex: 1 }}>
              <View style={styles('nameContainer')}>
                {/* Only supporters should be allowed to change their name */}
                {props.isEditing && userData?.role === UserRole.Supporter ? (
                  <TextInputWithIcon
                    maxLength={48}
                    containerStyle={{
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      marginBottom: 4,
                    }}
                    icon={
                      <View
                        style={{
                          height: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesome
                          name="pencil"
                          size={21}
                          style={{
                            paddingHorizontal: 4,
                          }}
                          color={KEY_GRAY}
                        />
                      </View>
                    }
                    iconPosition="left"
                    value={props.formData?.name || ''}
                    style={styles('smallDeviceHeaderNameInput')}
                    onFocus={onNameInputFocus}
                    onBlur={onNameInputBlur}
                    onChangeText={(text) => {
                      props.setData({
                        name: text,
                      });
                    }}
                  />
                ) : (
                  <>
                    <Text
                      style={styles('smallDeviceHeaderName')}
                      numberOfLines={2}
                    >
                      {props.formData?.name}
                      {props.formData?.is_verified ? (
                        <View
                          // @ts-ignore - Works on the web
                          style={{
                            justifyContent: 'center',
                            display:
                              Platform.OS === 'web' ? 'inline' : undefined,
                            textAlignVertical: 'center',
                          }}
                        >
                          <VerifiedBadge />
                        </View>
                      ) : null}
                    </Text>
                  </>
                )}
              </View>

              <ProfileLocation
                profileId={props.formData?.id}
                profileRole={props.formData?.role ?? UserRole.Supporter}
                isLargeDeviceHeader={false}
                isEditing={props.isEditing}
                locations={props.formData?.locations}
                setLocations={(locations) => {
                  props.setData({
                    locations: locations,
                  });
                }}
              />

              {props.formData?.role === UserRole.Supporter ? (
                <SupporterTeams
                  textColor="black"
                  data={props.formData?.teams as any}
                />
              ) : null}

              <SocialLinks
                tint={KEY_GRAY}
                data={props.formData}
                isEditing={props.isEditing}
                setData={props.setData}
              />
            </View>
          </Animated.View>

          <UserActionSheetPressable
            user={props.data}
            style={{
              padding: 10,
              alignSelf: 'flex-start',
            }}
            excludeAccountSettings={
              props.formData?.id === userData?.id &&
              minSize(DEVICE_SIZES.LARGE_DEVICE).includes(deviceSize)
            }
          >
            <Ellipse
              width={24}
              height={24}
              fill={isSmallDevice ? '#000' : '#fff'}
            />
            <Animated.View
              style={[
                StyleSheet.absoluteFill,
                {
                  padding: 10,
                  opacity: headerDarkEllipseOpacity,
                },
              ]}
            >
              <Ellipse width={24} height={24} fill={'#000'} />
            </Animated.View>
          </UserActionSheetPressable>
        </Animated.View>

        {/* On large devices only */}
        {isSmallDevice ? null : (
          <Animated.View
            pointerEvents="box-none"
            style={[
              styles('largeDeviceHeaderContent'),
              { opacity: largeHeaderOpacity },
            ]}
          >
            <View
              style={{
                alignItems: 'center',
              }}
              pointerEvents="box-none"
            >
              {props.isEditing ? (
                <EditAvatarComponent
                  size={132}
                  isUploading={isUploadingProfileImage}
                  media={props.formData?.profile_image}
                  progress={profileImageUploadProgress}
                  setBusy={props.setBusy}
                  setData={props.setData}
                  setUploadProgress={setProfileImageUploadProgress}
                  setUploading={setUploadingProfileImage}
                />
              ) : (
                <View pointerEvents="none">
                  <Avatar
                    rounded
                    source={{ uri: props.formData?.profile_image ?? '' }}
                    size={132}
                  />
                </View>
              )}

              <View
                pointerEvents={
                  props.isEditing && userData?.role === UserRole.Supporter
                    ? 'auto'
                    : 'box-none'
                }
                style={styles('nameContainer')}
              >
                {/* Only supporters should be able to update their name */}
                {props.isEditing && userData?.role === UserRole.Supporter ? (
                  <TextInputWithIcon
                    numberOfLines={2}
                    maxLength={48}
                    containerStyle={{
                      marginVertical: 20,
                      marginHorizontal: 24,
                      backgroundColor: 'rgba(255,255,255,0.5)',
                    }}
                    icon={
                      <View
                        style={{
                          height: '100%',
                          justifyContent: 'center',
                          padding: 20,
                        }}
                      >
                        <FontAwesome
                          name="pencil"
                          size={32}
                          style={{
                            flex: 1,
                            paddingHorizontal: 4,
                          }}
                          color={KEY_GRAY}
                        />
                      </View>
                    }
                    iconPosition="left"
                    value={props.formData?.name || ''}
                    style={styles('largeDeviceHeaderNameInput')}
                    onFocus={onNameInputFocus}
                    onBlur={onNameInputBlur}
                    onChangeText={(text) => {
                      props.setData({
                        name: text,
                      });
                    }}
                  />
                ) : (
                  <>
                    <Text style={styles('largeDeviceHeaderName')}>
                      {props.formData?.name}
                      {props.formData?.is_verified ? (
                        <View
                          // @ts-ignore - Works on the web
                          style={{
                            display:
                              Platform.OS === 'web' ? 'inline' : undefined,
                            textAlignVertical: 'center',
                          }}
                        >
                          <VerifiedBadge />
                        </View>
                      ) : null}
                    </Text>
                  </>
                )}
              </View>
            </View>

            <View
              style={{
                paddingBottom: 16,
              }}
              pointerEvents={props.isEditing ? 'auto' : 'none'}
            >
              <ProfileLocation
                profileId={props.formData?.id}
                profileRole={props.formData?.role ?? UserRole.Supporter}
                isLargeDeviceHeader
                isEditing={props.isEditing}
                locations={
                  /** Conservationists use EditProfileLocations screen to update their location, so we should
                   * render data from `data` instead of `formData` in those cases to avoid showing stale data */
                  props.formData?.role === UserRole.Supporter
                    ? props.formData?.locations
                    : props.data?.locations
                }
                setLocations={(locations) => {
                  props.setData({
                    locations,
                  });
                }}
              />
            </View>

            {props.formData?.role === UserRole.Supporter ? (
              <SupporterTeams
                textColor="white"
                data={props.formData?.teams as any}
              />
            ) : null}

            <SocialLinks
              data={props.formData}
              isEditing={props.isEditing}
              setData={props.setData}
            />
          </Animated.View>
        )}
      </Animated.View>
      <View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: shouldRemainCollapsed
            ? MINIMUM_COMPONENT_HEIGHT
            : COMPONENT_HEIGHT,
        }}
      >
        <ProfileToolbar
          onLayout={onToolbarLayout}
          data={props.formData as any}
          isEditing={props.isEditing}
          isSaving={props.isSaving}
          onDiscardChanges={props.onDiscardChanges}
          onEditProfile={props.onEditProfile}
          onSaveChanges={props.onSaveChanges}
          setBusy={props.setBusy}
          setData={props.setData}
        />
      </View>
    </Animated.View>
  );
}

interface IProfileLocationProps {
  profileId: string | undefined;
  profileRole: UserRole;
  locations: DeepPartial<Location>[] | undefined | null;
  setLocations: (
    locations: {
      name: string;
      latitude: number | undefined;
      longitude: number | undefined;
    }[],
  ) => void;
  isEditing: boolean;
  isLargeDeviceHeader: boolean;
}

function ProfileLocation(props: IProfileLocationProps) {
  const { styles } = useStyles();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { spawnModal } = useModalContext();

  const [showLocationPicker, setShowLocationPicker] = useState(false);

  function onEditLocations() {
    /** Organizations can have multiple locations, use EditProfileLocations screen */
    if (props.profileRole === UserRole.Conservationist) {
      navigate('EditProfileLocations', {
        userId: props.profileId,
      });
      return;
    }

    // Alert asking if user wants to remove their location or change it
    // If no location is already set, just open the modal
    if (props.locations?.[0]?.name) {
      Alert.alert(
        'Edit Location',
        'Would you like to change your location or remove the existing one?',
        [
          {
            style: 'default',
            text: 'Change Location',
            onPress: () => {
              setShowLocationPicker(true);
            },
          },
          {
            style: 'destructive',
            text: 'Remove Location',
            onPress: () => {
              props.setLocations([]);
            },
          },
          {
            style: 'cancel',
          },
        ],
      );
    } else setShowLocationPicker(true);
  }

  function onLocationPickerClosed(location?: LocationPickerResult) {
    setShowLocationPicker(false);

    if (isNaN(Number(location?.latitude)) || isNaN(Number(location?.longitude)))
      return;

    if (location?.location && location?.latitude && location?.longitude)
      props.setLocations([
        {
          name: location?.location ?? '',
          latitude: Number(location?.latitude),
          longitude: Number(location?.longitude),
        },
      ]);
  }

  function seeAllLocations() {
    spawnModal({
      title: 'Locations',
      style: {
        maxWidth: 480,
      },
      component: (
        <>
          {props.locations?.map((location, index) => (
            <View
              key={index}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 8,
              }}
            >
              <View
                style={{
                  paddingRight: 8,
                }}
              >
                <MapMarker width={24} height={24} fill={KEY_GRAY} />
              </View>
              <Text style={styles('locationName')}>{location?.name}</Text>
            </View>
          ))}
        </>
      ),
    });
  }

  const locationIcon = useMemo(
    () => (
      <Ionicons
        name="location-sharp"
        size={20}
        color={KEY_YELLOW}
        style={
          /** Looks horrible in the browser */
          Platform.OS !== 'web'
            ? {
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.6,
                shadowRadius: 1,
              }
            : undefined
        }
      />
    ),
    [],
  );

  return props.isEditing ? (
    <View style={styles('locationContainer')}>
      <TouchableOpacity
        style={{
          width: '100%',
        }}
        onPress={onEditLocations}
      >
        <TextInputWithIcon
          disabled
          iconPosition="left"
          value={props.locations?.[0]?.name ?? ''}
          placeholder="Add a location..."
          icon={
            <View
              style={{
                /** For some reason spacing is different in the browser here */
                paddingHorizontal: Platform.OS === 'web' ? 3 : 0,
              }}
            >
              {locationIcon}
            </View>
          }
          containerStyle={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            maxWidth: 280,
            width: '100%',
          }}
          style={{
            paddingLeft: 20,
            width: '100%',
          }}
        />
      </TouchableOpacity>

      <LocationPickerModal
        visible={showLocationPicker}
        onRequestClose={onLocationPickerClosed}
      />
    </View>
  ) : props.locations?.length ? (
    <Pressable
      pointerEvents={props.locations?.length > 1 ? 'auto' : 'none'}
      onPress={seeAllLocations}
      style={({ pressed }) => [
        {
          backgroundColor: pressed ? 'rgba(255,255,255,0.5)' : 'transparent',
          borderRadius: 6,
        },
      ]}
    >
      <View pointerEvents={'none'} style={styles('locationContainer')}>
        {locationIcon}
        <Text
          numberOfLines={2}
          style={[
            styles('locationText'),
            {
              color: props.isLargeDeviceHeader ? 'white' : 'black',
              textAlign: props.isLargeDeviceHeader ? 'center' : 'left',
            },
          ]}
        >
          {props.locations?.[0]?.name}
        </Text>
      </View>
      {props.locations?.length > 1 ? (
        <Text
          style={[
            styles('locationText'),
            {
              color: props.isLargeDeviceHeader ? 'white' : 'black',
              textAlign: props.isLargeDeviceHeader ? 'center' : 'left',
            },
          ]}
        >
          +{props.locations.length - 1} more
        </Text>
      ) : null}
    </Pressable>
  ) : null;
}

interface ITeamOwner extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface ISupporterTeamsTeam
  extends Pick<TeamMember, 'userId' | 'teamId' | 'title'> {
  team: {
    user: ITeamOwner;
  };
}

interface ISupporterTeamsProps {
  data: ISupporterTeamsTeam[] | undefined | null;
  textColor: string;
}

function SupporterTeams(props: ISupporterTeamsProps) {
  const [expanded, setExpanded] = useState(false);

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const minimizeTeams = useMemo(
    () => (props.data?.length ?? 0) > 1 && !expanded,
    [props.data, expanded],
  );

  return props.data?.length ? (
    <View
      style={{
        paddingTop: 4,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            flexDirection: minimizeTeams ? 'row' : 'column',
            flexWrap: minimizeTeams ? 'wrap' : 'nowrap',
            alignItems: minimizeTeams ? 'center' : undefined,
          }}
        >
          {props.data?.map((membership) => (
            <View
              key={membership.teamId}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 2,
              }}
            >
              <Avatar
                size={24}
                containerStyle={{
                  marginRight: 6,
                }}
                source={{ uri: membership.team?.user?.profile_image ?? '' }}
                rounded
                onPress={() =>
                  navigate('Profile', { id: membership.team.user.id })
                }
              />
              {minimizeTeams ? null : (
                <View>
                  <Text
                    style={{
                      fontFamily: 'Lato-Bold',
                      color: props.textColor ?? 'black',
                      fontSize: 15,
                    }}
                  >
                    {shorten(membership.team?.user?.name ?? '', 64)},
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Lato-Italic',
                      color: props.textColor ?? 'black',
                      textTransform: 'capitalize',
                      fontSize: 15,
                    }}
                  >
                    {shorten(membership.title ?? 'Member', 64)}
                  </Text>
                </View>
              )}
            </View>
          ))}
        </View>
        {!expanded && props.data.length > 1 ? (
          <Pressable
            style={{
              padding: 8,
              paddingVertical: 12,
            }}
            onPress={() => setExpanded(true)}
          >
            <AntDesign
              name="right"
              size={18}
              color={props.textColor ?? 'black'}
            />
          </Pressable>
        ) : null}
      </View>
      {expanded && props.data.length > 1 ? (
        <Pressable
          style={{
            padding: 8,
            paddingVertical: 12,
            alignSelf: 'center',
          }}
          onPress={() => setExpanded(false)}
        >
          <AntDesign name="up" size={18} color={props.textColor ?? 'black'} />
        </Pressable>
      ) : null}
    </View>
  ) : null;
}

function VerifiedBadge() {
  return (
    <MaterialIcons
      onPress={() => {
        Alert.alert(
          'What does this mean?',
          'This organization has submitted approved documentation proving its authenticity',
        );
      }}
      name="verified"
      style={{ marginLeft: 6, marginVertical: -2 }}
      size={22}
      color={'#009CFF'}
    />
  );
}

interface IEditAvatarComponentProps {
  size: number;
  media: string | undefined | null;
  progress: number;
  disabled?: boolean;
  isUploading: boolean;
  setBusy: (busy: boolean) => void;
  setData: (data: DeepPartial<User>) => void;
  setUploading: (uploading: boolean) => void;
  setUploadProgress: (progress: number) => void;
}

/**
 * This component allows us to synchronize the appearance of multiple UploadMedia
 * components, like we have in OrganizationProfileHeader, and display the same progress
 * indications no matter which one we end up viewing.
 */
const EditAvatarComponent = (props: IEditAvatarComponentProps) => (
  <View
    pointerEvents={props.isUploading || props.disabled ? 'none' : 'auto'}
    style={{
      opacity: props.isUploading ? 0.8 : 1,
    }}
  >
    <UploadMedia
      circular
      isAvatar
      disabled={props.disabled || props.isUploading}
      mediaType="Images"
      size={props.size}
      removable={false} // Organization profiles should always have a logo
      media={[{ uri: props.media ?? '' }]}
      hideProgressIndicator
      onChangeMedia={(m) => {
        const uri = m?.[0]?.uri;
        props.setData({ profile_image: uri });
      }}
      onUploadStart={() => {
        props.setBusy(true);

        props.setUploading(true);
      }}
      onUploadProgress={(progress) => {
        props.setUploadProgress(Math.ceil(progress.progress));
      }}
      onUploadEnd={() => {
        props.setBusy(false);

        props.setUploading(false);
      }}
      onError={(errorMessage) => {
        Alert.alert('Error', errorMessage);
      }}
      onUploadError={() => {
        Alert.alert(
          'Error',
          'We were unable to update your profile image right now.',
        );
      }}
    />
    <View
      pointerEvents="none"
      style={[
        StyleSheet.absoluteFill,
        {
          borderRadius: 1024,
          backgroundColor: 'rgba(10, 10, 10, 0.4)',
          display: props.isUploading ? 'flex' : 'none',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
          elevation: 1,
        },
      ]}
    >
      <ActivityIndicator size="small" />
      <Text
        style={{
          color: 'white',
          marginLeft: 6,
          fontFamily: 'Lato-Bold',
        }}
      >
        {props.progress}%
      </Text>
    </View>
  </View>
);
