import React from 'react';
import { View } from 'react-native';

import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';

import ValidatedTextInput from '/components/ValidatedForm/ValidatedTextInput';

interface IProjectGoalsFormContentProps {
  data: any;
  index: number;
  onChangeContent: (content: any) => void;
}

const ProjectGoalsFormContent = (props: IProjectGoalsFormContentProps) => {
  return (
    <View style={styles.itemContentRows}>
      <View style={styles.expertiseDescriptionContainer}>
        <ValidatedTextInput
          placeholderTextColor="gray"
          name={`goal_title_${props.index}`}
          maxLength={64}
          style={[styles.textInput, { fontFamily: 'Lato-Bold' }]}
          placeholder="Goal Title"
          onChangeText={(text) => props.onChangeContent?.({ title: text })}
          value={props.data?.title}
        />
        <ValidatedTextInput
          placeholderTextColor="gray"
          name={`goal_description_${props.index}`}
          multiline={true}
          maxLength={640}
          numberOfLines={4}
          style={styles.goalDescriptionBox}
          placeholder="Description of goal"
          onChangeText={(text) =>
            props.onChangeContent?.({ description: text })
          }
          value={props.data?.description}
        />
      </View>
    </View>
  );
};

export default ProjectGoalsFormContent;
