import { UpdateResolver } from '@urql/exchange-graphcache';

const createTesterObjectiveFeedback: UpdateResolver = (result, args, cache) => {
  if (!(args?.input as any)?.objectiveId) return;

  cache.link(
    {
      __typename: 'TesterObjective',
      id: (args.input as any).objectiveId,
    },
    'feedback',
    result.createTesterObjectiveFeedback as any,
  );
};

export default createTesterObjectiveFeedback;
