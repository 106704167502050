import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from './CampaignBuilderFooter';
import BigIssuesPicker, {
  IBigIssuesPickerBigIssue,
} from '/components/BigIssuesPicker/BigIssuesPicker';
import { KEY_GRAY, TITLE_FONT_SIZE } from '/constants';
import sharedStyles from '/constants/CampaignBuilder';
import ScrollView from '/components/common/ScrollView/ScrollView';

export default function CampaignBigIssues(
  props: CampaignBuilderComponentProps,
) {
  function onSelectionChanged(newSelection: IBigIssuesPickerBigIssue[]) {
    props.setData({
      campaign: {
        big_issues: newSelection.map((issue) => ({ id: issue.id })),
      },
    });
  }

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[
          sharedStyles.scrollView,
          {
            backgroundColor: 'white',
            padding: 10,
            marginTop: 8,
          },
        ]}
      >
        <Text style={styles.headerText}>RELEVANT BIG ISSUES</Text>
        <Text style={styles.paragraph}>
          <Text style={styles.paragraphItalic}>(Optional)</Text>
          {` `}Are any of the big issues you described on your profile related
          to this campaign? Select them here so people can take action on them.
          <Text style={styles.paragraphItalic}>
            {` `}
            Only select issues that apply to this campaign.
          </Text>
        </Text>

        <BigIssuesPicker
          selection={props.data?.campaign?.big_issues}
          onChangeSelection={onSelectionChanged}
        />
      </ScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => {
          props.next();
        }}
        validateForm={() => true}
      />
    </>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
  },
  paragraphItalic: {
    fontFamily: 'Lato-BoldItalic',
  },
  containerStyle: {
    padding: 10,
  },
});
